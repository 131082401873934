import { Component, OnInit } from '@angular/core';
import { Event } from 'src/app/modules/event';
import { PubliciteService } from 'src/app/services/publicite.service';
import { first } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ModalDismissReasons, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment.prod';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {

  events: Event[] = [];

  public collapse = false;
  actu: string;
  dtOptions: DataTables.Settings = {};
  image: string = "";
  actu1: string;
  date = new Date;
  registerForm: FormGroup;
  registerForm1: FormGroup;
  submitted = false;
  currentPage = 1;
  itemsPerPage = 10;
  pageSize: number;
  images: File;
  images2: File;
  closeResult = '';
  url = environment.apiUrl;
  link: string = "";
  link2: string = "";
  initialValues: any;
  user: any = {};
  p: number = 1;
  receivedData: any;
  constructor(private servicepublicite: PubliciteService, private route: ActivatedRoute, private serviceauth: AuthentificationService,
    private notifyService: NotificationService, private formBuilder: FormBuilder, public datepipe: DatePipe,private sharedService: SharedService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;

    this.user = this.serviceauth.getCurrentUser();
    this.getevents()

  }

  controle() {

    this.registerForm1 = this.formBuilder.group({
      img1: ['', Validators.required]
    });
  }
  get f() { return this.registerForm.controls; }
  get h() { return this.registerForm1.controls; }

  getevents() {
    this.servicepublicite.getEvent().pipe(first()).subscribe(data => { this.events = data })
  }

  delete(id: string) {
    this.servicepublicite.deleteevent(id).pipe(first()).subscribe(() => {
      this.getevents();
      this.showToasterError();
      this.modalService.dismissAll();
   
    })
  } 


  ajouter() {
    if (!this.images2) {
      this.image = "يجب تحميل الصورة ";
      return;
    }

    const formData = new FormData();
    formData.append('img', this.images2);
    this.servicepublicite.addevent(formData)
      .pipe(first()).subscribe(() => {
        setTimeout(() => {
          this.getevents();
        }, 3000);

        this.showToasterSuccess();
        this.modalService.dismissAll();
        this.images2 = null;
        this.link2 = "";
        this.submitted = false;
        this.image = "";
   
      })
  }

  modifierimage(id) {
    const formData = new FormData();
    formData.append('img', this.images);
    this.servicepublicite.updateevent(formData, id)
      .pipe(first()).subscribe(() => {
        this.getevents();
        this.showToasterSuccess1();
        this.modalService.dismissAll();
        this.link = "",
          this.images = null;
      })
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("تمت الإضافة ")
  }
  showToasterSuccess1() {
    this.notifyService.showSuccess("تم التعديل ")
  }
  showToasterError() {
    this.notifyService.showSuccess("تم الحذف ")
  }
  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }
  

  selectImage1(event: any) {
    this.image = "";
    var reader = new FileReader();
    const file = event.target.files[0];
    this.images2 = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link2 = event.target.result
    }

  }
  selectImage3(event: any) {
    var reader = new FileReader();
    const file = event.target.files[0];
    this.images = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link = event.target.result
    }

  }
  

  open(content) {
    this.modalService.open(content, { centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.actu = '';

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.actu = '';
    });
  }
  open1(content) {
    this.actu = '';
    this.link2 = "";
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.actu = '';
      this.link2 = "";
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.actu = '';
      this.link2 = "";
    });
  }



  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  refresh(): void {
    this.modalService.dismissAll();
    this.getevents()
  }
  ferme() {
    this.link = "";
    this.link2 = "";
    this.images = null;
    this.images2 = null;
  }

}

