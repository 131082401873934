<meta name="viewport" content="width=device-width, initial-scale=1.0">

<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '83%' : 'auto'}">
  <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.donate == 1">
    <br><br>
    <div class="card">
      <div class="card-body">
        <h2> قائمة حالات التبرع </h2>
        <br>

        <br>
        <div class="row" style="overflow-x:auto;direction: rtl;">
          <table class="row-border hover">
            <thead>
              <tr>
                <th style="border-bottom-right-radius: 10px; border-top-right-radius:10px">التاريخ</th>
                <th> وصف الحالة </th>
                <th> رمز الحالة </th>
                <th> المبلغ المتبرع به </th>
                <th>الوثائق </th>
                <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
              </tr>
            </thead>
            <tbody>
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;"
                *ngFor="let donate of donates; let index = index;">
                <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{
                  donate.created_at | date: 'yyyy-MM-dd H:mm' }}</td>
                <td> {{ donate.description }} </td>
                <td>{{ donate.identifier }}</td>
                <td>{{ donate.amount }}</td>
                <td>
                  <a (click)="openImageModal(donate, content)">
                    <i class="material-icons" style="color: #30287a;">image</i>
                  </a>
                  <ng-template #content let-modal>
                    <div class="modal-content">
                      <div class="modal-body" dir="rtl">
                        <div class="row" id="row5">
                          <div class="col-md-2">
                            <button id="close" type="button" class="close" aria-label="Close"
                              (click)="modal.close('Cross click');">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        </div>
                        <br />

                        <div *ngFor="let row of groupImagesByRow(getImagesForCurrentPage())" class="row">
                          <div *ngFor="let image of row" class="col-md-4">
                            <img [src]="image.image" alt="" style="width: 100%; height: auto;max-height:200px" />
                          </div>
                        </div>

                        <div class="d-flex justify-content-center mt-3">
                          <button class="btn btn-primary mx-1" [disabled]="currentImagePage === 1"
                            (click)="currentImagePage = currentImagePage - 1">
                            السابق
                          </button>
                          <span>{{ currentImagePage }} / {{ totalImagePages }}</span>
                          <button class="btn btn-primary mx-1" [disabled]="currentImagePage === totalImagePages"
                            (click)="currentImagePage = currentImagePage + 1">
                            التالي
                          </button>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
                <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <div class="d-flex">
                    <a (click)="openSm2(content4)"><i class="material-icons"
                        style="color: rgb(206, 6, 6);">delete</i></a>
                    <a [routerLink]="['/editDonate/', donate.id]"><i class="material-icons"
                        style="color: green;">edit</i></a>
                  </div>
                  <ng-template #content4 let-modal id="template4">
                    <div class="modal-body" dir="rtl">
                      <button id="close2" type="button" class="close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h4 id="h3"> هل تريد حذف هذه الحالة ؟</h4>
                      <br>
                      <div class="d-flex justify-content-center">
                        <button id="btnP" (click)="delete(donate.id)" type="button" class="btn btn-primary">&nbsp;
                          &nbsp;
                          تأكيد &nbsp; &nbsp;</button>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="padding-top: 10px;"> <ngb-pagination [maxSize]="5" [(page)]="currentPage" [pageSize]="pageSize"
              [collectionSize]="totalItems" (pageChange)="pageChange($event)"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="user.role != 'globale' && user.donate == 0 " style="padding-top: 10% !important">
    <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
      background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto;
      margin-bottom:auto; max-height: 800px; background-image: url('assets/droit.png')">
    </div>
  </div>
</body>