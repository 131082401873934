<app-menu></app-menu>

<body dir="rtl" [ngStyle]="{'width': receivedData ? '82%' : 'auto'}" style="padding: 62px;">
    <div class="container pt-5" *ngIf="user.role == 'globale' || user.mobile == 1" style="    margin-right: 22px;">
        <div class="d-flex" style="justify-content: space-between;">
            <h2>      رموز الشحن لبطاقة الهاتف </h2>
        </div>
        <br>
        <div class="container">

            <div class="row">
                <div class="col-md-4">
                    <form (ngSubmit)="onSubmit()" [formGroup]="photoForm">
                        <div class="card">

                            <img height="250px" style="padding: 20px;" src="{{img}}" alt="">
                            <br>
                            <h5> الرمز</h5>
                            <br>
                            <input onKeyPress="if(this.value.length==30) return false;" onkeydown="javascript: return event.keyCode === 8 ||
                            event.keyCode === 46 ? true : !isNaN(Number(event.key))" type="string" class="form-control" id="code" type="text" formControlName="code">
                            <br>
                            <div style="text-align: center;">
                                <button type="submit" class="button btn" [disabled]="photoForm.invalid || isSubmitting">إضافة</button>
                            </div>

                        </div>
                    </form>
                </div>
                <div class="col-md-8">
                    <div class="card">
                        <h5> قائمة الرموز</h5>
                        <div *ngFor="let support of supports" class="row cardList">
                            <div class="col-md-8 ">
                                {{support.code}}
                               
                            </div>
                            
                            <div class="col-md-4 d-flex  align-items-center justify-content-center">
                                <div class="d-flex ">
                                    <a (click)="openSm(content4)">
                                        <img src="../../../assets/deltepublication.png" height="25px" alt="">
                                    </a> &nbsp; &nbsp;
                                    <a (click)="openMd(content)">
                                        <img src="../../../assets/editpublication.png" height="25px"  alt="">
                                    </a>
                                </div>
                            </div>
                            <ng-template #content4 let-modal id="template4">
                                <div class="modal-body" dir="rtl">
                                  <button id="close2" type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                  <h4 id="h3"> هل تريد حذف الرمز  ؟</h4>
                                  <br>
                                  <div class="d-flex justify-content-center">
                                    <button id="btnP" (click)="delete(support.id)" type="button" class="btn btn-primary">&nbsp; &nbsp;
                                      تأكيد &nbsp; &nbsp;</button>
                                  </div>
                                </div>
                              </ng-template>
                              <ng-template  #content let-modal id="template4">
                                <div class="modal-body" dir="rtl">
                                    <button id="close2" type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                    
                                    <form (ngSubmit)="onUpdate(support.id)" [formGroup]="editForm">
                                        <div class="card">
                
                                    
                                            <h5> الرمز</h5>
                                            <br>
                                            <input onKeyPress="if(this.value.length==30) return false;" onkeydown="javascript: return event.keyCode === 8 ||
                                            event.keyCode === 46 ? true : !isNaN(Number(event.key))" type="string"  [(ngModel)]="support.code" class="form-control" id="code1"  formControlName="code1">
                                            <br>
                                            <div style="text-align: center;">
                                                <button type="submit" class="button btn" [disabled]="editForm.invalid">تعديل</button>
                                            </div>
                
                                        </div>
                                    </form>
                                  </div>

                                
                              </ng-template>
                        </div>
                        <div style="padding-top: 10px;"> <ngb-pagination [maxSize]="5" [(page)]="currentPage" [pageSize]="pageSize"
                            [collectionSize]="totalItems" (pageChange)="pageChange($event)"></ngb-pagination>
                        </div>
                    </div>

                   
                </div>
            </div>
            <br>


        </div>


    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.mobile == 0 " style="padding-top: 10% !important">
        <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
          background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto;
          margin-bottom:auto; max-height: 800px; background-image: url('assets/droit.png')">
        </div>
    </div>
</body>