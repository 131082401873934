import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ContactService } from 'src/app/services/contact.service';
import { Contact } from 'src/app/modules/contact';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  uri = environment.apiUrl;
  contact: Contact[];
  contacts: Contact[];
  pageSize= 10;
  p: number = 1;
  user: any = {};
  closeResult = '';
  registerForm: FormGroup;
  submitted = false;
  currentPage= 1;
  reponse: string;
  searchTerm: string;
  totalItems: number;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });

  dtOptions: DataTables.Settings = {};
  receivedData: any;
  constructor(
    private servicecontact: ContactService,
    private serviceauth: AuthentificationService,
    private modalService: NgbModal,
    private notifyService: NotificationService,private sharedService: SharedService,
    private formBuilder: FormBuilder, private http: HttpClient,
    config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  ngOnInit() {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
    });
    this.receivedData=false;
   
    this.user = this.serviceauth.getCurrentUser();
    this.registerForm = this.formBuilder.group({
      reponse: ['', Validators.required],
    });

    this.loadPosts();
  }

  get f() { return this.registerForm.controls; }

  search() {
    this.currentPage = 1;
    this.loadPosts();
  }

  updatecontact(id: string, reponse: string) {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.servicecontact.updatecontact(reponse, id).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.reponse = "";
      this.showToasterSuccess();
      this.modalService.dismissAll();
      this.submitted = false;
      this.loadPosts();
    })
  }

  
  pageChange(newPage: number) {
    this.currentPage = newPage;
    this.loadPosts();
  }

  loadPosts() {
    this.servicecontact.getContacts(this.currentPage, this.pageSize , this.searchTerm).subscribe(
      (response) => {
        this.contact = response.data;
        this.totalItems = response.meta.total;
      },
      (error) => {
        console.error('Error fetching posts', error);
      }
    );
  }



  allContact(id) {
    this.servicecontact.allContact(id)
      .subscribe(data => {
        this.contacts = data;

      })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("  تمت الإجابة على السؤال بنجاح    ")
  }

  openSm2(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openSm(content) {
    this.modalService.open(content, { size: 'lg', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
