<app-menu></app-menu>

<body dir="rtl" [ngStyle]="{'width': receivedData ? '82%' : 'auto'}">
    <div class="container pt-5" *ngIf="user.role == 'globale' || user.product == 1">
        <h2>تعديل المنتج</h2>
        <br>
        <form [formGroup]="registerForm" enctype="multipart/form-data">
            <div class="row">
                <div class="bord row">

                    <div class="col-lg-5">
                        <label for="inputGroupFile01"
                            class=" labelImg col-img d-flex flex-row justify-content-center align-items-center ">
                            <img class="img-fluid img" *ngIf="link !=''" [src]="link" height="200px" width="200px">
                            <img class="img-fluid img" *ngIf="link == ''" src='{{product.img}}'
                                style="max-height: 250px;">
                        </label>
                        <input (change)="selectImage($event)" [(ngModel)]="img" accept="image/*" formControlName="img"
                            style="display:none" type="file" class="custom-file-input" id="inputGroupFile01">

                 
                            <div formArrayName="images">
                                <div class="images-container align-items-center justify-content-center"
                                    style="width:418px">
                                    <button class="button btn-pagination" *ngIf="imageArray.controls.length > 3"
                                        (click)="previousImage()" [disabled]="currentIndex === 0"> <span
                                            class="material-icons">arrow_right</span> </button>
    
                                    <div *ngFor="let imageControl of imageArray.controls; let i = index">
                                        <div class="form-group" *ngIf="i >= currentIndex && i < currentIndex + 3">
                                            <label class="labelImg1 col-img d-flex flex-row align-items-center"
                                                [for]="'image' + i">
                                                <img class="img-fluid" [src]="getSrc(i)" *ngIf="getSrc(i)">
    
                                            </label>
                                            <input type="file" [id]="'image' + i" (change)="onFileSelected($event, i)"
                                                accept="image/*" style="display:none">
                                        </div>
                                    </div>
    
                                    <button class="button btn-pagination" *ngIf="imageArray.controls.length > 3"
                                        (click)="nextImage()"
                                        [disabled]="currentIndex === (imageArray.controls.length - 1)"><span
                                            class="material-icons">arrow_left</span></button>
                                </div>
                            </div>
                            <br>
                            <div *ngIf="imageArray.controls.length < 5"
                                class="d-flex flex-row justify-content-center align-items-center" >
                                <button (click)="addImage()" class="mb-5 btn1" style=>إضافة صورة</button>
                            </div>
                  
                    </div>
                    <div class="col-lg-7">
                        <div class="col-md-10">
                            <div class="d-flex">
                                <p class="prix">الإسم</p>
                                <input type="text" [(ngModel)]="product.name" formControlName="name" maxlength="30"
                                    class="inp form-control">
                            </div>
                            <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
                                <div *ngIf="f['name'].errors['required']"> يجب إدخال الإسم</div>
                            </div>
                            <br>
                        </div>
                        <div class="col-md-10">
                            <div class="d-flex">
                                <p class="prix">الثمن</p>
                                <input type="text" onpaste="return false;" (keypress)="keyPressNumbers($event)"
                                    class="inp form-control" [(ngModel)]="product.prix" name="prix"
                                    style="margin-right: 24px;" formControlName="prix" maxlength="10">
                            </div>
                            <div *ngIf="submitted && f['prix'].errors" class="invalid-feedback">
                                <div *ngIf="f['prix'].errors['required']"> يجب إدخال الثمن</div>
                            </div>
                            <br>
                        </div>

                        <div class="col-md-10">
                            <div class="d-flex">
                                <p class="prix">الوصف</p>
                                <textarea type="text" maxlength="250" style="max-height: 100px !important;" cols="30"
                                    rows="3" class="inp form-control" [(ngModel)]="product.description"
                                    name="description" formControlName="description"></textarea>

                            </div>
                            <div *ngIf="submitted && f['description'].errors" class="invalid-feedback">
                                <div *ngIf="f['description'].errors['required']"> يجب إدخال الوصف</div>
                            </div>

                            <br>
                        </div>



                        <div class="row">
                            <div class="col">
                                <div formArrayName="colors">
                                    <div *ngFor="let colorControl of colorsArray.controls; let i = index">
                                      <div class="d-flex align-items-center">
                                        <input [formControlName]="i" maxlength="30" type="text" class="form-control inp" placeholder="أدخل لون">
                                        <img src="../../../assets/deletecolor.png" (click)="removeColor(i)" alt="">
                                      </div>
                                    </div>
                                    <div style="text-align: left;">
                                      <img (click)="addColor()" src="../../../assets/addcolor.png" alt="">
                                    </div>
                                  </div>
                                  
                                  <!-- Sizes -->
                           

                            </div>
                            <div class="col">
                                <div formArrayName="sizes">
                                    <div *ngFor="let sizeControl of sizesArray.controls; let i = index">
                                      <div class="d-flex align-items-center">
                                        <input [formControlName]="i" type="text" maxlength="30" class="form-control inp" placeholder="أدخل مقاس">
                                        <img src="../../../assets/deletecolor.png" (click)="removeSize(i)" alt="">
                                      </div>
                                    </div>
                                    <div style="text-align: left;">
                                      <img (click)="addSize()" src="../../../assets/addcolor.png" alt="">
                                    </div>
                                  </div>
                            </div>
                        </div>
                 




                    </div>
                    <br>
                    <div class="d-flex flex-row justify-content-center align-items-center">
                        <button [disabled]="registerForm.invalid" type="submit" #submitButton1
                            (click)="update(product.prix, product.name, product.description); submitButton1.disabled = true"
                            class="btn1">تعديل</button>
                    </div>
                </div>
            </div>
        </form>
        <br><br>
    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.product == 0 " style="padding-top: 10% !important">
        <div class="container" style="width: 100%; height: 400px; max-height: 800px;background-size: contain;
        background-repeat: no-repeat;background-position: center center;display:block;margin-top:auto;
        margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
        </div>
    </div>
</body>