<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '82%' : 'auto'}">
  <div class="container" dir="rtl"
    *ngIf="user.role == 'globale' || user.users == 1
  || user.user_point == 1 || user.user_diamond == 1|| user.user_message == 1|| user.user_delete == 1|| user.user_block == 1">
    <br><br>
    <div class="card">
      <div class="card-body">

        <div class="d-flex" style="justify-content: space-between">
          <h2 id="h4">قائمة المستخدمين : {{countUser}}</h2>
          <div class=" lab1 btn" *ngIf="isTask == false && isTaskPoint == false && user.role == 'globale'">
            <div class="d-flex">
              <img height="18px" width="18px" (click)="sendPoint = !sendPoint" src="assets/iconflesh.png" alt="">
              &nbsp;&nbsp;
              <a (click)="openSm3(content3)"> <strong>إرسال إلى جميع المستخدمين</strong> </a>
            </div>
            <div *ngIf="sendPoint">
              <hr class="ligne">
              <a (click)="openSm3(contentP)"> <strong>إرسال هدايا </strong> </a>
            </div>
          </div>
        </div>
        <br>



        <div class="row" *ngIf="isTask && user.role == 'globale'">
          {{isTask}}
          <div class="col-md-1"></div>
          <div class="col-md-1">
            <!-- <img class="img-fluid imgRefresh" (click)="getProgress()" src="assets/refresh.png" alt=""> -->
          </div>
          <div class="col-md-7">
            <h4>جاري الإرسال...</h4>
            <div class="cardimg1 card-progress">
              <h3>{{progress / 100}} %</h3>
              <div class="progress">
                <progress class="progress-bar" value="{{progress /100}}" max="100"></progress>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <img class="img-fluid imgProgress" src="assets/progressimage.png" alt="">
          </div>
        </div>

        <div class="row " *ngIf="isTaskPoint && user.role == 'globale'">
          <div class="col-md-1"></div>
          <div class="col-md-1 ">
            <!-- <img class="img-fluid imgRefresh" (click)="getProgressPoint()" src="assets/refresh.png" alt=""> -->
          </div>
          <div class="col-md-7">
            <h4>جاري الإرسال...</h4>
            <div class="cardimg1 card-progress">
              <h3>{{progressPoint/100}} %</h3>
              <div class="progress">
                <progress class="progress-bar" value="{{progressPoint/100}}" max="100"></progress>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <img class="img-fluid imgProgress" src="assets/progressimage.png" alt="">
          </div>
        </div>


        <br>

        <ng-template #contentP let-modal class="testt">
          <div class="modal-content" *ngIf="isTask == false && isTaskPoint == false">
            <div class="modal-body" dir="rtl">
              <br>
              <form [formGroup]="registerForm3">
                <div class="row">
                  <div class="col-md-9">
                    <div class="cardimg">
                      <div class="d-flex flex-row justify-content-center align-items-center">
                        <div class="hPoint">إرسال هدايا </div>
                      </div>
                      <div class="col-md-4">
                        <input formControlName="point" [(ngModel)]="point" name="point" type="number"
                          onKeyPress="if(this.value.length==13) return false;" onkeydown="javascript: return event.keyCode === 8 ||
                    event.keyCode === 46 ? true : !isNaN(Number(event.key))" class="inputPoint form-control" name="ddd"
                          id="">
                      </div>
                      <div class="col-md-9">
                        <textarea formControlName="messageP" [(ngModel)]="messageP" name="messageP" type="text"
                          class="inputPoint1 form-control" name="dszse" id=""> </textarea>
                      </div>
                      <div class="d-flex flex-row justify-content-center align-items-center">
                        <button class="btnPoint" [disabled]="registerForm3.invalid"
                          (click)="addPointTotal(); submitButton4.disabled = true" #submitButton4>إرسال</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <img class="img-fluid imgPoint" src="assets/cadeaupoint.png" alt="">
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ng-template>


        <ng-template #content3 let-modal class="testt">
          <div class="modal-content" style="padding: 15px;" *ngIf="isTask == false && isTaskPoint == false">
            <div class="modal-body" dir="rtl">
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
              <br>
              <h4>إرسال إلى جميع المستخدمين</h4>
              <br> <br>
              <form [formGroup]="registerForm1">
                <div class="flex">
                  <textarea placeholder="أرسل رسالة ..." pattern=".*\S+.*" style=" max-height: 70px"
                    formControlName="messages" [(ngModel)]="messages" name="messages" cols="100" rows="1"
                    class="form-control"></textarea>
                  <button [disabled]="registerForm1.invalid" (click)="addMessageTotal(); submitButton3.disabled = true"
                    #submitButton3 type="button" class="lab2 btn">&nbsp;إرسال &nbsp;</button>
                </div>
                <br>
              </form>
            </div>
          </div>
        </ng-template>


        <div class="d-flex col-md-2 shadow-border" style="
        margin-right: 2%; ">
          <input type="search" (search)="search()" class="inputSearch form-control" [(ngModel)]="searchTerm"
            name="searchTerm" style="border: none" placeholder="البحث">
          <img style="margin-top: -5px; margin-left: -15px; " src="../assets/search.png" (click)=" search()"
            class="searchIcon material-icons" width="35" height="35">
        </div>
        <br>
        <div class="row" style="overflow-x:auto;direction: rtl;" id="tab">
          <table id="dtable" datatable [dtOptions]="dtOptions" class="row-border hover" style="margin-top: 17px;">
            <thead>
              <tr>
                <th style="border-bottom-right-radius: 10px; border-top-right-radius:10px">#</th>
                <th>التاريخ</th>
                <th>المستخدم</th>
                <th>رقم الهاتف</th>
                <th>البريد الإلكتروني</th>
                <th>الفيسبوك</th>
                <th>رمز الإحالة</th>
                <th>رمز مرجعي </th>
                <th> الفريق</th>
                <th>العداد</th>
                <th> النقاط</th>
                <th> المجوهرات</th>
                <th>عدد الإبلاغات</th>
                <th>سجل</th>
                <th></th>
                <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
              </tr>
            </thead>
            <tbody>
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;"
                *ngFor="let person of persons; let index = index;">
                <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{((currentPage *
                  10) -9)+index}}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ person.created_at |
                  date: 'yyyy-MM-dd H:mm' }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <div class="d-flex">{{ person.nomprenom }}
                    <img *ngIf="person.badge" height="30" width="30" [src]="person.badge" alt="">
                  </div>
                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ person.numerotele }}
                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ person.email }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <i *ngIf="person.IDfacebook != ''" class="material-icons" style="color: green;">check_circle</i>

                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{
                  person.codeparrainageSpecial
                  }}</td>
                <td *ngIf="person.codeDePere!='none'"
                  style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ person.codeDePere
                  }}</td>
                <td *ngIf="person.codeDePere=='none'"
                  style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> <a class="action"
                    (click)="openSm(contentGroup); getEquipeUser(person.id)">
                    <i class="material-icons" style="color: green;">supervised_user_circle</i>
                  </a>
                </td>

                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> <a class="action"
                    (click)="openSm4(contentOffer); getOfferUser(person.id);getOfferUser2(person.id)">
                    <i class="material-icons" style="color: #30287a;">av_timer</i>
                  </a>
                </td>

                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <div class="d-flex" style="padding-top: 5px;">
                    <a *ngIf="user.role == 'globale' || user.user_point == 1" class="action" (click)="openSm(content5)">
                      <i class="material-icons" style="color: orange;">edit</i>
                    </a>

                    <!-- <a *ngIf="user.role == 'globale'" class="action" (click)="openSm(content5)">
                      <i class="material-icons" style="color: orange;">edit</i>
                    </a> -->
                    {{ person.pointAmount }}
                  </div>
                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <div class="d-flex" style="padding-top: 5px;">
                    <a *ngIf="user.role == 'globale' || user.user_diamond == 1" class="action"
                      (click)="openSm(content6)">
                      <i class="material-icons" style="color: orange;">edit</i>
                    </a>
                    <!-- <a *ngIf="user.role == 'globale'" class="action"
                    (click)="openSm(content6)">
                    <i class="material-icons" style="color: orange;">edit</i>
                  </a> -->
                    {{ person.diamondAmount }}
                  </div>


                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ person.nbrSignal }}

                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <a target="_blank" [routerLink]="['/historique/', person.id , person.codeparrainageSpecial]">
                    <i class="material-icons" style="color: #30287a;">history</i>
                  </a>
                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <a class="action" (click)="openSm(content7); checkUserPret(person.id)">
                    <i class="material-icons" style="color: #00ae37;">money</i>
                  </a>
                </td>

                <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <div class="d-flex">
                    <a *ngIf="user.role == 'globale' || user.user_message == 1" class="action"
                      (click)="openSm2(content1); getMsgByid(person.id)">
                      <i class="material-icons" style="color: #30287a;">mail</i>
                    </a>
                    <a *ngIf="user.role == 'globale' || user.user_delete == 1" class="action" (click)="openSm(content)">
                      <i class="material-icons" style="color: rgb(206, 6, 6);">delete</i>
                    </a>
                    <a *ngIf="user.role == 'globale' || user.user_block == 1" class="action"
                      (click)="openSm3(content2); diffTime(person.token,person.codeparrainage)">
                      <i class="material-icons">block</i>
                    </a>
                  </div>
                </td>

                <ng-template #content7 let-modal>
                  <div class="modal-body" dir="rtl">
                    <div class="modal-header">
                      <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <div *ngIf="userPret==false">
                      <h5 id="h3"> منع نقل العداد </h5>
                      <br>
                      <div class="d-flex justify-content-center">
                        <button id="btnP" (click)="addUserPret(person.id)" type="button"
                          class="btn btn-primary"> &nbsp; &nbsp; تأكيد &nbsp; &nbsp;
                        </button>
                      </div>
                    </div>

                    <div *ngIf="userPret==true">
                      <h5 id="h3">فتح نقل العداد </h5>
                      <br>
                      <div class="d-flex justify-content-center">
                        <button id="btnP" (click)="deleteUserPret(person.id)" type="button"
                          class="btn btn-primary"> &nbsp; &nbsp; تأكيد &nbsp; &nbsp;
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <ng-template #content5 let-modal>
                  <div class="modal-body" dir="rtl">
                    <h5 id="h3"> تعديل النقاط </h5>
                    <br>
                    <form [formGroup]="registerForm5">
                      <input dir="ltr" placeholder="0" class="form-control" type="number" formControlName="montant"
                        [(ngModel)]="pointAmount">

                      <div *ngIf="submitted5 && h['montant'].errors" class="invalid-feedback">
                        <div class="err" *ngIf="h['montant'].errors['required']">يجب ادخال النقاط </div>
                      </div>
                      <br>
                      <div class="d-flex justify-content-center">
                        <button id="btnP" (click)="updateAmountPoint(pointAmount , person.id)" type="button"
                          class="btn btn-primary"> &nbsp; &nbsp; تأكيد &nbsp; &nbsp;</button>
                      </div>
                    </form>
                  </div>
                </ng-template>


                <ng-template #content6 let-modal>
                  <div class="modal-body" dir="rtl">
                    <h5 id="h3"> تعديل المجوهرات </h5>
                    <br>
                    <form [formGroup]="registerForm6">
                      <input dir="ltr" placeholder="0" class="form-control" type="number" formControlName="montant1"
                        [(ngModel)]="diamondAmount">

                      <div *ngIf="submitted6 && k['montant1'].errors" class="invalid-feedback">
                        <div class="err" *ngIf="k['montant1'].errors['required']">يجب ادخال المجوهرات </div>
                      </div>
                      <br>
                      <div class="d-flex justify-content-center">
                        <button id="btnP" (click)="updateAmountDiamond(diamondAmount , person.id)" type="button"
                          class="btn btn-primary"> &nbsp; &nbsp; تأكيد &nbsp; &nbsp;</button>
                      </div>
                    </form>

                  </div>
                </ng-template>

                <ng-template #contentGroup let-modal>
                  <div class="modal-content" dir="rtl">
                    <div class="modal-header">
                      <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <div class="modal-body">

                      <div class="card-container">
                        <div class="float-layout">
                          <div class="card-image">

                            <div class="card card1">
                              <h4>عدد الفريق </h4>
                              <h3>{{equipe}} </h3>


                            </div>
                            <img src="assets/equipe.png">
                          </div>
                        </div>
                      </div>

                      <div class="card-container">
                        <div class="float-layout">
                          <div class="card-image">

                            <div class="card card2">
                              <h4>عدد المتصلين</h4>
                              <h3>{{equipeOnline}} </h3>


                            </div>
                            <img src="assets/equipeOnline.png">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <ng-template #contentOffer let-modal>
                  <div class="modal-content" dir="rtl">
                    <div class="modal-header">
                      <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <div class="modal-body">


                      <table class="table row-border hover">
                        <tbody>

                          <th style="background-color: rgba(98, 180, 218, 0.479);text-align-last: center;"> مجموع
                            المجوهرات </th>
                          <th style="background-color: rgba(98, 180, 218, 0.479); border: 1px;text-align-last: center;">
                            مجموع النقاط </th>
                          <tr>
                            <td style="text-align-last: center;">{{offers2.diamondAmount}} </td>

                            <td style="text-align-last: center;">{{offers2.pointAmount}}</td>
                          </tr>
                        </tbody>
                      </table>




                    </div>
                  </div>
                </ng-template>

                <ng-template #content let-modal>
                  <div class="modal-body" dir="rtl">
                    <div class="modal-header">
                      <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <h5 id="h3"> هل تريد حذف هذا المستخدم؟</h5>
                    <br>
                    <div class="d-flex justify-content-center">
                      <button id="btnP" (click)="delete(person.id , person.codeparrainage)" type="button"
                        class="btn btn-primary"> &nbsp; &nbsp;
                        تأكيد &nbsp; &nbsp;</button>
                    </div>
                  </div>
                </ng-template>

                <ng-template #content1 let-modal class="testt">
                  <div class="modal-content" style="padding: 15px;">
                    <div class="modal-body" dir="rtl" style="padding: 0px !important;">
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="div1" dir="rtl">
                      <img src="assets/men.png" class="img" />
                      <div class="div2">
                        <h5> {{person.nomprenom}} </h5>
                        <h5> {{person.codeparrainage}} </h5>
                      </div>
                    </div>

                    <br>
                    <div class="modal-body" dir="rtl">
                      <div id="divdrop">
                        <div *ngFor="let m of msg ">
                          <div class="d-flex row" style="justify-content: space-between;">
                            <p class="col-md-10">
                              <li> {{m.message}}</li>
                            </p>
                            <p class="col-md-2" style="text-align:left"> {{ m.created_at | date: 'yy/M/d' }} </p>
                          </div>
                          <hr>
                        </div>
                      </div>

                      <br>
                      <form [formGroup]="registerForm">

                        <div class="flex" dir="ltr">

                          <textarea placeholder="أرسل رسالة ..." pattern=".*\S+.*" style=" max-height: 70px"
                            formControlName="message" [(ngModel)]="message" name="message" cols="30" rows="1"
                            class="form-control"></textarea>
                          <button [disabled]="registerForm.invalid"
                            (click)="addMessage(person.id); submitButton1.disabled = true" #submitButton1 type="button"
                            class="lab btn"><i style="color: #fff;" class="material-icons">send</i>
                            &nbsp;</button>
                        </div>

                        <div *ngIf="submitted && f['message'].errors" class="invalid-feedback">
                          <div class="err" *ngIf="f['message'].errors['required']">يجب ادخال الرسالة </div>
                        </div>
                        <br>
                      </form>

                    </div>
                  </div>
                </ng-template>

                <ng-template #content2 let-modal class="testt">
                  <div class="modal-content">
                    <div class="modal-body" dir="rtl">
                      <div class="modal-header">
                        <button type="button" class="btn-close" aria-label="Close"
                          (click)="modal.dismiss('Cross click')"></button>
                      </div>
                      <h4> هل تريد حظر هذا المستخدم؟</h4>
                      <br>
                      <div class="d-flex" style="justify-content: space-between;" id="bts">
                        <button class="btn bl1" (click)="showBlock()" *ngIf="diffJour <= 0 || person.token == ''">حظر
                        </button>
                        <button class="btn bl4" (click)="deblockUser(person.id)" *ngIf="diffJour >=0">إلغاء الحظر
                        </button>
                        <button class="btn bl2" aria-label="Close" (click)="modal.dismiss('Cross click')">إلغاء</button>
                      </div>
                      <br>
                      <div style="font-size : 18px" *ngIf="diffJour > 0"> <strong> مدة الحظر المتبقية : {{diffJour}} يوم
                        </strong>
                        <br>
                        <strong> السبب : {{block_data2}}
                        </strong>

                      </div>

                      <div *ngIf="periode == true">
                        <form [formGroup]="registerForm2">
                          <div class="d-flex" style="justify-content: space-between;">
                            <div style="font-size : 18px"> <strong>يرجى تحديد المدة الزمنية للحظر </strong> </div>
                            <input max="99999" min="1" name="periodeBlock" formControlName="periodeBlock"
                              [(ngModel)]="periodeBlock" class="inp form-control" type="number">
                          </div>
                          <div *ngIf="submitted2 && g['periodeBlock'].errors" class="invalid-feedback">
                            <div style="text-align:left ;" class="err" *ngIf="g['periodeBlock'].errors['required']">يجب
                              ادخال المدة الزمنية للحظر </div>
                          </div>
                          <br>
                          <div style="font-size : 18px"> <strong>السبب</strong> </div>
                          <textarea maxlength="300" formControlName="block_data" [(ngModel)]="block_data"
                            name="block_data" type="text" class="inputPoint1 form-control" name="dszse"
                            id=""> </textarea>
                          <div *ngIf="submitted2 && g['block_data'].errors" class="invalid-feedback">
                            <div class="err" *ngIf="g['block_data'].errors['required']">يجب
                              ادخال السبب </div>
                          </div>
                          <div class="d-flex justify-content-center">
                            <button (click)="blockUser(person.id)" class="btn bl3">تسجيل</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </tr>
              <tr *ngIf="persons?.length == 0 ">
                <td colspan="14" class="no-data-available" style="background-color: transparent;">لا توجد بيانات متوفرة
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>



  <div class="container" *ngIf="user.role != 'globale' && user.users == 0 " style="padding-top: 10% !important">
    <div class="container" style="
  width: 100%;
  height: 400px;
  max-height: 800px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display:block;
  margin-top:auto;
  margin-bottom:auto;
  max-height: 800px;
  background-image: url('assets/droit.png')">
    </div>
  </div>

</body>