<meta name="viewport" content="width=device-width, initial-scale=1.0">

<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '82.5%' : 'auto'}" style="padding-right: 72px;">
  <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.listdelegate == 1">
    <br><br>
    <div class="card">
      <div class="card-body">
        <div class="d-flex" style="justify-content: space-between;">
          <h2>قائمة التجار</h2>
          <button routerLink="/trader-percentage" class="lab"> طلبات تغيير نسبة الخصم </button>
          <button routerLink="/trader-countries" class="lab"> قائمة الدول للتجار</button>
        </div>
        <br>
        <br>
        <div class="container">
          <div class="row">
            <div class="col-sm"> </div>
            <div class="col-sm">
              <form [formGroup]="registerForm2">
                <div style="display: flex; align-items: center; margin-left:130%">
                  <div class="spa" style="margin-right: 32%;display: flex; margin-right: 32%;">
                    <h6 style="width: 121px;">
                      <span style="font-size: 22px;margin-right:42px; ">ثمن التاجر</span>
                    </h6>
                  </div>
                  <div class="flex" id="bar"
                    style="background-color:#b3daffd9; border-radius: 10px; margin-right: 0px; margin-left: 10px;">
                    <div></div>
                    <input type="text" id="inp" class="form-control" [(ngModel)]="traderPrice"
                      formControlName="traderPrice" pattern="[0-9]+"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                      style="margin-top: 3px; border-radius: 10px; width: 218px; margin-right: 22px" maxlength="10">
                    <a (click)="updatedelegate()" *ngIf="user.role =='globale'" id="a1">
                      <button
                        style="color: rgb(255, 255, 255);background-color: #c8cb00;border-radius: 9px;border: none;height: 34px;">تسجيل</button>
                    </a>
                    <img src="../assets/Bitcoin is growing.png" id="image" alt="" height="30" width="30"
                      style="height: 85px;width: 103px;margin-top: -25px;margin-left: -40px;">
                  </div>
                </div>
                <div *ngIf="submitted2 && g['traderPrice'].errors" class="invalid-feedback">
                  <div *ngIf="g['traderPrice'].errors['required']" style="margin-right: 37%;">يجب ادخال ثمن التاجر
                  </div>
                </div>
                <div *ngIf="traderPrice=='0' || traderPrice>'9999999'" class="invalid-feedback">
                  <div *ngIf="traderPrice=='0' || traderPrice>'9999999'" style="margin-right: 37%;">يجب ادخال ثمن التاجر
                  </div>
                </div>
              </form>
            </div>
            <div class="col-sm"> </div>
          </div>
        </div>
        <br>
        <!-- <div class="d-flex col-md-2">
          <input type="search"  class="inputSearch form-control" >
          <i  class="searchIcon material-icons">search</i>
        </div> -->
        <div class="row" style="overflow-y: auto;  direction: rtl;" id="tab">
          <table id="dtable" datatable [dtOptions]="dtOptions" class="row-border hover" style="margin-top:49px">
            <thead>
              <tr>
                <th style="border-bottom-right-radius: 10px; border-top-right-radius:10px">#</th>
                <th>التاريخ</th>
                <th>الإسم </th>
                <th> إسم المتجر </th>
                <th>نسبة التخفيض</th>
                <th>البلد </th>
                <th> المدينة </th>
                <th> رقم الهاتف</th>
                <th>العنوان </th>
                <th>رمز الإحالة</th>
                <th>الملاحظات </th>
                <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
              </tr>
            </thead>
            <tbody>
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;"
                *ngFor="let delegate of delegates;let index = index;">
                <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{((currentPage *
                  10) -9)+index}}</td>
                <td>{{ delegate.created_at |
                  date: 'yyyy-MM-dd H:mm' }}</td>
                <td>{{ delegate.name }}</td>
                <td>{{ delegate.storeName }}</td>
                <td>{{ delegate.percentage }} %</td>
                <td>{{ delegate.country }}</td>
                <td>{{ delegate.city }}</td>
                <td>{{ delegate.phoneNumber }}</td>
                <td>{{ delegate.address }}</td>
                <td>{{  delegate.codeparrainageSpecial }}</td>
                <td>
                  <div *ngIf="delegate.remark!=null">{{ delegate.remark }}</div>
                  <button id="btnP1" class="btn btn-primary" (click)="openSm1(content1)" *ngIf="delegate.remark==null">
                    إضافة </button>
                </td>
                <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <div class="d-flex">
                    <a class="action" (click)="openSm2(content2);onCountryChange(delegate.country)">
                      <i class="material-icons" style="color: #30287a;">edit</i>
                    </a>
                    &nbsp;
                    <a class="action" (click)="openSm(content)">
                      <i class="material-icons" style="color: rgb(206, 6, 6);">delete</i>
                    </a>
                  </div>
                </td>
                <ng-template #content let-modal>
                  <div class="modal-body" dir="rtl">
                    <div class="col-md-2">
                      <button id="close" type="button" class="close" aria-label="Close" style="
                      background-color: transparent;
                  " (click)="modal.close('Cross click'); ">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <h5 id="h3"> هل تريد حذف هذا التاجر</h5>
                    <br>
                    <div class="d-flex justify-content-center">
                      <button id="btnP" (click)="delete(delegate.id)" type="button" class="btn btn-primary"> &nbsp;
                        &nbsp; تأكيد &nbsp; &nbsp;</button>
                    </div>
                  </div>
                </ng-template>
                <ng-template #content1 let-modal>
                  <div class="modal-body" dir="rtl">
                    <button id="close" type="button" class="close" aria-label="Close"
                      (click)="modal.close('Cross click');vide(remark)"> <span aria-hidden="true">&times;</span>
                    </button>
                    <h5 id="h3"> الملاحظات </h5>
                    <br>
                    <form [formGroup]="registerForm1">
                      <div class="row">
                        <div class="col-md-8">
                          <textarea class="mt-2 remark form-control" formControlName="remark" id="remark"
                            [(ngModel)]="remark" cols="30" rows="4" maxlength="150"></textarea>
                        </div>
                        <div class="col-md-4">
                          <img class="img-fluid" src="../../../assets/note.png" alt="">
                        </div>
                      </div>
                      <br>
                      <div class="d-flex justify-content-center">
                        <button id="btnP1" [disabled]="registerForm1.invalid"
                          (click)="addRemarkDelegate(delegate.id); submitButton1.disabled = true" #submitButton1
                          type="button" class="btn btn-primary"> &nbsp; &nbsp; إضافة &nbsp; &nbsp;</button>
                      </div>
                    </form>
                  </div>
                </ng-template>
                <ng-template #content2 let-modal>
                  <div class="modal-body" dir="rtl" style="background-color: #f1f1f1;">
                    <button id="close3" type="button" class="close" aria-label="Close" style="
                    background-color: transparent;
                " (click)="modal.dismiss('Cross click'); ">
                      <span aria-hidden="true">&times;</span>
                    </button><br>
                    <h5 id="h3"> تعديل التاجر </h5>
                    <br>
                    <form [formGroup]="registerForm">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="d-flex mb-2">
                            <img src="../../../assets/userdelege.png" alt=""> &nbsp; إسم التاجر
                          </div>
                          <input class="form-control" formControlName="name" [(ngModel)]="delegate.name" maxlength="30"
                            type="text">
                          <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
                            <div *ngIf="f['name'].errors['required']">يجب ادخال إسم التاجر </div>
                          </div>
                          <br>
                          <div class="d-flex mb-2">
                            <img src="../../../assets/phonedelege.png" alt=""> &nbsp; رقم الهاتف
                          </div>
                          <input class="form-control" formControlName="phoneNumber" maxlength="20"
                            [(ngModel)]="delegate.phoneNumber" type="text">
                          <div *ngIf="submitted && f['phoneNumber'].errors" class="invalid-feedback">
                            <div *ngIf="f['phoneNumber'].errors['required']">يجب ادخال رقم الهاتف </div>
                          </div>
                          <br>
                          <div class="d-flex mb-2">
                            <img src="../../../assets/adressedelege.png" alt=""> &nbsp; العنوان
                          </div>
                          <input class="form-control" formControlName="address" maxlength="30"
                            [(ngModel)]="delegate.address" type="text">
                          <div *ngIf="submitted && f['address'].errors" class="invalid-feedback">
                            <div *ngIf="f['address'].errors['required']">يجب ادخال العنوان </div>
                          </div>
                          <br>
                          <div class="d-flex mb-2">
                            <img src="../../../assets/paysdelege.png" alt=""> &nbsp; البلد
                          </div>
                          <select class="form-control" formControlName="country" [(ngModel)]="selectedCountry" (change)="onCountryChange(selectedCountry)">
                            <option value="" disabled selected>اختر البلد</option>
                            <option *ngFor="let country of countries" [value]="country.name">{{ country.name }}</option>
                          </select>
                          <div *ngIf="submitted && f['country'].errors" class="invalid-feedback">
                            <div *ngIf="f['country'].errors['required']">يجب ادخال البلد </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="d-flex mb-2">
                            <img src="../../../assets/userdelege.png" alt=""> &nbsp; إسم المتجر
                          </div>
                          <input class="form-control" formControlName="storeName" [(ngModel)]="delegate.storeName"
                            maxlength="30" type="text">
                          <div *ngIf="submitted && f['storeName'].errors" class="invalid-feedback">
                            <div *ngIf="f['storeName'].errors['required']">يجب ادخال إسم المتجر </div>
                          </div>
                          <br>
                          <div class="d-flex mb-2">
                            <img src="../../../assets/villedelege.png" alt=""> &nbsp; المدينة
                          </div>
                          <select class="form-control" formControlName="city" [(ngModel)]="delegate.city">
                            <option value="" disabled selected>اختر المدينة</option>
                            <option *ngFor="let city of cityOptions" [value]="city">{{ city }}</option>
                          </select>
                          <div *ngIf="submitted && f['city'].errors" class="invalid-feedback">
                            <div *ngIf="f['city'].errors['required']">يجب ادخال المدينة </div>
                          </div>
                          <br>
                          <div class="d-flex mb-2">
                            <img src="../../../assets/remarqueicon.png" alt=""> &nbsp; الملاحظات
                          </div>
                          <textarea class="form-control" formControlName="remark" [(ngModel)]="delegate.remark" name=""
                            id="" cols="30" rows="5"></textarea>
                        </div>
                      </div>
                      <br>
                      <div class="d-flex justify-content-center">
                        <button id="btnP1"
                          (click)="updateInfoDelegate(delegate.name,delegate.storeName,delegate.phoneNumber,delegate.address,delegate.country,delegate.city,delegate.remark,delegate.id)"
                          type="button" class="btn btn-primary"> &nbsp; &nbsp; تعديل &nbsp; &nbsp;</button>
                      </div>
                    </form>
                  </div>
                </ng-template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="user.role != 'globale' && user.listdelegate == 0 " style="padding-top: 10% !important">
    <div class="container" style="width: 100%; height: 400px;max-height: 800px;background-size: contain;background-repeat: no-repeat;
      background-position: center center;display:block;margin-top:auto; margin-bottom:auto;max-height: 800px;
      background-image: url('assets/droit.png')">
    </div>
  </div>
</body>