<meta name="viewport" content="width=device-width, initial-scale=1.0">

<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '82.5%' : 'auto'}" style="padding-right: 72px;">
  <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.listdelegate == 1">
    <br><br>
    <div class="card">
      <div class="card-body">
        <div class="d-flex" style="justify-content: space-between;">
          <h2> قائمة الدول للتجار</h2>
          <button class="buttonAdd" (click)="openSm2(content2);"> إضافة دولة </button>

          <ng-template #content2 let-modal>
            <div class="modal-body d-flex align-items-center" dir="rtl"
              style="background-color: #f1f1f1;border-radius: 15px;">
              <div class="form-container">
                <h5 id="h3">إضافة دولة</h5>
                <br>
                <form (ngSubmit)="addCountry()">
                  <div class="form-group">
                    <label for="countryName">اسم الدولة:</label>
                    <input required type="text" id="countryName" class="form-control add" [(ngModel)]="newCountryName"
                      name="countryName" required />
                  </div>
                  <div class="form-group">
                    <label>المدن:</label>
                    <div *ngFor="let city of newCities; let i = index;trackBy: trackByFn"
                      class="d-flex align-items-center">
                      <input required type="text" class="form-control add" [(ngModel)]="newCities[i]" name="city{{ i }}"/>
                      <i class="material-icons" (click)="removeCity(i)">delete</i>
                    </div>
                  </div>
                  <i class="fa fa-plus" (click)="addCity()"></i>
                  <div class="d-flex justify-content-center" style="margin-top: 20px;">
                    <button type="submit" class="buttonAdd">إضافة</button>
                  </div>
                </form>
              </div>
              <div class="image-container">
                <img src="../../../assets/mapimage.png" alt="Image Design" class="design-image" />
              </div>
            </div>
          </ng-template>

        </div>
        <br>
        <br>
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="header">
                <h5>الدول</h5>
              </div>
              <ul>
                <div *ngFor="let country of countries">
                  <div class="d-flex" style="justify-content: space-between;">
                    <h6>
                      <input type="radio" name="countrySelection" class="country"
                        (change)="toggleCountrySelection(country.name, $event)" />
                      {{ country.name }}
                    </h6>
                    <div *ngIf="!isCountryUsed(country.name)">
                      <i class="material-icons" (click)="openEditCountryModal(country.name, editModal)">
                        edit
                      </i>
                      <i class="material-icons" (click)="openSm(content)">
                        delete
                      </i>
                    </div>
                  </div>

                  <ng-template #content let-modal>
                    <div class="modal-header">
                      <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <div class="modal-body" dir="rtl">
                      <h5 id="h5"> هل تريد حذف هذه الدولة؟</h5>
                      <br>
                      <div class="justify-content-center">
                        <button id="btnP" (click)="deleteCountry(country.name)" type="button" class="btn buttonAdd">
                          &nbsp; تأكيد &nbsp; </button>
                      </div>
                    </div>
                  </ng-template>

                  <ng-template #editModal let-modal>
                    <div class="modal-body" dir="rtl">
                      <h5 id="h5"> تعديل الدولة </h5>
                      <br>
                      <div>
                        <input required id="countryName" type="text" class="form-control" [(ngModel)]="editCountryName"
                          placeholder="اسم الدولة" />
                      </div>
                      <br />
                      <div class="d-flex justify-content-center">
                        <button id="btnP" (click)="saveEditedCountry()" type="button" class="btn buttonAdd">
                          تعديل
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </ul>
            </div>

            <div class="col-md-2"></div>
            <div class="col-md-4">
              <div *ngFor="let countryName of selectedCountriesKeys">

                <div class="header">
                  <h5> مدن {{ countryName }} : </h5>
                </div>
                <ul>
                  <div *ngFor="let city of selectedCountries[countryName]">
                    <div class="d-flex" style="justify-content: space-between;">
                      <div>{{ city }}</div>
                      <div *ngIf="!isCityUsed(countryName, city)">
                        <i class="material-icons" (click)="openEditCityModal(countryName, city, editCityModal)">
                          edit
                        </i>
                        <i class="material-icons" (click)="openSm(content1)">delete</i>
                      </div>
                    </div>

                    <ng-template #content1 let-modal>
                      <div class="modal-body" dir="rtl">
                        <div class="modal-header">
                          <button type="button" class="btn-close" aria-label="Close"
                            (click)="modal.dismiss('Cross click')"></button>
                        </div>
                        <h5 id="h5"> هل تريد حذف  المدينة</h5>
                        <br>
                        <div class="d-flex justify-content-center">
                          <button id="btnP" (click)="deleteCity(countryName, city);" type="button"
                            class="btn btn-primary"> &nbsp; &nbsp;
                            تأكيد &nbsp; &nbsp;</button>
                        </div>
                      </div>
                    </ng-template>

                    <ng-template #editCityModal let-modal>
                      <div class="modal-body" dir="rtl">

                        <h5 id="h5"> تعديل المدينة </h5>
                        <div>
                          <input required id="cityName" type="text" class="form-control" [(ngModel)]="editCityName"
                            placeholder="اسم المدينة" />
                        </div>
                        <br />
                        <div class="d-flex justify-content-center">
                          <button id="btnP" (click)="saveEditedCity()" type="button" class="btn btn-primary">
                            تعديل
                          </button>
                        </div>
                      </div>
                    </ng-template>

                  </div>
                  <br>
                  <div>
                    <div class="d-flex" style="justify-content: space-between; align-items: center;">
                      <input required type="text" class="form-control" [(ngModel)]="newCityName"
                        placeholder=" أضف مدينة" style="flex: 1; margin-right: 10px;" />
                      <button class="btn btn-primary" (click)="addCityP(countryName)">
                        إضافة مدينة
                      </button>
                    </div>
                  </div>
                </ul>

              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
  <div class="container" *ngIf="user.role != 'globale' && user.listdelegate == 0 " style="padding-top: 10% !important">
    <div class="container" style="width: 100%; height: 400px;max-height: 800px;background-size: contain;background-repeat: no-repeat;
      background-position: center center;display:block;margin-top:auto; margin-bottom:auto;max-height: 800px;
      background-image: url('assets/droit.png')">
    </div>
  </div>
</body>