<app-menu></app-menu>

<body dir="rtl">
    <div class="container pt-5" *ngIf="user.role == 'globale' || user.users == 1">
        <h2>سجل المستخدم </h2>
        <br>
        <h3>رمز الإحالة : {{codeparrainage}} الإسم :  {{name}} </h3>
        <br>
        <div class="row justify-content-center">
            <div class="col-md-5">
                <div class="d-flex justify-content-center">
                    <div class="date-content"> 
                        <label for="startDate">تاريخ البدء: </label>
                        <input id="startDate" class="date" type="date" [(ngModel)]="startDate" />
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="d-flex justify-content-center">
                    <div class="date-content">
                        <label for="endDate">تاريخ الانتهاء: </label>
                        <input id="endDate" class="date" type="date" [(ngModel)]="endDate" />
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="d-flex justify-content-center">
                    <button class="btn" (click)="onDateChange()">بحث</button>
                </div>
            </div>
        </div>
        <br>
        
        <br>
        <div class="row" style="overflow-x:auto">
            <table class="table">
                <thead>
                    <tr>
                        <th>التاريخ</th>
                        <th>السجل</th>
                        <th>الأدمين</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let historique of historiques"
                        [ngClass]="{'debit-row': historique.type_operation === 'debit', 'credit-row': historique.type_operation === 'credit'}">
                        <td>{{ historique.created_at | date: 'yyyy-MM-dd H:mm' }}</td>
                        <td>{{ historique.transaction }}</td>
                        <td>{{historique.admin}}</td>
                        <td>
                            <div *ngIf="historique.type_operation == 'credit'">
                                <div class="d-flex">
                                    <img *ngIf="historique.nature_transaction == 'diamond'"
                                        src="../../../assets/diamondgift.png" alt=""> &nbsp;
                                    <img *ngIf="historique.nature_transaction == 'point'"
                                        src="../../../assets/centgift.png" alt="">
                                    {{ historique.amount }} -
                                </div>
                            </div>
                        </td>
                        <td>
                            <div *ngIf="historique.type_operation == 'debit'">
                                <div class="d-flex">
                                    <img *ngIf="historique.nature_transaction == 'diamond'"
                                        src="../../../assets/diamondgift.png" alt="">&nbsp;
                                    <img *ngIf="historique.nature_transaction == 'point'"
                                        src="../../../assets/centgift.png" alt="">
                                    {{ historique.amount }} +
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <br><br>
            <ngb-pagination [maxSize]="5" [(page)]="currentPage" [pageSize]="pageSize" [collectionSize]="totalItems"
                (pageChange)="pageChange($event)"></ngb-pagination>
        </div>

        <br><br>
    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.users == 0" style="padding-top: 10% !important">
        <div class="container" style="width: 100%; height: 400px; max-height: 800px; background-size: contain;
            background-repeat: no-repeat; background-position: center center; display: block; margin-top: auto;
            margin-bottom: auto; max-height: 800px; background-image: url('assets/droit.png')">
        </div>
    </div>
</body>
