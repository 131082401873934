import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Recharge } from 'src/app/modules/recharge';
import { NotificationService } from 'src/app/services/notification.service';
import { RechargeService } from 'src/app/services/recharge.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['./addproduct.component.css']
})
export class AddproductComponent implements OnInit {

  selectedImage: string = '';
  prix: string;
  name: string
  selectedCategory: string;
  description: string;
  img: string;
  submitted = false;
  images1: File;
  registerForm: FormGroup;
  link: string = "";
  selectedFilterCategory: string | undefined;
  user: any = {};
  imagePreviews: string[] = [];
  currentIndex = 0;
  isFirstDivVisible: boolean = true;
  im: any;
  receivedData: any;
  categories: Recharge[] = [];

  constructor(private servicerecharge: RechargeService,
    private formBuilder: FormBuilder,
    private notifyService: NotificationService, private sharedService: SharedService,
    private serviceauth: AuthentificationService
  ) { }

  ngOnInit(): void {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;

    });
    this.receivedData = false;
    this.getCategories()
    this.registerForm = this.formBuilder.group({
      img: [null, Validators.required],
      name: ['', Validators.required],
      prix: ['', Validators.required],
      description: ['', Validators.required],
      images: this.formBuilder.array([]),
      category: ['', Validators.required],
      colors: this.formBuilder.array([this.createColorField()]),
      sizes: this.formBuilder.array([this.createSizeField()])
    });

    this.user = this.serviceauth.getCurrentUser();

    for (let i = 0; i < 3; i++) {
      this.addImage();
    }
  }

  get f() { return this.registerForm.controls; }


  get colors(): FormArray {
    return this.registerForm.get('colors') as FormArray;
  }

  createColorField(): FormGroup {
    return this.formBuilder.group({
      color: ['']
    });
  }

  addColor(): void {
    this.colors.push(this.createColorField());
  }

  removeColor(index: number): void {
    if (this.colors.length > 1) {
      this.colors.removeAt(index);
    }
  }
  showImage(imagePath: any): void {
    imagePath = imagePath.toString();
    this.selectedImage = imagePath;
  }

  get sizes(): FormArray {
    return this.registerForm.get('sizes') as FormArray;
  }

  createSizeField(): FormGroup {
    return this.formBuilder.group({
      size: ['']
    });
  }

  addSize(): void {
    this.sizes.push(this.createSizeField());
  }

  removeSize(index: number): void {
    if (this.sizes.length > 1) {
      this.sizes.removeAt(index);
    }
  }

  getCategories() {
    this.servicerecharge.getCategories()
      .pipe(first()).subscribe(data => {
        this.categories = data
      })
  }


  get imageArray() {
    return this.registerForm.get('images') as FormArray;
  }
  onFileSelected(event: any, index: number) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.imageArray.setControl(index, this.formBuilder.control(files[i]));
      this.previewImage(files[i], index);
    }
  }
  addImage() {
    const control = this.formBuilder.control(null);
    this.imageArray.push(control);
    this.imagePreviews.push('');
  }
  previewImage(files: File, index: number) {
    if (files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreviews[index] = e.target.result;
      };
      reader.readAsDataURL(files);
    }
  }

  previousImage() {
    if (this.currentIndex > 0) {
      this.currentIndex -= 3;
    }
  }
  toggleDivs() {
    this.isFirstDivVisible = !this.isFirstDivVisible;
  }

  nextImage() {
    if (this.currentIndex < (this.imageArray.controls.length - 3)) {
      this.currentIndex += 3;
    }
  }

  ajouter() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('img', this.images1);
    formData.append('category_id', this.selectedCategory);
    formData.append('prix', this.prix);
    formData.append('name', this.name);
    formData.append('description', this.description);
    formData.append('type', "product");
    const colorSet = new Set<string>();
    this.colors.controls.forEach(colorControl => {
      const color = colorControl.get('color')?.value;
      if (color && color.trim() !== '') {
        colorSet.add(color.trim());
      }
    });
    Array.from(colorSet).forEach(color => {
      formData.append('colors[]', color);
    });
    const sizeSet = new Set<string>();
    this.sizes.controls.forEach(sizeControl => {
      const size = sizeControl.get('size')?.value;
      if (size && size.trim() !== '') {
        sizeSet.add(size.trim());
      }
    });
    Array.from(sizeSet).forEach(size => {
      formData.append('sizes[]', size);
    });
    for (let i = 0; i < this.imageArray.length; i++) {
      if (this.imageArray.at(i).value != null) {
        formData.append('images[]', this.imageArray.at(i).value);
      }
    }
    this.servicerecharge.addProduct(formData)
      .pipe(first()).subscribe(() => {
        this.prix = "";
        this.link = "";
        this.name = "";
        this.description = "";
        this.img = "";
        this.imagePreviews = [];
        this.registerForm.reset();
        this.colors.clear();
        this.sizes.clear();
        this.addColor();
        this.addSize();
        this.selectedCategory = undefined;
        this.showToasterSuccess();
        setTimeout(() => {
        }, 5000)
        this.images1 = null;
        this.submitted = false;
        this.imageArray.controls = []
        for (let i = 0; i < 3; i++) {
          this.addImage();
        }
      })
  }

  showToasterSuccess() {
    this.notifyService.showSuccess(" تم اضافة منتج")
  }

  selectImage(event: any) {
    var reader = new FileReader();
    const file = event.target.files[0];
    this.images1 = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link = event.target.result
    }
  }

  isZeroAllowed = true;

  keyPressNumbers(event) {
    const key = event.key || String.fromCharCode(event.keyCode);
    if (key === '0' && this.isZeroAllowed && event.target.selectionStart === 0) {
      event.preventDefault();
    }
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  inputEvent(event: any) {
    const value = event.target.value;
    this.isZeroAllowed = value === '';
  }
}
