import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { DonateService } from 'src/app/services/donate.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-add-donate',
  templateUrl: './add-donate.component.html',
  styleUrls: ['./add-donate.component.css']
})
export class AddDonateComponent implements OnInit {

  photoForm: FormGroup;
  files: File[] = [null]; // Liste des fichiers
  uploadedFileNames: string[] = []; // Liste des noms des fichiers
  user: any = {};
  receivedData: boolean;
  currentPage: number = 1; 
  itemsPerPage: number = 5;
  totalPages: number = 1;
  isSubmitting: boolean = false;
  constructor(
    private fb: FormBuilder,
    private serviceauth: AuthentificationService, 
    private sharedService: SharedService,
    private servicedonate: DonateService,
    private notifyService: NotificationService
  ) {}

  ngOnInit(): void {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
    });

    this.photoForm = this.fb.group({
      description: ['', Validators.required], 
    });

    this.user = this.serviceauth.getCurrentUser();
    this.updatePagination();
  }

  // Ajouter un champ d'entrée pour un nouveau fichier
  addFileInput() {
    if (this.files.length < 10) {
      this.files.push(null);
      this.uploadedFileNames.push(''); // Ajouter une place pour le nom
      this.updatePagination();
    } else {
      console.log("Vous ne pouvez pas ajouter plus de 10 images.");
    }
  }

  // Gérer le téléchargement d'un fichier
  onFileChange(event: any, index: number) {
    const file = event.target.files[0];
    if (file) {
      this.files[index] = file;
      this.uploadedFileNames[index] = file.name; // Mettre à jour le nom du fichier correspondant
    }
  }

  // Supprimer un fichier
  removeFile(index: number) {
    this.files.splice(index, 1); // Supprimer le fichier
    this.uploadedFileNames.splice(index, 1); // Supprimer le nom correspondant
    this.updatePagination();

    const totalPages = Math.ceil(this.files.length / this.itemsPerPage);
    if (this.currentPage > totalPages) {
      this.currentPage = totalPages; // Ajuster la page courante
    }
  }

  // Obtenir les fichiers pour la page actuelle
  getFilesForCurrentPage(): { file: File, name: string }[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.files.slice(startIndex, endIndex).map((file, i) => ({
      file,
      name: this.uploadedFileNames[startIndex + i]
    }));
  }

  // Mettre à jour la pagination
  updatePagination() {
    this.totalPages = Math.ceil(this.files.length / this.itemsPerPage) || 1;
  }

  // Naviguer vers une page spécifique
  goToPage(page: number) {
    this.currentPage = page;
  }

  // Soumettre le formulaire
  onSubmit() {
    if (this.photoForm.valid) {
      this.isSubmitting = true;
      const formData = new FormData();
      formData.append('description', this.photoForm.get('description')?.value);

      this.files.forEach(file => {
        if (file) {
          formData.append('images[]', file);
        }
      });

      this.servicedonate.addDonate(formData)
        .pipe(first())
        .subscribe(() => {
          this.showToasterSuccess();
          this.resetForm();
          this.isSubmitting = false;
        });
    }
  }

  // Réinitialiser le formulaire après soumission
  resetForm() {
    this.photoForm.reset();
    this.files = [null];
    this.uploadedFileNames = [];
    this.updatePagination();
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("تم إضافة حالة تبرع");
  }
}
