<app-menu></app-menu>

<body dir="rtl" [ngStyle]="{'width': receivedData ? '82%' : 'auto'}" style="padding: 62px;">
    <div class="container pt-5" *ngIf="user.role == 'globale' || user.product == 1" style="    margin-right: 22px;">
        <div class="d-flex" style="justify-content: space-between;">
            <h2>المنتجات </h2>
            <div>
                <button routerLink="/add-product" class=" lab"> إضافة منتج</button> &nbsp; &nbsp;
                <button routerLink="/category" class=" lab"> قائمة الأصناف</button> &nbsp; &nbsp;
                <button routerLink="/products-demands" class=" lab"> قائمة طلبات المنتجات</button>
            </div>
        </div>
        <br>
        <div class="row">
            <br>
            <div class="row bord">
                <div class="d-flex" style="padding: 15px;justify-content: center;">
                    <button (click)="getProducts(); " class=" lab"> الكل</button>&nbsp; &nbsp;
                    <select [(ngModel)]="selectedFilterCategory" (change)="filterProducts()"
                        class="lab ">
                        <option value="undefined" disabled>الأصناف </option>
                        <option *ngFor="let category of categories" [value]="category.id">{{ category.name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-3"
                    *ngFor="let product of products| slice: pageSize | slice: 0:itemsPerPage; let index = index;">
                    <div class="mb-3">
                        <div class="col-md-6"
                            style="width: 83%;border: 1px dashed #5bd5ff;border-radius: 9px; height:225px;margin-top:10px; text-align-last: center;">
                            <br>
                            <img class="img-fuild img1" src="{{product.img}}" alt="" style="height: 41%; width: 41%;">

                        </div>
                        <ng-template #content8 let-modal>
                            <div class="modal-body" dir="rtl">
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="row">
                                            <div class="col">
                                                <img *ngIf="product.images.length=='0'" src="{{product.img}}" alt=""
                                                    width="253px" (click)="showImage(product.img)">
                                                <img *ngIf="product.images.length!='0'" src="{{selectedImage}}" alt=""
                                                    width="153px"
                                                    style="margin-bottom: 50px; max-height: 150px; margin-right: 84px;margin-top: -1px;border: 1px dashed #47bdff;border-radius: 12px;"
                                                    (click)="showImage(product.img)">
                                            </div>
                                            <br>
                                            <div class="col">
                                                <div class="image-container"
                                                    style="display: flex; flex-wrap: wrap; margin-right: 36px;">
                                                    <div
                                                        *ngFor="let im of product.images | slice: (currentPage - 1) * itemsPerPage : currentPage * itemsPerPage; let i = index;">
                                                        <img src="{{im}}" alt="" height="80" width="80"
                                                            style="box-shadow:3px 2px #888888;margin-right:10px;margin-left: 2px;margin-bottom: 10px;border: 1px dashed #47bdff;border-radius: 12px;"
                                                            (click)="showImage(im)">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="info">
                                            {{product.name}}
                                        </div>
                                        <div class="info">
                                            {{product.description}}
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div class="info">
                                                    {{product.prix}}
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="info">
                                                    {{ getCategoryName(product.category_id) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <select class="info form-select" style="padding-right: 30px;">
                                                    <option selected >الألوان </option>
                                                    <option  disabled *ngFor="let color of product.colors" >{{ color }}
                                                    </option>
                                                  </select>
                                            </div>
                                            <div class="col">
                                                <select class="info form-select" style="padding-right: 30px;">
                                                    <option selected >المقاسات </option>
                                                    <option disabled *ngFor="let size of product.sizes" >{{ size }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="container-fluid">

                                    <div class="row" style="    justify-content: right;">

                                        <br>
                                        {{product.description}}
                                        <br>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <img *ngIf="product.images.length=='0'" src="{{product.img}}" alt=""
                                                width="253px" (click)="showImage(product.img)">
                                            <img *ngIf="product.images.length!='0'" src="{{selectedImage}}" alt=""
                                                width="153px"
                                                style="margin-right: 84px;margin-top: -1px;border: 1px dashed #47bdff;border-radius: 12px;"
                                                (click)="showImage(product.img)">
                                        </div>
                                        <div class="col">
                                            <div *ngIf="product.images.length>'4'">
                                                <mat-paginator [length]="product.images.length" [hidePageSize]="true"
                                                    [pageSize]="itemsPerPage" (page)="pageChangedim($event)"
                                                    [showFirstLastButtons]="false">
                                                </mat-paginator>
                                            </div>
                                            <div class="image-container"
                                                style="display: flex; flex-wrap: wrap; margin-right: 36px;">
                                                <div
                                                    *ngFor="let im of product.images | slice: (currentPage - 1) * itemsPerPage : currentPage * itemsPerPage; let i = index;">
                                                    <img src="{{im}}" alt="" height="80" width="80"
                                                        style="box-shadow:3px 2px #888888;margin-right:10px;margin-left: 2px;margin-bottom: 10px;border: 1px dashed #47bdff;border-radius: 12px;"
                                                        (click)="showImage(im)">
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div> -->


                        </ng-template>

                        <p class="prix"
                            style="text-align: center;font-size: 14px;margin: -10px;margin-top: -46px;margin-left: 45px;">
                            الاسم:{{product.name}} </p>
                        <p class="prix"
                            style="text-align: center;font-size: 14px;margin: -10px;margin-top: -46px;margin-left: 45px;">
                            الثمن :{{product.prix}} <img *ngIf="getCategoryType(product.category_id) == 'point'" src="../assets/Coin.png" alt="">
                            <img *ngIf="getCategoryType(product.category_id) != 'point'" src="../assets/diamondgift.png" alt=""></p>

                        <div class="d-flex" style="margin-top: 37px;place-content: center;">

                            <img class="delete" height="35px" width="35px" (click)="openSm2(content4)"
                                src="assets/deleteproduit.png" alt="" style="margin-left:8px">
                            <img src="../assets/editproduits.png" [routerLink]="['/editProduct/', product.id]" alt=""
                                style=" width: 35px; height:35px ">
                            <img class="delete" height="35px" width="35px" (click)="openlg(content8)"
                                (click)="showImage(product.img)" src="../assets/infos.png" alt="">
                        </div>
                        <ng-template #content4 let-modal>
                            <div class="modal-body" dir="rtl">
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h5 id="h3"> هل تريد حذف هذا المنتج ؟</h5>
                                <br>
                                <div class="d-flex justify-content-center">
                                    <button id="btnP" (click)="delete(product.id)" type="button"
                                        class="btn btn-primary">&nbsp;
                                        &nbsp; تأكيد &nbsp; &nbsp;</button>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div style="justify-content: center;" class="pag">
                    <ngb-pagination class="pagination-reversed" dir="rtl" [maxSize]="5" [rotate]="true"
                        *ngIf="products.length > itemsPerPage" [collectionSize]="products.length" #numPages
                        [pageSize]="itemsPerPage" [(page)]="currentPage"
                        (pageChange)="onPageChange(currentPage); p = $event"><ng-template ngbPaginationPrevious>
                            <button class="btn btn-link custom-pagination-button">
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </button>
                        </ng-template>
                        <ng-template ngbPaginationNext>
                            <button class="btn btn-link custom-pagination-button">
                                <i class="fa fa-chevron-left" aria-hidden="true"></i>
                            </button>
                        </ng-template></ngb-pagination>
                </div>
            </div>
        </div>



        <br><br>


    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.product == 0 " style="padding-top: 10% !important">
        <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
          background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto;
          margin-bottom:auto; max-height: 800px; background-image: url('assets/droit.png')">
        </div>
    </div>
</body>