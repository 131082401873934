<app-menu></app-menu>

<body dir="rtl" [ngStyle]="{'width': receivedData ? '82%' : 'auto'}" style="padding: 62px;">
    <div class="container pt-5" *ngIf="user.role == 'globale' || user.product == 1" style="    margin-right: 22px;">
        <div class="d-flex" style="justify-content: space-between;">
            <h2>إضافة منتج </h2>
        </div>

        <br>

        <form [formGroup]="registerForm" enctype="multipart/form-data">
            <div class="row">

                <div class="col-md-6">
                    <div class="bord">
                        <div class="d-flex">
                            <p class="prix d-flex">الإسم <span class="red">*</span> </p>
                            <input type="text" [(ngModel)]="name" name="name" formControlName="name" maxlength="30"
                                class="inp form-control">
                        </div>
                        <div *ngIf=" submitted==true &&f['name'].errors" class="invalid-feedback">
                            <div *ngIf="f['name'].errors['required']"> يجب إدخال الإسم</div>
                        </div>
                        <br>

                        <div class="d-flex">
                            <p class="prix d-flex">الثمن <span class="red">*</span></p>
                            <input type="text" onpaste="return false;" (keypress)="keyPressNumbers($event)"
                                maxlength="10" class="inp form-control" [(ngModel)]="prix" name="prix"
                                formControlName="prix">
                        </div>
                        <div *ngIf="submitted && f['prix'].errors" class="invalid-feedback">
                            <div *ngIf="f['prix'].errors['required']"> يجب إدخال الثمن</div>
                        </div>
                        <br>

                        <div class="d-flex">
                            <p class="prix d-flex" >الوصف <span class="red">*</span></p>
                            <textarea type="text" maxlength="250"
                                style="max-height: 100px !important;margin-right: 3px;" cols="30" rows="2"
                                class="inp form-control" [(ngModel)]="description" name="description"
                                formControlName="description"></textarea>

                        </div>
                        <div *ngIf="submitted && f['description'].errors" class="invalid-feedback">
                            <div *ngIf="f['description'].errors['required']"> يجب إدخال الوصف</div>
                        </div>
                        <br>
                        <div class="d-flex">
                            <p class="category d-flex" >الصنف <span class="red">*</span> </p> &nbsp; &nbsp;

                            <select style="margin-right: 22px;" [(ngModel)]="selectedCategory" name="category"
                                class="inp form-control" formControlName="category" required>
                                <option value="undefined" disabled>اختر الصنف</option>
                                <option *ngFor="let category of categories" [value]="category.id">{{ category.name }}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="submitted && f['category'].errors" class="invalid-feedback">
                            <div *ngIf="f['category'].errors['required']">يجب إدخال الصنف</div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="d-flex flex-column">
                                    <p class="category">الألوان</p>
                                    <div formArrayName="colors"
                                        *ngFor="let colorControl of colors.controls; let i = index">
                                        <div [formGroupName]="i" class="d-flex">
                                            <input type="text" formControlName="color" placeholder="أدخل لون"
                                                class="inp form-control" maxlength="30">
                                            <div>
                                                <img src="../../../assets/deletecolor.png" 
                                                (click)="removeColor(i)" *ngIf="colors.length > 1" alt="">
                                            </div>

                                        </div>
                                    </div>
                                    <div style="text-align: left;">
                                        <img src="../../../assets/addcolor.png" 
                                            (click)="addColor()">
                                    </div>


                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex flex-column">
                                    <p class="category">المقاسات</p>
                                    <div formArrayName="sizes"
                                        *ngFor="let sizeControl of sizes.controls; let i = index">
                                        <div [formGroupName]="i" class="d-flex">
                                            <input type="text" formControlName="size" placeholder="أدخل مقاس"
                                                class="inp form-control" maxlength="30">
                                           <div>
                                            <img src="../../../assets/deletecolor.png"
                                            (click)="removeSize(i)" *ngIf="sizes.length > 1" alt="">
                                           </div>
                                        </div>
                                    </div>
                                    <div style="text-align: left;">
                                        <img src="../../../assets/addcolor.png"  (click)="addSize()">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="d-flex flex-row justify-content-center align-items-center">
                            <button type="submit" #submitButton1 (click)="ajouter(); " class="btn1">إضافة</button>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card " style="border: 1px solid dashed #CBF1FF;height: 520px;;">
                        <label for="inputGroupFile01"
                            style="background-color: transparent;height: 87%;width: 78%;margin-right: 43px; margin: top -36px;"
                            class=" labelImg col-img d-flex flex-row justify-content-center align-items-center ">
                            <img class="img-fluid img" *ngIf="link ==''" src="assets/picupload.png" alt="">
                            <img class="img-fluid img" style="max-height:200px" *ngIf="link !=''" [src]="link" >
                        </label>
                        <input (change)="selectImage($event)" [(ngModel)]="img" accept="image/*" formControlName="img"
                            style="display:none" type="file" class="custom-file-input" id="inputGroupFile01">
                        <div *ngIf="submitted && f['img'].errors " class="invalid-feedback">
                            <div *ngIf="f['img'].errors['required']" style="
                            margin-top: -34px;
                            margin-right: 65px;
                        "> يجب إدخال المنتج </div>
                        </div>
                        <br>
                        <div *ngIf="imageArray.controls.length < 5"
                            class="d-flex flex-row justify-content-center align-items-center">
                            <button #submitButton1 (click)="addImage()"
                                style="background-color: transparent;border: none;"><img
                                    src="../assets/addphotoprouct.png" alt="" height="50" width="" class="add"></button>
                        </div>
                        <br>
                        <div formArrayName="images">
                            <div class="images-container align-items-center justify-content-center">
                                <button class="button btn-pagination" *ngIf="imageArray.controls.length > 3"
                                    (click)="previousImage()" [disabled]="currentIndex === 0"> <img
                                        src="../assets/next.png" alt=""> </button>
                                <div *ngFor="let imageControl of imageArray.controls; let i = index">
                                    <div class="form-group" *ngIf="i >= currentIndex && i < currentIndex + 3"
                                        class="arrImage">
                                        <label class="labelImg1  col-img d-flex flex-row  align-items-center"
                                            [for]="'image' + i">
                                            <img class="img-fluid" [src]="imagePreviews[i]" *ngIf="imagePreviews[i]">
                                            <img class="img-fluid" src="../assets/Group 10.png"
                                                *ngIf="!imagePreviews[i]">
                                        </label>
                                        <input type="file" [id]="'image' + i" (change)="onFileSelected($event, i)"
                                            accept="image/*" style="display:none">

                                    </div>
                                </div>
                                <button class="button btn-pagination" *ngIf="imageArray.controls.length > 3"
                                    (click)="nextImage()"
                                    [disabled]="currentIndex === (imageArray.controls.length - 1)"><img
                                        src="../assets/previous.png" alt=""></button>
                            </div>
                        </div>
                     
                        <br><br>
                    </div>
                </div>
            </div>
            <br>
        </form>
        <br><br>
    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.product == 0 " style="padding-top: 10% !important">
        <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
          background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto;
          margin-bottom:auto; max-height: 800px; background-image: url('assets/droit.png')">
        </div>
    </div>
</body>