import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { UtilisateurService } from 'src/app/services/utilisateur.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utilisateur } from 'src/app/modules/utilisateur';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { environment } from 'src/environments/environment.prod';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-liste-signal',
  templateUrl: './liste-signal.component.html',
  styleUrls: ['./liste-signal.component.css']
})
export class ListeSignalComponent implements OnInit {

  uri = environment.apiUrl;
  user: any = {};
  countUser: string;
  searchTerm: string;
  persons: Utilisateur[];
  currentPage: number;
  closeResult = '';
  equipe: string;
  equipeOnline: string;
  registerForm: FormGroup;
  submitted = false;
  periodeBlock: string;
  diffJour: number;
  periode = false;
  itemsPerPage = 4; 
  pageSize: number;
  p: number = 1;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  dtOptions: DataTables.Settings = {};
  receivedData: any;
  block_data:string;
  block_data2:string;
  constructor(private serviceauth: AuthentificationService, private serviceutilisateur: UtilisateurService,
    private http: HttpClient, private modalService: NgbModal, private notifyService: NotificationService,
    private route: ActivatedRoute,private sharedService: SharedService,
    private formBuilder: FormBuilder,) { }

  ngOnInit(): void {

    this.user = this.serviceauth.getCurrentUser();
    this.countUsersSignal();
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;
    var that = this;
    this.dtOptions = {
      pagingType: 'simple_numbers',
      searching: true,
      language: {
        processing: "يرجى الإنتظار...",
        searchPlaceholder: 'البحث',
        search: "",
        lengthMenu: " _MENU_ ",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        paginate: {
          first: "",
          previous: "<i class='material-icons'>navigate_next</i> ",
          next: "<i class='material-icons'>navigate_before</i>",
          last: ""
        },
      },
      info: false,
      paging: true, 
      ordering: false,
      serverSide: true,
      processing: true,
      lengthChange: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.currentPage = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        const rowData = {
          no_of_records: dataTablesParameters.length,
          page: this.currentPage,
          group_id: 1
        };
        const obj = { searchTerm: this.searchTerm }
        that.http.post<DataTablesResponse>(this.uri + 'signal-list', Object.assign(dataTablesParameters, obj), { headers: this.headers }).subscribe(resp => {
          const details = JSON.parse(JSON.stringify(resp.original));
          that.persons = details.data;
          callback({
            recordsTotal: details.recordsTotal,
            recordsFiltered: details.recordsFiltered,
            data: [],
          });
        });
      },
      columns: [
        { data: "created_at" },
        { data: "nomprenom" },
        { data: "badge" },
        { data: "numerotele" },
        { data: "codeparrainage" },
        { data: "pointAmount" },
        { data: "diamondAmount" },
        { data: "nbrSignal" }
      ],
    };
    
    this.registerForm = this.formBuilder.group({
      periodeBlock: ['', Validators.required],
    });

  }
  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }
  get g() { return this.registerForm.controls; }

  diffTime(date: any,codeparrainage) {
    var date1 = new Date();
    var date2 = new Date(date);
    var Diff_temps = date2.getTime() - date1.getTime();
    var Diff_jours = Diff_temps / (1000 * 3600 * 24);
    this.diffJour = Math.round(Diff_jours);
    if(this.diffJour > 0){
      this.serviceutilisateur.getBlock(codeparrainage)
      .subscribe(data => {
        this.block_data2 = data['block_data']
        console.log(this.block_data2)
      })
    }
  }
  countUsersSignal() {
    this.serviceutilisateur.countUsersSignal()
      .subscribe(data => {

        this.countUser = data['countUsers'];
      })
  }
  search() {
    $('#dtable').DataTable().ajax.reload();
  }

  openSm(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openSm2(content) {
    this.modalService.open(content, { size: 'lg', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openSm3(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  getEquipeUser(id) {
    this.serviceutilisateur.getEquipeUser(id)
      .subscribe(data => {
        this.equipe = data['equipe'];
        this.equipeOnline = data['equipeOnline'];
      })
  }


  blockUser(id: string) {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.serviceutilisateur.blockUser(id, this.periodeBlock, this.block_data).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.showToasterError1();
      this.modalService.dismissAll();
      this.submitted = false;
      this.periodeBlock = "";
      this.block_data=""
    })
  }

  deblockUser(id: string) {
    this.serviceutilisateur.deblockUser(id)
      .subscribe(() => {
        $('#dtable').DataTable().draw(false);
        this.showToasterSuccess1();
        this.modalService.dismissAll();
        this.countUsersSignal();
        this.periodeBlock = "";
      })
  }

  showBlock() {
    this.periode = true;
  }

  delete(id: string, codeparrainage:string) {
    this.serviceutilisateur.deleteusers(id , codeparrainage).subscribe(() => {
      this.modalService.dismissAll();
      $('#dtable').DataTable().draw(false);
      this.showToasterError();
    })
  }

  deleteSignal(id: string) {
    this.serviceutilisateur.deleteSignal(id).subscribe(() => {
      this.modalService.dismissAll();
      $('#dtable').DataTable().draw(false);
      this.showToasterSuccess();
    })
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("تم حذف الإبلاغات بنجاح")
  }

  showToasterSuccess1() {
    this.notifyService.showSuccess(" تم إلغاء الحظر بنجاح")
  }
  showToasterError() {
    this.notifyService.showSuccess("تم محو المستخدم")
  }

  showToasterError1() {
    this.notifyService.showSuccess("تم  حظر المستخدم")
  }

}
