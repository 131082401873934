import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ModalDismissReasons, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { DemandeRecharge } from 'src/app/modules/demanderecharge';
import { DemanderechargeService } from 'src/app/services/demanderecharge.service';
import { Recharge } from 'src/app/modules/recharge';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { environment } from 'src/environments/environment.prod';
import { UtilisateurService } from 'src/app/services/utilisateur.service';
import { SharedService } from 'src/app/shared.service';
@Component({
  selector: 'app-liste-mobile',
  templateUrl: './liste-mobile.component.html',
  styleUrls: ['./liste-mobile.component.css']
})
export class ListeMobileComponent implements OnInit {
  uri = environment.urlMarket;
  //uri=environment.apiUrl;
  demandesRechage: DemandeRecharge[];
  recharges: Recharge[] = [];
  date = new Date;
  registerForm: FormGroup;
  registerForm1: FormGroup;
  registerForm2: FormGroup;
  submitted = false;
  submitted2 = false;

  closeResult = '';
  user: any = {};
  col: string;
  reponse: string;
  reponseAccept: string;
  searchTerm: string;
  offers: any;
  currentPage = 1;
  totalItems: number;
  pageSize=10;
  p: number = 1;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  dOptions: DataTables.Settings = {};
  dtOptions: DataTables.Settings = {};
  receivedData: any;
  offers2: any;
  constructor(private serviceutilisateur: UtilisateurService, private servicedemanderecharge: DemanderechargeService, private serviceauth: AuthentificationService, private notifyService: NotificationService,
    private formBuilder: FormBuilder,private sharedService: SharedService, private http: HttpClient, public datepipe: DatePipe, private modalService: NgbModal,
    config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.user = this.serviceauth.getCurrentUser();
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
    });
    this.getAlldemande()
    this.receivedData=false;
    this.registerForm = this.formBuilder.group({
      reponse: ['', Validators.required],
    });
    this.registerForm2 = this.formBuilder.group({
      reponseAccept: ['', Validators.required],
    });
  }

  get f() { return this.registerForm.controls; }
  get g() { return this.registerForm2.controls; }


  
  getAlldemande() {
    this.servicedemanderecharge.getAlldemande(this.currentPage, this.pageSize , this.searchTerm).subscribe(
      (response) => {
        this.demandesRechage = response.data;
        this.totalItems = response.meta.total;
      },
      (error) => {
        console.error('Error fetching posts', error);
      }
    );
  }
  pageChange(newPage: number) {
    this.currentPage = newPage;
    this.getAlldemande();
  }


  updatedemande(id: string) {
    this.servicedemanderecharge.updatedemande(id).pipe(first()).subscribe(() => {
      this.getAlldemande()
      this.showToasterSuccess();
      this.modalService.dismissAll();
    })
  }

  updatedemande2(id: string, reponseAccept) {
    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      return;
    }
    this.servicedemanderecharge.updatedemande2(id, reponseAccept).pipe(first()).subscribe(() => {
      this.getAlldemande();
      this.showToasterSuccess();
      this.reponseAccept = "";
      this.modalService.dismissAll();
    })
  }


  search() {
    this.currentPage = 1;
    this.getAlldemande();
  }

  updatedemanderefuse(id: string, reponse: string) {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.servicedemanderecharge.updatedemanderefuse(reponse, id, this.user.email).pipe(first()).subscribe(() => {
      this.getAlldemande();
      this.reponse = "";
      this.showToasterSuccess1();
      this.modalService.dismissAll();
      this.submitted = false;
    })
  }

  updatedemanderefuse2(id: string) {
    this.servicedemanderecharge.updatedemanderefuseC(id, this.user.email).pipe(first()).subscribe(() => {
      this.getAlldemande();
      this.reponse = "";
      this.showToasterSuccess1();
      this.modalService.dismissAll();
    })
  }

  getOfferUser(id) {
    this.serviceutilisateur.getOfferUser(id)
      .subscribe(data => {
        this.offers = data;
      })
  }
  getOfferUser2(id) {
    this.serviceutilisateur.getOfferUser2(id)
      .subscribe(data => {
        this.offers2 = data;
      })
      console.log(this.offers2);
  }


  showToasterSuccess() {
    this.notifyService.showSuccess("  تم قبول طلب الشحن  ")
  }

  showToasterSuccess1() {
    this.notifyService.showError("      تم رفض طلب الشحن  ")
  }

  open(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.reponse = "";
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.reponse = "";
    });
  }

  openSm3(content) {
    this.modalService.open(content, { size: 'lg', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
