<app-menu></app-menu>

<body dir="rtl" [ngStyle]="{'width': receivedData ? '82%' : 'auto'}">
    <div class="container pt-5" dir="rtl" *ngIf="user.role == 'globale' || user.mobile == 1" style="    padding: 35px;">
        <div class="d-flex" style="justify-content: space-between;">
            <h2> بطاقات الهاتف </h2>
            <button routerLink="/mobile-list" class=" lab"> قائمة طلبات الشحن</button>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <form [formGroup]="registerForm" enctype="multipart/form-data">
                    <div class="bord" style="border-radius: 10px;border: 1px dashed #6ac1ff;width: 407px;height:281px;">

                        <br>
                        <div>
                            <label class="btn2" for="inputGroupFile01" style="margin-top:0;margin-right:45px">
                                <div class=" col-img d-flex flex-row justify-content-center align-items-center ">
                                    <img class="img-fluid img" *ngIf="link ==''" src="../assets/Group 10.png" alt="">
                                    <img class="img-fluid img" *ngIf="link !=''" [src]="link" height="200px"
                                        width="200px">
                                </div>
                            </label>
                        </div>

                        <div class="d-flex flex-row justify-content-center align-items-center">

                            <input (change)="selectImage($event)" [(ngModel)]="img" name="img" accept="image/*"
                                formControlName="img" style="display:none" type="file" class="custom-file-input"
                                id="inputGroupFile01">
                        </div>

                        <br>
                        <div class="d-flex"
                            style="margin-right: 86%;width: 221px;margin-top: -127px;margin-right: 360px;">
                            <div class="card" id="card"
                                style="height: 80px;background-color: white;margin-right: -110px;margin-top: 15px;box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);width: 166px;;">
                                <h4>
                                    <p class="prix" style="text-align: center;">المبلغ</p>
                                </h4>
                                <br>
                                <div style="display: flex; align-items: center;">
                                    <input type="text" onpaste="return false;" (keypress)="keyPressNumbers($event)"
                                        style="outline: none; margin-inline: auto;border:none;border-bottom: 1px solid #959595;padding-bottom: 0px; width:100%;box-shadow: none; border-radius:0px ;margin-top:-40px;width: 80%;
                        " class="inp form-control" [(ngModel)]="prix" name="prix" formControlName="prix"
                                        maxlength="10">
                                    <div class="d-flex"> <span
                                            style="margin-right: -42px;margin-top: -28px;">نقطة</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="invalid-feedback"><span>{{message}}</span></div>
                        <br>
                        <div class="d-flex flex-row justify-content-center align-items-center" style="
                    margin-right: 97%;
                    margin-top: -35px;
                ">
                            <button (click)="ajouter(); " class="btn1" style="    margin-top: 700%;
                         
                            background-color: rgba(159, 212, 243, 0.719);
                            color: black;
                            height: 42px;
                            width: 96px;
                            border: #9b9b9b;
                        ">تسجيل</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col" id='co' style="border-radius: 10px; margin-right:22px;
            border: none">
                <br>
                <div class="row" style="     border: 1px dashed rgb(110, 173, 255);
                border-radius: 18px;">
                    <div class="col-md-6 " id="itm"
                        *ngFor="let recharge of recharges  | slice: pageSize | slice: 0:itemsPerPage; let index = index;">
                        <div class="col-md-6"
                            style="width: 99%;border: 1px dashed #5bd5ff;border-radius: 9px;margin-top: 11px; height:180px;    text-align-last: center;">
                            <img class="img-fuild img1" src="{{recharge.img}}"
                                style="height: 97px;width: 97px;margin-top: 15px;">
                            <p class="prix" style="text-align: center;font-size: 15px;margin: -10px;margin-top: 4px;">
                                الثمن : {{recharge.prix}} <img src="../assets/Coin.png" alt=""></p>
                            <br>
                            <div class="d-flex" style="margin-top: 2px; place-content: center;">

                                <img (click)="openSm2(content4)" class="delete" height="37px" width="37px"
                                    src="assets/deleteic.png" alt="">
                                <a (click)="openSm2(content3)"> <img src="../assets/edit.png" alt="" style="
                                  height: 37px;
                                  width:37px;
                                  margin-right: 5px;
                               "> </a>

                               <a target="_blank" [routerLink]="['/historique/', recharge.id , recharge.img]">
                                <img  class="delete" height="37px" width="37px"
                                src="assets/infos.png" alt="">
                               </a>
                              

                            </div>
                        </div>
                        <br><br>
                        <ng-template #content4 let-modal>
                            <div class="modal-body" dir="rtl">
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h5 id="h3"> هل تريد حذف هذه البطاقة ؟</h5>
                                <br>
                                <div class="d-flex justify-content-center">
                                    <button id="btnP" (click)="delete(recharge.id)" type="button"
                                        class="btn btn-primary">&nbsp; &nbsp; تأكيد &nbsp; &nbsp;</button>
                                </div>
                            </div>
                        </ng-template>

                        <ng-template #content3 let-modal>
                            <div class="modal-content" style="
                            width: 511px;
                            margin-left: -62px;
                        ">
                                <div class="modal-body" dir="rtl" style="width: 500px;">
                                    <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <form [formGroup]="registerForm2" enctype="multipart/form-data">
                                        <div>
                                            <h5 style="text-align: center ;">تعديل البطاقة</h5>
                                            <br>
                                            <div class="cardimg">
                                                <div
                                                    class=" col-img d-flex flex-row justify-content-center align-items-center ">
                                                    <img class="img-fluid img imgg" *ngIf="link2 ==''"
                                                        src="{{recharge.img}}">
                                                    <img class="img-fluid img imgg" *ngIf="link2 !=''" [src]="link2">
                                                </div>
                                            </div>
                                            <div class="d-flex flex-row justify-content-center align-items-center">
                                                <label class="btn2" for="inputGroupFile02">تحميل</label>
                                                <input (change)="selectImage2($event)" accept="image/*"
                                                    style="display:none" type="file" class="custom-file-input"
                                                    id="inputGroupFile02">
                                            </div>
                                            <br>
                                            <div class="d-flex">
                                                <p class="prix">المبلغ</p>
                                                <input type="text" onpaste="return false;"
                                                    (keypress)="keyPressNumbers($event)" class="inp form-control"
                                                    maxlength="10" [(ngModel)]="recharge.prix" name="prix2"
                                                    formControlName="prix2">
                                            </div>
                                            <div *ngIf="submitted2 && g['prix2'].errors" class="invalid-feedback">
                                                <div *ngIf="g['prix2'].errors['required']" style="text-align: right;">
                                                    يجب
                                                    إدخال الثمن</div>
                                            </div>
                                            <br>
                                            <div class="d-flex flex-row justify-content-center align-items-center">
                                                <button (click)="updateRecharge(recharge.id, recharge.prix.toString())"
                                                    class="btn1">تعديل</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <br>
                </div>
                <div style="margin-right: -44px;">
                    <ngb-pagination dir="rtl" [maxSize]="5" *ngIf="recharges.length > itemsPerPage"
                        [collectionSize]="recharges.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage"
                        (pageChange)="onPageChange(currentPage); p = $event"></ngb-pagination>
                    <div style="
                margin-left: 100%;
            ">
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="container" *ngIf="user.role != 'globale' && user.mobile == 0 " style="padding-top: 10% !important">
        <div class="container" style="
        width: 100%;
        height: 400px;
        max-height: 800px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        display:block;
        margin-top:auto;
        margin-bottom:auto;
        max-height: 800px;
        background-image: url('assets/droit.png')">
        </div>
    </div>
</body>

<!-- <meta name="viewport" content="width=device-width, initial-scale=1.0">


<app-menu></app-menu>
<body>
<div class="container p-5" dir="rtl" *ngIf="user.role == 'globale' || user.mobile == 1">

    <div class="row">
        <div class="col-md-6">
            <h5>شحن بطاقات الهاتف </h5>


        </div>
        <div class="col-md-3"></div>
        <div class="col-md-3" style="text-align: right;">
            <button routerLink="/mobile-list" class="form-control lab">  قائمة  طلبات الشحن</button>
        </div>
    </div>
    <br>


    <div class="row" >
        <div class="col-md-3">
            <div class="bord">
                <form [formGroup]="registerForm"  enctype="multipart/form-data">
                <div class="row">
                    <input type="text" (keypress)="keyPressNumbers($event)" [(ngModel)]="recharge1.prix"  oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"name="prix" formControlName="prix" placeholder="000" value="" class="form-control">
                    <div *ngIf="submitted && f['prix'].errors" class="invalid-feedback">
                        <div *ngIf="f['prix'].errors['required']">يجب ادخال الثمن </div>
                    </div>

                </div>
                <div class="row">
                    <img *ngIf="link1 !=''" [src]="link1" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;">
                    <img *ngIf="recharge1.img != url1 && link1 ==''" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;" src="{{recharge1.img}}" alt="">
                    <div class=" col-img d-flex flex-row justify-content-center align-items-center ">
                    <img  *ngIf="recharge1.img == url1 && link1 ==''"  style="padding-top: 25px; padding-bottom: 25px;" src="assets/imagecarte.png" alt="">
                    </div>
                </div>
                <div *ngIf="submitted && h['img'].errors && recharge1.img == url1 && link1 ==''" class="invalid-feedback">
                    <div *ngIf="h['img'].errors['required']">يجب ادخال البطاقة </div>
                  </div>
                <div class="row">
                    <div class=" d-flex justify-content-center">
                        <label for="inputGroupFile01" > <i class="material-icons">camera_alt</i>  </label> &nbsp; &nbsp;
                        <input  (change)="selectImage1($event)"  [(ngModel)]="img" name="img" formControlName="img" type="file" class="custom-file-input" id="inputGroupFile01" >
                        <i *ngIf="recharge1.img != url1 || link1 !=''" class="material-icons" (click)="modifier('1', recharge1.prix)" > check </i> &nbsp;
                        <i *ngIf="recharge1.img == url1 && link1 =='' " class="material-icons" (click)="modifier1('1', recharge1.prix)">check </i> &nbsp; &nbsp;
                        <i class="material-icons" (click)="delete1()" >delete</i> &nbsp; &nbsp;
                    </div>
                </div>
                </form>
            </div>


        </div>
        <div class="col-md-3">
            <div class="bord">
                <form [formGroup]="registerForm2"  enctype="multipart/form-data">
            <div class="row">
                <input type="text" (keypress)="keyPressNumbers($event)" [(ngModel)]="recharge2.prix" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" name="prix2" formControlName="prix2"   placeholder="000" value="" class="form-control" >
                <div *ngIf="submitted2 && a['prix2'].errors" class="invalid-feedback">
                    <div *ngIf="a['prix2'].errors['required']">يجب ادخال الثمن </div>
                  </div>
            </div>
            <div class="row">
                <img *ngIf="link2 !=''" [src]="link2" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;">
                <img *ngIf="recharge2.img != url1 && link2 ==''" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;" src="{{recharge2.img}}" alt="">
                <div class=" col-img d-flex flex-row justify-content-center align-items-center ">
                    <img  *ngIf="recharge2.img == url1 && link2 ==''" style="padding-top: 25px; padding-bottom: 25px;" src="assets/imagecarte.png" alt="">
                </div>
            </div>
            <div *ngIf="submitted2 && b['img2'].errors && recharge2.img == url1 && link2 ==''" class="invalid-feedback">
                <div *ngIf="b['img2'].errors['required']">يجب ادخال البطاقة </div>
              </div>
            <div class="row">
                <div class=" d-flex justify-content-center">
                    <label for="inputGroupFile02" > <i class="material-icons">camera_alt</i> </label> &nbsp; &nbsp;
                    <input (change)="selectImage2($event)" [(ngModel)]="img2" name="img2" formControlName="img2" type="file" class="custom-file-input" id="inputGroupFile02" >
                    <i *ngIf="recharge2.img != url1 || link2 !=''" class="material-icons" (click)="modifier2('2', recharge2.prix)" >  check</i> &nbsp;
                    <i *ngIf="recharge2.img == url1 && link2 =='' " class="material-icons" (click)="modifier22('2', recharge2.prix)" >check </i> &nbsp;
                    <i class="material-icons" (click)="delete2()" >delete</i> &nbsp; &nbsp;
                </div>
            </div>
            </form>
        </div>
        </div>
        <div class="col-md-3">
            <div class="bord">
                <form [formGroup]="registerForm3"  enctype="multipart/form-data">
            <div class="row">
                <input type="text" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" (keypress)="keyPressNumbers($event)" [(ngModel)]="recharge3.prix" name="prix3" formControlName="prix3" placeholder="000" value="" class="form-control">
                <div *ngIf="submitted3 && c['prix3'].errors" class="invalid-feedback">
                    <div *ngIf="c['prix3'].errors['required']">يجب ادخال الثمن </div>
                </div>
            </div>
            <div class="row">
                <img *ngIf="link3 !=''" [src]="link3" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;">
                <img *ngIf="recharge3.img != url1 && link3 ==''" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;" src="{{recharge3.img}}" alt="">
                <div class=" col-img d-flex flex-row justify-content-center align-items-center ">
                    <img  *ngIf="recharge3.img == url1 && link3 ==''" style="padding-top: 25px; padding-bottom: 25px;" src="assets/imagecarte.png" alt="">

                </div>
            </div>

            <div *ngIf="submitted3 && d['img3'].errors && recharge3.img == url1 && link3 ==''" class="invalid-feedback">
                <div *ngIf="d['img3'].errors['required']">يجب ادخال البطاقة </div>
              </div>
            <div class="row">
                <div class=" d-flex justify-content-center">
                    <label for="inputGroupFile03" > <i class="material-icons">camera_alt</i> </label> &nbsp; &nbsp;
                    <input (change)="selectImage3($event)" [(ngModel)]="img3" name="img3" formControlName="img3" type="file" class="custom-file-input" id="inputGroupFile03" >
                    <i *ngIf="recharge3.img != url1 || link3 !=''" class="material-icons" (click)="modifier3('3', recharge3.prix)" > check </i> &nbsp;
                    <i *ngIf="recharge3.img == url1 && link3 =='' " class="material-icons" (click)="modifier33('3', recharge3.prix)" >check</i> &nbsp; &nbsp;
                    <i class="material-icons" (click)="delete3()" >delete</i> &nbsp; &nbsp;
                </div>
            </div>
            </form>
            </div>
        </div>
        <div class="col-md-3">
            <div class="bord">
                <form [formGroup]="registerForm4"  enctype="multipart/form-data">
            <div class="row">
                <input type="text" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" (keypress)="keyPressNumbers($event)" [(ngModel)]="recharge4.prix" name="prix4" formControlName="prix4" placeholder="000" value="" class="form-control" >
                <div *ngIf="submitted4 && e['prix4'].errors" class="invalid-feedback">
                    <div *ngIf="e['prix4'].errors['required']">يجب ادخال الثمن </div>
                </div>
            </div>

            <div class="row">
                <img *ngIf="link4 !=''" [src]="link4" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;">
                <img *ngIf="recharge4.img != url1 && link4 ==''" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;" src="{{recharge4.img}}" alt="">
                <div class=" col-img d-flex flex-row justify-content-center align-items-center ">
                    <img *ngIf="recharge4.img == url1 && link4 ==''" style="padding-top: 25px; padding-bottom: 25px;" src="assets/imagecarte.png" alt="">
                </div>
            </div>
            <div *ngIf="submitted4 && g['img4'].errors && recharge4.img == url1 && link4 ==''" class="invalid-feedback">
                <div *ngIf="g['img4'].errors['required']">يجب ادخال البطاقة </div>
              </div>
            <div class="row">
                <div class=" d-flex justify-content-center">
                    <label for="inputGroupFile04" > <i class="material-icons">camera_alt</i> </label> &nbsp; &nbsp;
                    <input (change)="selectImage4($event)" [(ngModel)]="img4" name="img4" formControlName="img4" type="file" class="custom-file-input" id="inputGroupFile04" >
                    <i *ngIf="recharge4.img != url1 || link4 !=''" class="material-icons" (click)="modifier4('4', recharge4.prix)" > check </i> &nbsp;
                    <i *ngIf="recharge4.img == url1 && link4 =='' " class="material-icons" (click)="modifier44('4', recharge4.prix)">check</i> &nbsp; &nbsp;
                    <i class="material-icons" (click)="delete4()" >delete</i> &nbsp; &nbsp;
                </div>
            </div>
            </form>
            </div>
        </div>
    </div>

<br>

    <div class="row" >
        <div class="col-md-3">
            <div class="bord">
                <form [formGroup]="registerForm5"  enctype="multipart/form-data">
            <div class="row">
                <input type="text" (keypress)="keyPressNumbers($event)" [(ngModel)]="recharge5.prix" name="prix5" formControlName="prix5" placeholder="000" value="" class="form-control" >
                <div *ngIf="submitted5 && i['prix5'].errors" class="invalid-feedback">
                    <div *ngIf="i['prix5'].errors['required']">يجب ادخال الثمن </div>
                </div>
            </div>
            <div class="row">
                <img *ngIf="link5 !=''" [src]="link5" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;">
                <img *ngIf="recharge5.img != url1 && link5 ==''" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;" src="{{recharge5.img}}" alt="">
                <div class=" col-img d-flex flex-row justify-content-center align-items-center ">
                    <img *ngIf="recharge5.img == url1 && link5 ==''" style="padding-top: 25px; padding-bottom: 25px;" src="assets/imagecarte.png" alt="">
                </div>
            </div>
            <div *ngIf="submitted5 && j['img5'].errors && recharge5.img == url1 && link5 ==''" class="invalid-feedback">
                <div *ngIf="j['img5'].errors['required']">يجب ادخال البطاقة </div>
              </div>
            <div class="row">
                <div class=" d-flex justify-content-center">
                    <label for="inputGroupFile05" > <i class="material-icons">camera_alt</i> </label> &nbsp; &nbsp;
                    <input (change)="selectImage5($event)" type="file" [(ngModel)]="img5" name="img5" formControlName="img5" class="custom-file-input" id="inputGroupFile05" >
                    <i *ngIf="recharge5.img != url1 || link5 !=''" class="material-icons" (click)="modifier5('5', recharge5.prix)" > check</i> &nbsp;
                    <i *ngIf="recharge5.img == url1 && link5 =='' " class="material-icons" (click)="modifier55('5', recharge5.prix)" >check </i> &nbsp; &nbsp;
                    <i class="material-icons" (click)="delete5()" >delete</i> &nbsp; &nbsp;
                </div>
            </div>
            </form>
            </div>

        </div>

        <div class="col-md-3">
            <div class="bord">
                <form [formGroup]="registerForm6"  enctype="multipart/form-data">
            <div class="row">
                <input type="text" (keypress)="keyPressNumbers($event)" [(ngModel)]="recharge6.prix" name="prix6" formControlName="prix6" placeholder="000" value="" class="form-control" >
                <div *ngIf="submitted6 && k['prix6'].errors" class="invalid-feedback">
                    <div *ngIf="k['prix6'].errors['required']">يجب ادخال الثمن </div>
                </div>
            </div>
            <div class="row">
                <img *ngIf="link6 !=''" [src]="link6" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;">
                <img *ngIf="recharge6.img != url1 && link6 ==''" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;" src="{{recharge6.img}}" alt="">
                <div class=" col-img d-flex flex-row justify-content-center align-items-center ">
                    <img  *ngIf="recharge6.img == url1 && link6 ==''"  style="padding-top: 25px; padding-bottom: 25px;" src="assets/imagecarte.png" alt="">
                </div>
            </div>
            <div *ngIf="submitted6 && l['img6'].errors && recharge6.img == url1 && link6 ==''" class="invalid-feedback">
                <div *ngIf="l['img6'].errors['required']">يجب ادخال البطاقة </div>
            </div>
            <div class="row">
                <div class=" d-flex justify-content-center">
                    <label for="inputGroupFile06" > <i class="material-icons" >camera_alt</i>  </label> &nbsp; &nbsp;
                    <input (change)="selectImage6($event)" [(ngModel)]="img6" name="img6" formControlName="img6" type="file" class="custom-file-input" id="inputGroupFile06" >
                    <i *ngIf="recharge6.img != url1 || link6 !=''" class="material-icons" (click)="modifier6('6', recharge6.prix)" >  check </i>&nbsp;
                    <i *ngIf="recharge6.img == url1 && link6 =='' " class="material-icons" (click)="modifier66('6', recharge6.prix)" >check </i> &nbsp; &nbsp;
                    <i class="material-icons" (click)="delete6()" >delete</i> &nbsp; &nbsp;
                </div>
            </div>
            </form>
            </div>
        </div>
        <div class="col-md-3">
            <div class="bord">
                <form [formGroup]="registerForm7"  enctype="multipart/form-data">
            <div class="row">
                <input type="text" (keypress)="keyPressNumbers($event)" [(ngModel)]="recharge7.prix" name="prix7" formControlName="prix7" placeholder="000" value="" class="form-control">
                <div *ngIf="submitted7 && m['prix7'].errors" class="invalid-feedback">
                    <div *ngIf="m['prix7'].errors['required']">يجب ادخال الثمن </div>
                </div>
            </div>
            <div class="row">
                <img *ngIf="link7 !=''" [src]="link7" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;">
                <img *ngIf="recharge7.img != url1 && link7 ==''" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;" src="{{recharge7.img}}" alt="">
                <div class=" col-img d-flex flex-row justify-content-center align-items-center ">
                    <img  *ngIf="recharge7.img == url1 && link7 ==''" style="padding-top: 25px; padding-bottom: 25px;" src="assets/imagecarte.png" alt="">
                </div>
            </div>
            <div *ngIf="submitted7 && n['img7'].errors && recharge7.img == url1 && link7 ==''" class="invalid-feedback">
                <div *ngIf="n['img7'].errors['required']">يجب ادخال البطاقة </div>
            </div>
            <div class="row">
                <div class=" d-flex justify-content-center">
                    <label for="inputGroupFile07" > <i class="material-icons" >camera_alt</i> </label> &nbsp; &nbsp;
                    <input (change)="selectImage7($event)" [(ngModel)]="img7" name="img7" formControlName="img7" type="file" class="custom-file-input" id="inputGroupFile07" >
                    <i *ngIf="recharge7.img != url1 || link7 !=''" class="material-icons" (click)="modifier7('7', recharge7.prix)" >  check </i>&nbsp;
                    <i *ngIf="recharge7.img == url1 && link7 =='' " class="material-icons" (click)="modifier77('7', recharge7.prix)" > check</i> &nbsp; &nbsp;
                    <i class="material-icons" (click)="delete7()" >delete</i> &nbsp; &nbsp;
                </div>
            </div>
            </form>
            </div>
        </div>
        <div class="col-md-3">
            <div class="bord">
                <form [formGroup]="registerForm8"  enctype="multipart/form-data">
            <div class="row">
                <input type="text" (keypress)="keyPressNumbers($event)" [(ngModel)]="recharge8.prix" name="prix8" formControlName="prix8" placeholder="000" value="" class="form-control">
                <div *ngIf="submitted8 && o['prix8'].errors" class="invalid-feedback">
                    <div *ngIf="o['prix8'].errors['required']">يجب ادخال الثمن </div>
                </div>
            </div>
            <div class="row">
                <img *ngIf="link8 !=''" [src]="link8" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;">
                <img *ngIf="recharge8.img != url1 && link8 ==''" class="img1 img-fluid" style="padding-top: 25px; padding-bottom: 25px;" src="{{recharge8.img}}" alt="">
                <div class=" col-img d-flex flex-row justify-content-center align-items-center ">
                    <img *ngIf="recharge8.img == url1 && link8 ==''" style="padding-top: 25px; padding-bottom: 25px;" src="assets/imagecarte.png" alt="">
                </div>
            </div>
            <div *ngIf="submitted8 && r['img8'].errors && recharge8.img == url1 && link8 ==''" class="invalid-feedback">
                <div *ngIf="r['img8'].errors['required']">يجب ادخال البطاقة </div>
            </div>
            <div class="row">
                <div class=" d-flex justify-content-center">
                    <label for="inputGroupFile08" > <i class="material-icons" >camera_alt</i> </label> &nbsp; &nbsp;
                    <input (change)="selectImage8($event)" [(ngModel)]="img8" name="img8" formControlName="img8" type="file" class="custom-file-input" id="inputGroupFile08" >
                    <i *ngIf="recharge8.img != url1 || link8 !=''" class="material-icons" (click)="modifier8('8', recharge8.prix)" > check </i> &nbsp;
                    <i *ngIf="recharge8.img == url1 && link8 =='' " class="material-icons" (click)="modifier88('8', recharge8.prix)" >check </i> &nbsp; &nbsp;
                    <i class="material-icons" (click)="delete8()">delete</i> &nbsp; &nbsp;
                </div>
            </div>
            </form>
            </div>
        </div>
    </div>




</div>



<div class="container" *ngIf="user.role != 'globale' && user.mobile == 0 " style="padding-top: 10% !important">

    <div class="container"  style="
    width: 100%;
    height: 400px;
    max-height: 800px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    display:block;
    margin-top:auto;
    margin-bottom:auto;
    max-height: 800px;

    background-image: url('assets/droit.png')">



    </div>
  </div>

  </body> -->