import { Component, OnInit } from '@angular/core';
import { Conversion } from 'src/app/modules/conversion';
import { CurrencyService } from 'src/app/services/currency.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/shared.service';
import { ActivatedRoute } from '@angular/router';
import { ConversionService } from 'src/app/services/conversion.service';
@Component({
  selector: 'app-tousconversions',
  templateUrl: './tousconversions.component.html',
  styleUrls: ['./tousconversions.component.css']
})
export class TousconversionsComponent implements OnInit {
  uri = environment.apiUrl;
  conversion: Conversion[];
  MD = new Array();
  curr: any = {};
  max: number;
  montant1: number;
  id: string = "";
  searchText: string;
  user: any = {};
  receivedData:boolean;
  closeResult = '';
  col: string;
  total:string;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  }); 
  url1 = environment.urlStorage;
  dtOptions: DataTables.Settings = {};
  pageSize= 10;
  currentPage = 1;
  searchTerm: string;
  totalItems: number;
  pointindinars:number;
  constructor(private modalService: NgbModal, private serviceauth: AuthentificationService, private serviceconversion: ConversionService, private route: ActivatedRoute,private sharedService: SharedService,private servicecurrency: CurrencyService, private http: HttpClient) { }

  ngOnInit() {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
    });
    this.receivedData=false;
   this.loadPosts();
    this.user = this.serviceauth.getCurrentUser();
    this.servicecurrency.getbyid("1")
      .subscribe(data => {
        this.curr = data
        this.montant1 = this.curr.montantparpoint;
        this.max = this.curr.maxmontant;
        this.pointindinars = this.curr.pointindinars;
      })
  }

  loadPosts() {
    this.serviceconversion.getConversionsEnAttente(this.currentPage, this.pageSize, this.searchTerm).subscribe(
      (response) => {
        this.conversion = response.data;
        this.totalItems = response.meta.total;
        this.total = response.total;
      },
      (error) => {
        console.error('Error fetching posts', error);
      }
    );
  }

  pageChange(newPage: number) {
    this.currentPage = newPage;
    this.loadPosts();
  }


  open(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  search() {
    this.currentPage = 1;
    this.loadPosts();
  
  }
}
