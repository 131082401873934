import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { Admin } from 'src/app/modules/admin';
import { AdminService } from 'src/app/services/admin.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MustMatch } from '../must-match.validator';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-edit-admin',
  templateUrl: './edit-admin.component.html',
  styleUrls: ['./edit-admin.component.css']
})
export class EditAdminComponent implements OnInit {
  admin:Admin;
  user: any = {};
  id: string;
  searchText: string;
  closeResult = '';
  nom: string;
  prenom: string;
  email: string;
  telephone: number;
  passwordac: string;
  password: string;
  repassword: string;
  registerForm: FormGroup;
  submitted = false;
  users = false;
  actualite = false;
  publicite = false;
  conversion = false;
  tousconversion = false;
  jeux = false;
  mobile = false;
  contact = false;
  counter = false;
  product = false;
  listdelegate = false;
  demanddelegate = false;
  block = false;
  signal = false;
  gift = false;
  theme = false;

  user_point = false;
  user_diamond = false;
  user_delete = false;
  user_block = false;
  user_message = false;

  event = false;
  pret = false;
  store = false;
  codeparrainage =false;

  adminId: string;
  receivedData: any;
  constructor(private servicesadmin: AdminService,private route: ActivatedRoute,private serviceadmin: AdminService,
    private serviceauth: AuthentificationService,
    private modalService: NgbModal,
    private notifyService: NotificationService,private sharedService: SharedService,
    private formBuilder: FormBuilder,
    config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  ngOnInit() {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;
    this.adminId = this.route.snapshot.params['adminId'];

   this.getAdmibyId(this.adminId)

    this.user = this.serviceauth.getCurrentUser();
    this.registerForm = this.formBuilder.group({
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: [null, Validators.required],

    });
  }


  getAdmibyId(adminId) {
    this.servicesadmin.getbyid(adminId).pipe(first())
    .subscribe(data => {
      this.admin = data;
    })
  }


  get f() { return this.registerForm.controls; }

  public onusers(event: any) {
    this.admin.users = event.target.checked;
  }

  public oncounter(event: any) {
    this.admin.counter = event.target.checked;
  }

  public ontheme(event: any) {
    this.admin.theme = event.target.checked;
  }

  public onsignal(event: any) {
    this.admin.signal = event.target.checked;
  }

  public ongift(event: any) {
    this.admin.gift = event.target.checked;
  }

  public onlistdelegate(event: any) {
    this.admin.listdelegate = event.target.checked;
  }

  public ondemanddelegate(event: any) {
    this.admin.demanddelegate = event.target.checked;
  }

  public onblock(event: any) {
    this.admin.block = event.target.checked;
  }

  public onproduct(event: any) {
    this.admin.product = event.target.checked;
  }

  public onactualite(event: any) {
    this.admin.actualite = event.target.checked;
  }

  public onpublicite(event: any) {
    this.admin.publicite = event.target.checked;
  }

  public onconversion(event: any) {
    this.admin.conversion = event.target.checked;
  }

  public ontousconversion(event: any) {
    this.admin.tousconversion = event.target.checked;
  }

  public onjeux(event: any) {
    this.admin.jeux = event.target.checked;
  }

  public onmobile(event: any) {
    this.admin.mobile = event.target.checked;
  }

  public oncontact(event: any) {
    this.admin.contact = event.target.checked;
  }

  public onuserpoint(event: any) {
    this.admin.user_point = event.target.checked;
  }

  public onuserdiamond(event: any) {
    this.admin.user_diamond = event.target.checked;
  }

  public onusermessage(event: any) {
    this.admin.user_message = event.target.checked;
  }

  public onuserdelete(event: any) {
    this.admin.user_delete = event.target.checked;
  }

  public onuserblock(event: any) {
    this.admin.user_block = event.target.checked;
  }

  public onuserstore(event: any) {
    this.admin.store = event.target.checked;
  }


  public onuserevent(event: any) {
    this.admin.event = event.target.checked;
  }

  public onuserpret(event: any) {
    this.admin.pret = event.target.checked;
  }

  public oncodeparrainage(event: any) {
    this.admin.codeparrainage = event.target.checked;
  }

  public ondonate(event: any) {
    this.admin.donate = event.target.checked;
  }
  public onexplains(event: any) {
    this.admin.explains = event.target.checked;
  }
  public oninfo(event: any) {
    this.admin.info = event.target.checked;
  }

  modifier() {
    // this.submitted = true;
    // if (this.registerForm.invalid) {
    //   return;
    // }

    
    this.serviceadmin.updatedadmin(this.adminId ,this.admin.nom, this.admin.prenom, this.admin.email,this.admin.telephone,
      this.admin.users, this.admin.actualite, this.admin.publicite, this.admin.conversion, this.admin.tousconversion,
      this.admin.jeux, this.admin.mobile, this.admin.contact, this.admin.counter, this.admin.product, this.admin.listdelegate,
      this.admin.demanddelegate, this.admin.block, this.admin.signal, this.admin.gift, this.admin.theme
      ,this.admin.user_point, this.admin.user_diamond, this.admin.user_message, this.admin.user_delete,this.admin. user_block
      , this.admin.pret, this.admin.event,this.admin. store, this.admin.codeparrainage
      , this.admin.donate, this.admin.explains, this.admin.info)
      .pipe(first()).subscribe(() => {
        this.showToasterSuccess();
        this.modalService.dismissAll();
        this.nom = "";
        this.prenom = "";
        this.email = "";
        this.telephone = null;
        this.password = "";
        this.repassword = "";
        //window.location.reload();
        this.submitted = false;
        
      });
  }
  showToasterSuccess() {
    this.notifyService.showSuccess("تم تعديل المسؤول")
  }
}
