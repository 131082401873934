<meta name="viewport" content="width=device-width, initial-scale=1.0">

<app-menu></app-menu>

<body  [ngStyle]="{'width': receivedData ? '83%' : 'auto'}">
  <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.product == 1">
    <br><br>
    <div class="card">
      <div class="card-body">
        <div class="d-flex" style="justify-content: space-between;">
          <h2>طلبات شراء المنتجات</h2>
          <div>
              <button routerLink="/products-demands-history" class=" lab"> سجل المنتجات</button> &nbsp; &nbsp;
          </div>
      </div>

        <!-- <div class="d-flex col-md-2 shadow-border"style="
        margin-right: 2%; ">
          <input type="search" (search)="search()" class="inputSearch form-control" [(ngModel)]="searchTerm"
            name="searchTerm" style="border: none" placeholder="البحث">
            <img style="margin-top: 0px; margin-left: -15px; " src="../assets/search.png" (click)=" search()" class="searchIcon material-icons" width="35" height="35">
          </div> -->
        <br>
        
        <div class="row" style="overflow-x:auto;direction: rtl;">
          <table id="dtable" datatable [dtOptions]="dtOptions" class="row-border hover">
            <thead>
              <tr>
                <th style="border-bottom-right-radius: 10px; border-top-right-radius:10px">التاريخ</th>
                <th>	رمز الطلب</th>
                <th>الإسم </th>
                <!-- <th >البلد </th>
            <th> المدينة </th> -->
            <th>المحافظة</th>
                <th>العنوان الكامل </th>
                <th> رقم الهاتف</th>
                <th>رمز الإحالة</th>
                <th>الرصيد</th>
                <th>الأقساط</th>
                <th>ملاحظات</th>
                <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
              </tr>
            </thead>
            <tbody>
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;" *ngFor="let demandproduct of demandsproduct; let index = index;">

                <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ demandproduct.created_at | date: 'yyyy-MM-dd H:mm' }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{ demandproduct.orderRef }} </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ demandproduct.name }}</td>
                <!-- <td>{{ demandproduct.country }}</td>
                <td>{{ demandproduct.city }}</td> -->
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ demandproduct.city }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ demandproduct.address }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ demandproduct.phoneNumber }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ demandproduct.codeparrainage }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ demandproduct.montant }}</td>

                <td *ngIf="demandproduct.type == 'total'" style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">_</td>
                <td *ngIf="demandproduct.type == 'monthly'" style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ demandproduct.period }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ demandproduct.remark }}</td>
                <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <span class="d-flex" *ngIf="demandproduct.state == 'inProgress'">
                    <button id="submitB" [routerLink]="['/order-detail/', demandproduct.id]" >
                      <i class="material-icons" style="color: #30287a;">info</i>
                  </button>
                    <button #refuseButton (click)="open(content)" id="submitB">
                      <i class="material-icons" style="color: rgb(206, 6, 6);">cancel</i>
                    </button> &nbsp; &nbsp;
                    <button (click)="update(demandproduct.id) ; submitButton.disabled = true" #submitButton
                      id="submitB">
                      <i class="material-icons" style="color: green;">check_circle</i>
                    </button>
                  </span>
                </td>

                <ng-template #content let-modal>
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <div class="modal-body" dir="rtl">
                      <h4 id="h3"> سيتم رفض طلب شراء المنتج </h4><br>
                      <div class="justify-content-center d-flex">
                        <button (click)="updaterefuse(demandproduct.id);submitButton2.disabled = true" #submitButton2
                          type="button" class="lab btn">رفض الطلب</button>
                      </div><br>
                      <form [formGroup]="registerForm">
                        <div class="flex" dir="ltr">
                          <textarea placeholder="السبب" pattern=".*\S+.*" maxlength="250"
                            style="max-height: 100px !important;" name="cause" formControlName="cause" id=""
                            [(ngModel)]="cause" cols="30" rows="1" class="form-control"></textarea>
                          <button [disabled]="registerForm.invalid"
                            (click)="updaterefuseC(demandproduct.id, cause); submitButton1.disabled = true"
                            #submitButton1 type="button" class="lab btn">&nbsp; <i style="color: #fff;"
                              class="material-icons glyphicon">send</i> &nbsp;</button>
                        </div>
                        <div *ngIf="submitted && f['cause'].errors" class="invalid-feedback">
                          <div class="err" *ngIf="f['cause'].errors['required']">يجب ادخال السبب </div>
                        </div><br>
                      </form>
                    </div>
                  </div>
                </ng-template>


              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="user.role != 'globale' && user.product == 0 " style="padding-top: 10% !important">
    <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
      background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto;
      margin-bottom:auto; max-height: 800px; background-image: url('assets/droit.png')">
    </div>
  </div>

</body>

