<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '82.5%' : 'auto'}" style="overflow-y: auto">
  <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.tousconversion == 1"
    style="padding-right: 72px;">
    <br><br>

    <div class="card">
      <div class="card-body">
        <h2>قائمة التحويلات</h2>
        <br>
       <div class="d-flex">
        <h3>المبلغ الاجمالي للتحويلات اليومية :  </h3> &nbsp; <h3 class="demande1"> {{total}} </h3> &nbsp; <h3> دولار </h3>
       </div>
        
        <br>
        <div class="d-flex col-md-2">
          <input type="search" class="inputSearch form-control" [(ngModel)]="searchTerm" name="searchTerm">
          <i (click)=" search()" class="searchIcon material-icons">search</i>
        </div>
        <br>

        <div class="row" style="overflow-x:auto;direction: rtl; ">
          <table  class="row-border hover" style="padding: 11px;">
            <thead>
              <tr>
                <th scope="col" style="border-bottom-right-radius: 10px; border-top-right-radius:10px">#</th>
                <th scope="col">التاريخ</th>
                <th scope="col">المستخدم</th>
                <th scope="col">رمز الإحالة</th>
                <th scope="col">الصورة</th>
                <th scope="col"> الرصيد</th>
                <th scope="col">الرصيد المراد تحويله</th>
                <th scope="col">الرصيد بالدولار</th>
                <th>الرصيد بالدينار</th>
                <th scope="col" style="border-bottom-left-radius: 10px; border-top-left-radius:10px">الإجابة على الطلب
                </th>

              </tr>
            </thead>
            <tbody *ngFor="let conv of conversion ; let index = index">
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;">

                <td (click)="col = conv.id"
                  style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{((currentPage * 10)
                  -9)+index}}</td>
                <td (click)="col = conv.id" style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  {{ conv.created_at | date: 'yyyy-MM-dd H:mm' }}</td>

                <td (click)="col = conv.id" style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  {{ conv.nomprenom }}</td>
                <td (click)="col = conv.id" style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  {{ conv.codeparrainage }}</td>

                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <a *ngIf="conv.image != url1" (click)="open(content1)">
                    <i class="material-icons" style="color: #30287a;">image</i>
                  </a>
                  <ng-template #content1 let-modal>
                    <div class="modal-content">
                      <div class="modal-body" id="mbody" dir="rtl">
                        <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click'); ">
                          <span aria-hidden="true">&times;</span>
                        </button> <br>
                        <br>
                        <img height="150px !important" class="img-fluid" src="{{conv.image}}" alt="">
                      </div>
                    </div>
                  </ng-template>

                </td>

                <td (click)="col = conv.id" style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  {{ conv.montantUser }}</td>
                <td (click)="col = conv.id" style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  {{ conv.montant }}</td>
                <td (click)="col = conv.id" style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  {{ conv.date }}</td>
                  <td *ngIf="conv.dinar != null"  style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" (click)="col = conv.id">{{
                    conv.dinar }}</td>
                  <td *ngIf="conv.dinar == null" style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" (click)="col = conv.id">{{
                    conv.montant * pointindinars }}</td>
                <td (click)="col = conv.id"
                  style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <div *ngIf="conv.accepte == 'accepte' " class="demande">
                    الموافقة على الطلب
                  </div>
                  <div *ngIf="conv.accepte == 'refuse' " class="demande1">
                    رفض الطلب
                  </div>
                </td>

              </tr>
              <tr *ngIf="col == conv.id">
                <td colspan="7"
                  style="background-color: #fff; border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <div *ngIf="conv.typecarte != 'Zain Cash'"> <b>نوع البطاقة :</b> {{ conv.typecarte }} <br></div>
                  <div *ngIf="conv.typecarte != 'Zain Cash'"> <b>رقم البطاقة :</b> {{ conv.numerocarte }} <br></div>
                  <div *ngIf="conv.typecarte != 'Zain Cash'"> <b>الإسم واللقب :</b> {{ conv.nomusersurcarte }} <br>
                  </div>
                  <div *ngIf="conv.typecarte != 'Zain Cash'"> <b>تاريخ نهاية الصلاحية :</b> {{ conv.dateexpiration }}
                    <br>
                  </div>
                  <div *ngIf="conv.typecarte == 'Zain Cash'"> <b> {{ conv.typecarte }} </b> {{ conv.numerotelcarte }}
                  </div>
                </td>
                <td style="background-color: #fff; border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"
                  id="tdclose" (click)="col = ''">
                  <i class="material-icons" style="color: rgb(206, 6, 6);">cancel</i>
                </td>
                <td style="background-color: #fff; border:none; border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                </td>
              </tr>

            </tbody>
            <tr *ngIf="conversion?.length == 0 ">
              <td colspan="8" class="no-data-available" style="background-color: transparent;">لا توجد بيانات متوفرة
              </td>
            </tr>
          </table>
          <div style="padding-top: 10px;"> <ngb-pagination [maxSize]="5" [(page)]="currentPage" [pageSize]="pageSize"
            [collectionSize]="totalItems" (pageChange)="pageChange($event)"></ngb-pagination>
        </div>
        </div>
      </div>
    </div>

  </div>


  <div class="container" *ngIf="user.role != 'globale' && user.tousconversion == 0 "
    style="padding-top: 10% !important">

    <div class="container" style="
      width: 100%;
      height: 400px;
      max-height: 800px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      display:block;
      margin-top:auto;
      margin-bottom:auto;
      max-height: 800px;

      background-image: url('assets/droit.png')">



    </div>
  </div>
</body>