import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { Delegate } from 'src/app/modules/delegate';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DelegateService } from 'src/app/services/delegate.service';
import { NotificationService } from 'src/app/services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';


@Component({
  selector: 'app-traderdemands',
  templateUrl: './traderdemands.component.html',
  styleUrls: ['./traderdemands.component.css']
})
export class TraderdemandsComponent implements OnInit {

  p: number = 1;
  currentPage = 1;
  itemsPerPage = 10;
  pageSize: number;
  user: any = {};
  uri = environment.urlMarket;
  closeResult = '';
  cause: string;
  delegates: Delegate[];
  registerForm: FormGroup;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  submitted = false;
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  dtOptions: DataTables.Settings = {};
  receivedData: any;
  constructor(private serviceauth: AuthentificationService, private http: HttpClient, private modalService: NgbModal,
    private servicedelegate: DelegateService, private route: ActivatedRoute, private sharedService: SharedService, private notifyService: NotificationService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.user = this.serviceauth.getCurrentUser();
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;

    });
    this.receivedData = false;
    var that = this;
    this.dtOptions = {
      pagingType: 'simple_numbers',
      language: {
        processing: "يرجى الإنتظار...",
        searchPlaceholder: 'البحث', search: "",
        lengthMenu: " _MENU_ ",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        paginate: {
          first: "",
          previous: "<i class='material-icons'>navigate_next</i> ",
          next: "<i class='material-icons'>navigate_before</i>",
          last: ""
        },
      },
      info: false,
      paging: true,
      ordering: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.currentPage = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        const rowData = {
          no_of_records: dataTablesParameters.length,
          page: this.currentPage,
          group_id: 1
        };
        that.http.post<DataTablesResponse>(this.uri + 'TGSFCNYHNBGF872DZ154F', dataTablesParameters, { headers: this.headers }).subscribe(resp => {
          const details = JSON.parse(JSON.stringify(resp.original));
          that.delegates = details.data;
          callback({
            recordsTotal: details.recordsTotal,
            recordsFiltered: details.recordsFiltered,
            data: [],
          });
        });
      },
      columns: [
        { data: "created_at" },
        { data: "codeparrainage" },
        { data: "name" },
        { data: "country" },
        { data: "city" },
        { data: "address" },
        { data: "phoneNumber" }
      ],
    };
    this.registerForm = this.formBuilder.group({
      cause: ['', Validators.required],
    });
  }

  get f() { return this.registerForm.controls; }
  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }
  update(id: string) {
    this.servicedelegate.updateTrader(id).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.showToasterSuccess();
      this.modalService.dismissAll();
      this.cause = "";
    })
  }

  updaterefuse(id: string) {
    this.servicedelegate.updaterefuseTrader(id, this.user.email).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.showToasterError();
      this.modalService.dismissAll();
      this.cause = "";
    })
  }

  updaterefuseC(id: string, cause: string) {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.servicedelegate.updaterefuseCTrader(cause, id, this.user.email).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.showToasterError();
      this.modalService.dismissAll();
      this.submitted = false;
      this.cause = "";
    })
  }


  showToasterError() {
    this.notifyService.showSuccess("تم رفض طلب التاجر ")
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("تمت المافقة على طلب التاجر")
  }


  openSm(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
