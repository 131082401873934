import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { DonateService } from 'src/app/services/donate.service';
import { SharedService } from 'src/app/shared.service';
import { first } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-special-publication',
  templateUrl: './special-publication.component.html',
  styleUrls: ['./special-publication.component.css']
})
export class SpecialPublicationComponent implements OnInit {
  user: any = {};
  receivedData: any;
  photoForm: FormGroup;
  image:File
  link:string="";
  constructor(private fb: FormBuilder,private serviceauth: AuthentificationService,private sharedService: SharedService,
       private servicedonate: DonateService,    private notifyService: NotificationService
  ) { 
  
  }

  ngOnInit(): void {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;
    this.photoForm = this.fb.group({
      image: [File, Validators.required], 
    });
    this.user = this.serviceauth.getCurrentUser();
  }

  selectImage2(event: any) {
    var reader = new FileReader();
    const file = event.target.files[0];
    this.image = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link = event.target.result;
    }
  }

    onSubmit() {
      if (this.photoForm.valid) {
        const formData = new FormData();
        formData.append('image', this.image);

  
        this.servicedonate.addPublication(formData)
          .pipe(first())
          .subscribe(() => {
            this.showToasterSuccess();
            this.resetForm();
          });
      }
    }

    showToasterSuccess() {
      this.notifyService.showSuccess("تم إضافة منشور خاص  ");
    }
    resetForm() {
      this.photoForm.reset();
      this.image = null;
      this.link=""

    }
}
