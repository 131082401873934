<app-menu></app-menu>

<body>
    <div class="container" *ngIf="user.role == 'globale' || user.mobile == 1" dir="rtl">
        <br> <br>
        <h2>تفاصيل الطلب</h2>
        <br> <br>
        <div class="container">

            <div *ngFor="let cart of carts">
                <div class="row">
                    <div class="col-md-2">
                        <img class="img-fluid" style="height: 130px;" [src]="cart.image" alt="">
                        <br>
                    </div>
                    <div class="col-md-10">
                        <div class="card-Order">
                            <div class="row">
                                <div class="col-md-2">
                                    <strong>إسم المنتج</strong><br><br>
                                    <strong>ثمن المنتج</strong>
                                </div>
                                <div class="col-md-2">
                                    {{cart.name}} <br> <br>
                                    {{cart.price}} نقطة
                                </div>
                                <div class="col-md-2">
                                    <strong>الكمية </strong> <br><br>
                                    <strong>المجموع </strong>
                                </div>
                                <div class="col-md-2">
                                    {{cart.quantity}} <br><br>
                                    {{cart.price * cart.quantity}} نقطة
                                </div>
                                <div class="col-md-2">
                                    <strong> اللون </strong><br><br>
                                    <strong> المقاس</strong>
                                </div>
                                <div class="col-md-2">
                                    {{cart.color}} <br><br>
                                    {{cart.size}} 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>
            <div class="row">
                <div class="col-md-2">

                </div>
                <div class="col-md-10">
                    <div class="card-Order">
                        <div class="row">
                            <div class="col-md-3">
                                <strong> مجموع النقاط</strong><br><br>
                                <strong style="color: red;">{{order.totalPrice}} نقطة</strong><br>
                            </div>
                            <div class="col-md-3">
                                <strong *ngIf="order.type =='monthly'">  الأقساط</strong><br><br>
                                <strong *ngIf="order.type =='monthly'" style="color: red;">{{order.period}} </strong><br>
                            </div>
                            
                            <div class="col-md-3" *ngIf="order.state == 'inProgress'">
                                <button class="form-control orderBtnDanger" #refuseButton (click)="open(content)"
                                    id="submitB">
                                    <i class="material-icons">cancel</i> &nbsp; رفض
                                </button> &nbsp; &nbsp;

                            </div>
                            <div class="col-md-3" *ngIf="order.state == 'inProgress'">
                                <button class="form-control orderBtnSuccess"
                                    (click)="update(order.id) ; submitButton.disabled = true" #submitButton
                                    id="submitB">
                                    <i class="material-icons">check_circle</i> &nbsp; موافقة
                                </button>
                            </div>
                            <ng-template #content let-modal>
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="btn-close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')"></button>
                                    </div>
                                    <div class="modal-body" dir="rtl">
                                        <h4 id="h3"> سيتم رفض طلب شراء المنتج </h4><br>
                                        <div class="justify-content-center d-flex">
                                            <button (click)="updaterefuse(order.id);submitButton2.disabled = true"
                                                #submitButton2 type="button" class="lab btn">رفض الطلب</button>
                                        </div><br>
                                        <form [formGroup]="registerForm">
                                            <div class="flex" dir="ltr">
                                                <textarea placeholder="السبب" pattern=".*\S+.*" maxlength="250"
                                                    style="max-height: 100px !important;" name="cause"
                                                    formControlName="cause" id="" [(ngModel)]="cause" cols="30" rows="1"
                                                    class="form-control"></textarea>
                                                <button [disabled]="registerForm.invalid"
                                                    (click)="updaterefuseC(order.id, cause); submitButton1.disabled = true"
                                                    #submitButton1 type="button" class="lab btn">&nbsp; <i
                                                        style="color: #fff;" class="material-icons glyphicon">send</i>
                                                    &nbsp;</button>
                                            </div>
                                            <div *ngIf="submitted && f['cause'].errors" class="invalid-feedback">
                                                <div class="err" *ngIf="f['cause'].errors['required']">يجب ادخال السبب
                                                </div>
                                            </div><br>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <br>
        </div>
    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.product == 0 " style="padding-top: 10% !important">
        <div class="container" style="width: 100%; height: 400px;max-height: 800px;background-size: contain;
      background-repeat: no-repeat; background-position: center center; display:block; margin-top:auto;
      margin-bottom:auto; max-height: 800px;background-image: url('assets/droit.png')">
        </div>
    </div>

</body>