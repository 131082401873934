import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DemandproductService {

  uri = environment.urlMarket;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  constructor(private http: HttpClient) { }


  getProductHistory(page: number , pageSize: number , searchTerm:string): Observable<any> {
    const obj={
      searchTerm : searchTerm
    }
    console.log(pageSize)
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.post(`${this.uri}indexDemandproductHistory?page=${page}`, obj,{  headers: this.headers , params });
  }


  update(id: string) {
    const obj = {};
    return this.http.post(`${this.uri}acceptDemandproduct/${id}`, obj, { headers: this.headers });
  }

  updaterefuse(id: string, admin : string) {
    const obj = {admin : admin};
    return this.http.post(`${this.uri}refuseDemandproduct/${id}`, obj, { headers: this.headers });
  }

  updaterefuseC(cause: string, id: string, admin : string) {
    const obj = {
      cause: cause,
      admin : admin
    };
    return this.http.post(`${this.uri}refuseDemandproductCause/${id}`, obj, { headers: this.headers });
  }

  updatepret(id: string) {
    const obj = {};
    return this.http.post(`${this.uri}acceptDemandpret/${id}`, obj, { headers: this.headers });
  }

  updaterefusepret(id: string, admin : string) {
    const obj = {admin : admin};
    return this.http.post(`${this.uri}refuseDemandpret/${id}`, obj, { headers: this.headers });
  }

  updaterefuseCpret(cause: string, id: string, admin : string) {
    const obj = {
      cause: cause,
      admin : admin
    };
    return this.http.post(`${this.uri}refuseDemandpretCause/${id}`, obj, { headers: this.headers });
  }

  getPrets(page: number , pageSize: number , searchTerm:string): Observable<any> {
    const obj={
      searchTerm : searchTerm
    }
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.post(`${this.uri}RFJVDNKDNCKELCNKFRVN?page=${page}`, obj,{  headers: this.headers , params });
  }


  getOrderbyId(id: string) {

    return this
      .http
      .post(`${this.uri}order/${id}`, { headers: this.headers });
  }
}
