import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActualiteComponent } from './components/actualite/actualite.component';
import { AuthGuard } from './components/auth.guard';
import { ConversionComponent } from './components/conversion/conversion.component';
import { IsNotSignedInGuard } from './components/IsNotSignedInGuard.guard';
import { ListeAdminComponent } from './components/liste-admin/liste-admin.component';
import { ListeUtilisateurComponent } from './components/liste-utilisateur/liste-utilisateur.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileComponent } from './components/profile/profile.component';
import { QuestionComponent } from './components/question/question.component';
import { TousconversionsComponent } from './components/tousconversions/tousconversions.component';
import { AjoutAdminComponent } from './components/ajout-admin/ajout-admin.component';
import { MobileComponent } from './components/mobile/mobile.component';
import { PubliciteComponent } from './components/publicite/publicite.component';
import { ListeMobileComponent } from './components/liste-mobile/liste-mobile.component';
import { ContactComponent } from './components/contact/contact.component';
import { ActualiteResolver } from './resolvers/actualite.resolver';
import { PubliciteResolver } from './resolvers/publicite.resolver';
import { AdminResolver } from './resolvers/admin.resolver';
import { ThemeResolver } from './resolvers/theme.resolver';
import { ThemeComponent } from './components/theme/theme.component';
import { CadeauComponent } from './components/cadeau/cadeau.component';
import { ListeSignalComponent } from './components/liste-signal/liste-signal.component';
import { ListeBlockComponent } from './components/liste-block/liste-block.component';
import { CompteurComponent } from './components/compteur/compteur.component';
import { DelegateComponent } from './components/delegate/delegate.component';
import { DemandedelegateComponent } from './components/demandedelegate/demandedelegate.component';
import { ProductComponent } from './components/product/product.component';
import { DemandproductComponent } from './components/demandproduct/demandproduct.component';
import { EventComponent } from './components/event/event.component';
import { EditproductComponent } from './components/editproduct/editproduct.component';
import { EditAdminComponent } from './components/edit-admin/edit-admin.component';
import { UserPublicityComponent } from './components/user-publicity/user-publicity.component';
import { OfferPublicityComponent } from './components/offer-publicity/offer-publicity.component';
import { GiftactualiteComponent } from './components/giftactualite/giftactualite.component';
import { DemandetrendComponent } from './components/demandetrend/demandetrend.component';
import { PublicationdetailComponent } from './components/publicationdetail/publicationdetail.component';
import { AddpostComponent } from './components/addpost/addpost.component';
import { PostslistComponent } from './components/postslist/postslist.component';
import { StoreComponent } from './components/store/store.component';
import { EditpostComponent } from './components/editpost/editpost.component';
import { OrderdetailComponent } from './components/orderdetail/orderdetail.component';
import { CodeparrainageComponent } from './components/codeparrainage/codeparrainage.component';
import { CodeparrainageDemandComponent } from './components/codeparrainage-demand/codeparrainage-demand.component';
import { FortuneComponent } from './components/fortune/fortune.component';
import { HistoriqueComponent } from './components/historique/historique.component';
import { ListTrendComponent } from './components/list-trend/list-trend.component';
import { UsertodeleteComponent } from './components/usertodelete/usertodelete.component';
import { PretComponent } from './components/pret/pret.component';
import { ListPretComponent } from './components/list-pret/list-pret.component';
import { CategoryComponent } from './components/category/category.component';
import { AddproductComponent } from './components/addproduct/addproduct.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { TraderComponent } from './components/trader/trader.component';
import { TraderdemandsComponent } from './components/traderdemands/traderdemands.component';
import { TraderpercentageComponent } from './components/traderpercentage/traderpercentage.component';
import { CountrydelegateComponent } from './components/countrydelegate/countrydelegate.component';
import { CountrytradeComponent } from './components/countrytrade/countrytrade.component';



const routes: Routes = [
  { path: ``, canActivate: [IsNotSignedInGuard], pathMatch: 'full', component: LoginComponent },

  { path: `adds`, canActivate: [AuthGuard], resolve: { actualite: ActualiteResolver }, component: ActualiteComponent, pathMatch: 'full' },

  { path: `conversion`, canActivate: [AuthGuard], component: ConversionComponent, pathMatch: 'full' },

  { path: `profile`, canActivate: [AuthGuard], component: ProfileComponent, pathMatch: 'full' },

  { path: 'allconversions', canActivate: [AuthGuard], component: TousconversionsComponent, pathMatch: 'full' },

  { path: 'questions', canActivate: [AuthGuard], component: QuestionComponent, pathMatch: 'full' },

  { path: 'users', canActivate: [AuthGuard], component: ListeUtilisateurComponent, pathMatch: 'full' },

  { path: 'list-blocks', canActivate: [AuthGuard], component: ListeBlockComponent, pathMatch: 'full' },

  { path: 'signal-list', canActivate: [AuthGuard], component: ListeSignalComponent, pathMatch: 'full' },

  { path: 'users', canActivate: [AuthGuard], component: ListeUtilisateurComponent, pathMatch: 'full' },

  { path: 'admin', canActivate: [AuthGuard], resolve: { admin: AdminResolver }, component: ListeAdminComponent, pathMatch: 'full' },

  { path: 'add-admin', canActivate: [AuthGuard], component: AjoutAdminComponent, pathMatch: 'full' },

  { path: 'publicite', canActivate: [AuthGuard], resolve: { publicite: PubliciteResolver }, component: PubliciteComponent, pathMatch: 'full' },

  { path: 'carte-mobile', canActivate: [AuthGuard], component: MobileComponent, pathMatch: 'full' },

  { path: 'mobile-list', canActivate: [AuthGuard], component: ListeMobileComponent, pathMatch: 'full' },

  { path: 'support', canActivate: [AuthGuard], component: ContactComponent, pathMatch: 'full' },

  { path: 'themes', canActivate: [AuthGuard], resolve: { themes: ThemeResolver }, component: ThemeComponent, pathMatch: 'full' },

  { path: 'gifts', canActivate: [AuthGuard], component: CadeauComponent, pathMatch: 'full' },

  { path: 'counters', canActivate: [AuthGuard], component: CompteurComponent, pathMatch: 'full' },

  { path: 'delegates-list', canActivate: [AuthGuard], component: DelegateComponent, pathMatch: 'full' },

  { path: 'delegates-demands', canActivate: [AuthGuard], component: DemandedelegateComponent, pathMatch: 'full' },

  { path: 'products-demands', canActivate: [AuthGuard], component: DemandproductComponent, pathMatch: 'full' },

  { path: 'product-list', canActivate: [AuthGuard], component: ProductComponent, pathMatch: 'full' },

  { path: 'events', canActivate: [AuthGuard], component: EventComponent, pathMatch: 'full' },

  { path: 'editProduct/:productId', canActivate: [AuthGuard], component: EditproductComponent, pathMatch: 'full' },

  { path: 'editAdmin/:adminId', canActivate: [AuthGuard], component: EditAdminComponent, pathMatch: 'full' },

  { path: 'userPublicities', canActivate: [AuthGuard], component: UserPublicityComponent, pathMatch: 'full' },

  { path: 'offerPublicities', canActivate: [AuthGuard], component: OfferPublicityComponent, pathMatch: 'full' },

  { path: 'gift-actualite', canActivate: [AuthGuard], component: GiftactualiteComponent, pathMatch: 'full' },

  { path: 'trend-demands', canActivate: [AuthGuard], component: DemandetrendComponent, pathMatch: 'full' },

  { path: 'publication-detail/:pubId', canActivate: [AuthGuard], component: PublicationdetailComponent, pathMatch: 'full' },

  { path: 'add-post', canActivate: [AuthGuard], component: AddpostComponent, pathMatch: 'full' },

  { path: 'posts-list', canActivate: [AuthGuard], component: PostslistComponent, pathMatch: 'full' },

  { path: 'store', canActivate: [AuthGuard], component: StoreComponent, pathMatch: 'full' },

  { path: 'edit-post/:pubId', canActivate: [AuthGuard], component: EditpostComponent, pathMatch: 'full' },

  { path: 'order-detail/:orderId', canActivate: [AuthGuard], component: OrderdetailComponent, pathMatch: 'full' },

  { path: 'codeparrainage', canActivate: [AuthGuard], component: CodeparrainageComponent, pathMatch: 'full' },

  { path: 'codeparrainage-demands', canActivate: [AuthGuard], component: CodeparrainageDemandComponent, pathMatch: 'full' },

  { path: 'fortune-wheel', canActivate: [AuthGuard], component: FortuneComponent, pathMatch: 'full' },

  { path: 'historique/:userId/:codeparrainage', canActivate: [AuthGuard], component: HistoriqueComponent, pathMatch: 'full' },

  { path: 'trend-list', canActivate: [AuthGuard], component: ListTrendComponent, pathMatch: 'full' },

  { path: 'usersToDelete', canActivate: [AuthGuard], component: UsertodeleteComponent, pathMatch: 'full' },

  { path: 'pret', canActivate: [AuthGuard], component: PretComponent, pathMatch: 'full' },

  { path: 'pret-list', canActivate: [AuthGuard], component: ListPretComponent, pathMatch: 'full' },

  { path: 'category', canActivate: [AuthGuard], component: CategoryComponent, pathMatch: 'full' },

  { path: 'add-product', canActivate: [AuthGuard], component: AddproductComponent, pathMatch: 'full' },

  { path: 'products-demands-history', canActivate: [AuthGuard], component: OrderHistoryComponent, pathMatch: 'full' },

  { path: 'trader', canActivate: [AuthGuard], component: TraderComponent, pathMatch: 'full' },

  { path: 'trader-demands', canActivate: [AuthGuard], component: TraderdemandsComponent, pathMatch: 'full' },

  { path: 'trader-percentage', canActivate: [AuthGuard], component: TraderpercentageComponent, pathMatch: 'full' },

  { path: 'trader-countries', canActivate: [AuthGuard], component: CountrytradeComponent, pathMatch: 'full' },

  { path: 'delegate-countries', canActivate: [AuthGuard], component: CountrydelegateComponent, pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
