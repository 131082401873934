<html>

<app-menu></app-menu>

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>

<body [ngStyle]="{'width': receivedData ? '80%' : '100%'}" style="padding-right: 1px;">
  <div class="container" dir="rtl" [ngStyle]="{'margin-right': receivedData ? '4%' : 'auto'}"
    *ngIf="user.role == 'globale' || user.conversion == 1">
    <br><br>
    <div class="card">
      <div class="card-body">
        <h2 id="h4" style="
        margin-right: 112px;
    ">خصائص التحويل</h2>
        <div class="row" id="tag" dir="rtl" style="background-color:#b3daffd9;  border-radius: 15px;">
          <div class="col-md-3">
            <div class="row"><label> قيمة النقطة بالدولار</label>
            </div>
            <form [formGroup]="registerForm1">
              <div class="flex">
                <input type="text" id="inp" name="montant1" formControlName="montant1" class="form-control"
                  [(ngModel)]="montant1" style="border-radius: 10px;width: 174px;" (input)="limitInputLength($event)"
                  oninput="this.value = this.value.replace(/[^\d.]/g, ''); if(this.value < 0) this.value = 0;">

                <a (click)="updatemontant()" *ngIf="user.role =='globale' " id="a1">
                  <i class="material-icons" style="color: #fdfdfd;background: green;  border-radius:9px">check</i>
                </a>
              </div>
              <div *ngIf="(submitted1 && a['montant1'].errors)||(montant1==0)" class="invalid-feedback">
                <div class="err" *ngIf="(a['montant1'].errors['required'])||(montant1==0)">يجب ادخال قيمة النقطة </div>
              </div>

            </form>
          </div>
          <div class="col-md-3">
            <div class="row"><label>الحد الادنى لتحويل النقاط</label></div>
            <form [formGroup]="registerForm2">
              <div class=" flex">
                <input type="number" name="max" id="inp2" value="" class="form-control"
                  (input)="limitInputLength($event)" formControlName="max" [(ngModel)]="max"
                  style="border-radius: 10px;" pattern="[0-9]+"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');" style="width: 174px;border-radius:9px">
                <a (click)="updateminpoint()" *ngIf="user.role =='globale' " id="a1">
                  <i class="material-icons" style="color: #fdfdfd;background: green;  border-radius:9px">check</i>
                </a>
              </div>
              <div *ngIf="submitted2 && b['max'].errors" class="invalid-feedback">
                <div class="err" *ngIf="b['max'].errors['required']">يجب ادخال الحد الادنى </div>
              </div>
              <div *ngIf="max==0" class="invalid-feedback">
                <div class="err" *ngIf="max==0">يجب ادخال قيمة النقطة </div>
              </div>
            </form>
          </div>
          <div class="col-md-3">
            <div class="row"><label class="txt"> ثمن الغرفة </label></div>
            <form [formGroup]="registerForm3">
              <div class=" flex">
                <input type="number" id="inp3" name="prix_room" value="" formControlName="prix_room"
                  class="form-control" style="border-radius: 10px;width: 174px;" [(ngModel)]="prix_room"
                  pattern="[0-9]+" (input)="limitInputLength($event)"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');">
                <a (click)="updateprixroom()" *ngIf="user.role =='globale' " id="a1">
                  <i class="material-icons" style="color: #fdfdfd;background: green;  border-radius:9px">check</i>
                </a>
              </div>
              <div *ngIf="submitted3 && c['prix_room'].errors" class="invalid-feedback">
                <div class="err" *ngIf="c['prix_room'].errors['required']">يجب ادخال ثمن الغرفة </div>
              </div>
              <div *ngIf="prix_room==0" class="invalid-feedback">
                <div class="err" *ngIf="prix_room==0">يجب ادخال ثمن الغرفة </div>
              </div>
            </form>
          </div>

          <div class="col-md-3">
            <div class="row"><label> قيمة النقطة بالدينار </label>
            </div>
            <form [formGroup]="registerForm4">
              <div class="flex">
                <input type="text" id="inp" name="pointindinars" formControlName="pointindinars" class="form-control"
                  [(ngModel)]="pointindinars" style="border-radius: 10px;width: 174px;" (input)="limitInputLength($event)"
                  oninput="this.value = this.value.replace(/[^\d.]/g, ''); if(this.value < 0) this.value = 0;">

                <a (click)="updatepointindinars()" *ngIf="user.role =='globale' " id="a1">
                  <i class="material-icons" style="color: #fdfdfd;background: green;  border-radius:9px">check</i>
                </a>
              </div>
              <div *ngIf="(submitted4 && d['pointindinars'].errors)||(pointindinars==0)" class="invalid-feedback">
                <div class="err" *ngIf="(d['pointindinars'].errors['required'])||(pointindinars==0)">يجب ادخال قيمة النقطة </div>
              </div>

            </form>
          </div>

          <div class="col-md-3">
            <div class="row"><label> ثمن الصورة في الرسائل </label>
            </div>
            <form [formGroup]="registerForm5">
              <div class="flex">
                <input type="text" id="inp" name="imagePrice" formControlName="imagePrice" class="form-control"
                  [(ngModel)]="imagePrice" style="border-radius: 10px;width: 174px;" (input)="limitInputLength($event)"
                  oninput="this.value = this.value.replace(/[^\d.]/g, ''); if(this.value < 0) this.value = 0;">

                <a (click)="updateimagePrice()" *ngIf="user.role =='globale' " id="a1">
                  <i class="material-icons" style="color: #fdfdfd;background: green;  border-radius:9px">check</i>
                </a>
              </div>
              <div *ngIf="(submitted5 && e['imagePrice'].errors)||(imagePrice==0)" class="invalid-feedback">
                <div class="err" *ngIf="(e['imagePrice'].errors['required'])||(imagePrice==0)">يجب ادخال قيمة النقطة </div>
              </div>

            </form>
          </div>
          <!-- <div class="col-md-3">
          <div class="row"><label> ثمن الإنضمام للغرفة </label></div>
          <div class=" flex">
            <input type="number"  name="prix_favori" value=""  class="form-control" [(ngModel)]="prix_favori">
            <a (click)="updateprixfavori()" *ngIf="user.role =='globale' " id="a1">
            <i class="material-icons" style="color: green;">check</i>
            </a>
          </div>
        </div> -->
        </div><br>
        <h2 id="h4">قائمة طلبات التحويل</h2>
        <br>
        <div class="d-flex col-md-2">
          <input type="search" (search)="search()" class="inputSearch form-control" [(ngModel)]="searchTerm"
            name="searchTerm">
          <i (click)=" search()" class="searchIcon material-icons">search</i>
        </div>
        <br>
        <div class="row" style="overflow-x:auto;direction: rtl;">
          <table class="row-border hover" style="padding-top: 9px;">
            <thead>
              <tr>
                <th style="border-bottom-right-radius: 10px; border-top-right-radius:10px">#</th>
                <th>التاريخ</th>
                <th>المستخدم</th>
                <th>رمز الإحالة</th>
                <th>العداد</th>
                <th>الصورة</th>
                <th> الرصيد</th>
                <th>الرصيد المراد تحويله</th>
                <th>الرصيد بالدولار</th>
                <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
              </tr>
            </thead>
            <tbody *ngFor="let conv of conversion; let index = index;">
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;">
                <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"
                  (click)="col = conv.id"> {{((currentPage * 10) -9)+index}}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" (click)="col = conv.id">{{
                  conv.created_at | date: 'yyyy-MM-dd H:mm' }}</td>
                <td style="border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" (click)="col = conv.id">
                  <div class="d-flex">{{ conv.nomprenom }}
                    <img *ngIf="conv.badge" height="30" width="30" [src]="conv.badge" alt="">
                  </div>
                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" (click)="col = conv.id">{{
                  conv.codeparrainage }}</td>
                <td style="border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> <a class="action"
                    (click)="openSm3(contentOffer); getOfferUser2(conv.user_id)">
                    <i class="material-icons" style="color: #30287a;">av_timer</i>
                  </a>
                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <a *ngIf="conv.image != url1" (click)="open(content1)">
                    <i class="material-icons" style="color: #30287a;">image</i>
                  </a>

                  <ng-template #content1 let-modal>
                    <div class="modal-content">
                      <div class="modal-body" id="mbody" dir="rtl">
                        <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click'); ">
                          <span aria-hidden="true">&times;</span>
                        </button> <br>
                        <br>
                        <img height="150px !important" class="img-fluid" src="{{conv.image}}" alt="">
                      </div>
                    </div>
                  </ng-template>
                </td>

                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" (click)="col = conv.id">
                  {{ conv.montantUser }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" (click)="col = conv.id">
                  <div>{{ conv.montant }}</div>
                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" (click)="col = conv.id">{{
                  conv.date }}</td>
                <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"><span
                    class="d-flex">
                    <button (click)="open(content)" #refuseButton id="submitB">
                      <i class="material-icons" style="color: rgb(206, 6, 6);">cancel</i>
                    </button> &nbsp; &nbsp;
                    <button (click)="update(conv.id) ;submitButton.disabled = true" #submitButton id="submitB">
                      <i class="material-icons" style="color: green; border-radius:9px">check_circle</i>
                    </button>
                  </span>
                </td>
                <ng-template #content let-modal>
                  <div class="modal-content">
                    <div class="modal-header">
                      <button id="close2" type="button" class="close" aria-label="Close" style="margin-left: 94%;"
                        (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body" dir="rtl">
                      <h4 id="h3"> سيتم رفض طلب التحويل </h4><br>
                      <div class="justify-content-center d-flex">
                        <button (click)="updaterefuse(conv.id); submitButton2.disabled = true" #submitButton2
                          type="button" class="lab btn">رفض الطلب</button>
                      </div><br>
                      <form [formGroup]="registerForm">
                        <div class="flex" dir="ltr">
                          <textarea placeholder="السبب" pattern=".*\S+.*" maxlength="250"
                            style="max-height: 100px !important;" name="cause" formControlName="cause" id=""
                            [(ngModel)]="cause" cols="30" rows="1" class="form-control"></textarea>
                          <button [disabled]="registerForm.invalid"
                            (click)="updaterefuseC(conv.id, cause); submitButton1.disabled = true" #submitButton1
                            type="button" class="lab btn"> <i style="color: #fff;"
                              class="material-icons glyphicon">send</i> &nbsp;</button>
                        </div>
                        <div *ngIf="submitted && f['cause'].errors" class="invalid-feedback">
                          <div class="err" *ngIf="f['cause'].errors['required']">يجب ادخال السبب </div>
                        </div><br>
                      </form>
                    </div>
                  </div>
                </ng-template>

                <ng-template #contentOffer let-modal>
                  <div class="modal-content" dir="rtl">
                    <button id="close2" type="button" class="close" aria-label="Close"
                      style="width: 29px;margin-right: 15px;margin-top: 14px;" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">

                      <table class="table row-border hover">
                        <tbody>

                          <th style="background-color: rgba(98, 180, 218, 0.479);text-align-last: center;"> مجموع المجوهرات </th>
                          <th style="background-color: rgba(98, 180, 218, 0.479); border: 1px;text-align-last: center;"> مجموع النقاط </th>
                          <tr>
                            <td style="text-align-last: center;">{{offers2.diamondAmount}} </td>

                            <td style="text-align-last: center;">{{offers2.pointAmount}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </ng-template>

              </tr>
              <tr *ngIf="col == conv.id">
                <td colspan="8"
                  style="background-color: #fff; border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <div *ngIf="conv.typecarte != 'Zain Cash'"> <b>نوع البطاقة :</b> {{ conv.typecarte }} <br></div>
                  <div *ngIf="conv.typecarte != 'Zain Cash'"> <b>رقم البطاقة :</b> {{ conv.numerocarte }} <br></div>
                  <div *ngIf="conv.typecarte != 'Zain Cash'"> <b>الإسم واللقب :</b> {{ conv.nomusersurcarte }} <br>
                  </div>
                  <div *ngIf="conv.typecarte != 'Zain Cash'"> <b>تاريخ نهاية الصلاحية :</b> {{ conv.dateexpiration }}
                    <br>
                  </div>
                  <div *ngIf="conv.typecarte == 'Zain Cash'"> <b> {{ conv.typecarte }} </b> {{ conv.numerotelcarte }}
                  </div>
                </td>
                <td style="background-color: #fff; border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"
                  id="tdclose" (click)="col = ''">
                  <i class="material-icons" style="color: rgb(206, 6, 6);">cancel</i>
                </td>
                <td style="background-color: #fff; border:none; border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                </td>
              </tr>
            </tbody>
            <tr *ngIf="conversion?.length == 0 ">
              <td colspan="9" class="no-data-available">لا توجد بيانات متوفرة</td>
            </tr>
          </table>
          <div style="padding-top: 10px;"> <ngb-pagination [maxSize]="5" [(page)]="currentPage" [pageSize]="pageSize"
              [collectionSize]="totalItems" (pageChange)="pageChange($event)"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="user.role != 'globale' && user.conversion == 0 " style="padding-top: 10% !important">
    <div class="container" style="width: 100%; height: 400px; max-height: 800px;background-size: contain;
      background-repeat: no-repeat;background-position: center center;display:block;margin-top:auto;
      margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
    </div>
  </div>

</body>


</html>