<meta name="viewport" content="width=device-width, initial-scale=1.0">

<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '83%' : 'auto'}">
  <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.pret == 1">
    <br><br>
    <div class="card">
      <div class="card-body">
        <h2>سجل طلبات شراء القروض</h2>
        <br>
        <div class="d-flex col-md-2">
          <input type="search" (search)="search()" class="inputSearch form-control" [(ngModel)]="searchTerm"
            name="searchTerm">
          <i (click)=" search()" class="searchIcon material-icons">search</i>
        </div>
        <br>
        <div class="row" style="overflow-x:auto;direction: rtl;">
          <table class="row-border hover">
            <thead>
              <tr>
                <th style="border-bottom-right-radius: 10px; border-top-right-radius:10px">التاريخ</th>
                <th> رمز الطلب</th>
                <th>قيمة القرض</th>
                <th>رمز الإحالة</th>
                <th>الرصيد</th>
                <th>الأقساط</th>
                <td></td>
                <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
              </tr>
            </thead>
            <tbody>
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;"
                *ngFor="let demandproduct of demandsproduct; let index = index;">
                <td style="  border-left: none;">{{
                  demandproduct.created_at | date: 'yyyy-MM-dd H:mm' }}</td>
                <td > {{ demandproduct.orderRef }} </td>
                <td >{{ demandproduct.totalPrice }}</td>
                <td >{{ demandproduct.codeparrainage }}</td>
                <td >{{ demandproduct.montant }}</td>
                <td >{{ demandproduct.period }} </td>
                <td> <button *ngIf="demandproduct.state != 'refused' " id="submitB" [routerLink]="['/order-detail/', demandproduct.id]" >
                    <i class="material-icons" style="color: #30287a;">info</i>
                </button></td>
                <td style="  border-right: none;">
                
                  <a *ngIf="demandproduct.state == 'accepted' " style="color: green; cursor: default;">
                    تم الدفع
                  </a>
                  <a *ngIf="demandproduct.state == 'refused' " style="color: red; cursor: default;">
                    رفض الطلب
                  </a>
                  <a *ngIf="demandproduct.state == 'inPayement' " style="color: #30287a; cursor: default;">
                    قيد الدفع
                  </a>
                  
                </td>
              
              </tr>
            </tbody>
          </table>
          <div style="padding-top: 10px;"> <ngb-pagination [maxSize]="5" [(page)]="currentPage" [pageSize]="pageSize"
              [collectionSize]="totalItems" (pageChange)="pageChange($event)"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="user.role != 'globale' && user.pret == 0 " style="padding-top: 10% !important">
    <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
      background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto;
      margin-bottom:auto; max-height: 800px; background-image: url('assets/droit.png')">
    </div>
  </div>
</body>