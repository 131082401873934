import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Demandproduct } from 'src/app/modules/demandproduct';
import { DemandproductService } from 'src/app/services/demandproduct.service';
import { SharedService } from 'src/app/shared.service';


@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {

  user: any = {};
  uri = environment.urlMarket;
  closeResult = '';
  cause: string;
  demandsproduct: Demandproduct[];
  registerForm: FormGroup;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  submitted = false;
  itemsPerPage = 2; 
  searchTerm: string;
  pageSize= 10;
  currentPage = 1;
  totalItems: number;
  p: number = 1;
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  dtOptions: DataTables.Settings = {};
  receivedData: any;
  constructor(private serviceauth: AuthentificationService, private http: HttpClient, private modalService: NgbModal,
    private servicedemandproduct: DemandproductService, private notifyService: NotificationService,private sharedService: SharedService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.user = this.serviceauth.getCurrentUser();
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;
 this.loadPosts();
    this.registerForm = this.formBuilder.group({
      cause: ['', Validators.required],
    });

  }

  get f() { return this.registerForm.controls; }




  loadPosts() {
    this.servicedemandproduct.getProductHistory(this.currentPage, this.pageSize, this.searchTerm).subscribe(
      (response) => {
        console.log(response.data)
        this.demandsproduct = response.data;
        this.totalItems = response.meta.total;
      },
      (error) => {
        console.error('Error fetching posts', error);
      }
    );
  }

  
  search() {
    this.currentPage = 1;
    this.loadPosts();
  }



  pageChange(newPage: number) {
    this.currentPage = newPage;
    this.loadPosts();
  }

}
