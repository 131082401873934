import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { MenuComponent } from './components/menu/menu.component';
import { ConversionComponent } from './components/conversion/conversion.component';
import { ActualiteComponent } from './components/actualite/actualite.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './components/profile/profile.component';
import { DatePipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { TousconversionsComponent } from './components/tousconversions/tousconversions.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ListeAdminComponent } from './components/liste-admin/liste-admin.component';
import { ListeUtilisateurComponent } from './components/liste-utilisateur/liste-utilisateur.component';
import { FiltreadminPipe } from './components/liste-admin/liste-admin-filter.pipe';
import { EncrDecrService } from './services/encr-decr.service';
import { FiltreutilisateurPipe } from './components/liste-utilisateur/liste-utilisateur-filter.pipe';
import { QuestionComponent } from './components/question/question.component';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AjoutAdminComponent } from './components/ajout-admin/ajout-admin.component';
import { MobileComponent } from './components/mobile/mobile.component';
import { PubliciteComponent } from './components/publicite/publicite.component';
import { ListeMobileComponent } from './components/liste-mobile/liste-mobile.component';
import { ContactComponent } from './components/contact/contact.component';
import { NgxUiLoaderConfig, NgxUiLoaderRouterModule } from "ngx-ui-loader";
import { ThemeComponent } from './components/theme/theme.component';
import { CadeauComponent } from './components/cadeau/cadeau.component';
import { DataTablesModule } from "angular-datatables";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MyHttpInterceptor } from './components/http-interceptor';
import { ListeBlockComponent } from './components/liste-block/liste-block.component';
import { ListeSignalComponent } from './components/liste-signal/liste-signal.component';
import { CompteurComponent } from './components/compteur/compteur.component';
import { DelegateComponent } from './components/delegate/delegate.component';
import { DemandedelegateComponent } from './components/demandedelegate/demandedelegate.component';
import { ProductComponent } from './components/product/product.component';
import { DemandproductComponent } from './components/demandproduct/demandproduct.component';
import { EventComponent } from './components/event/event.component';
import { EditproductComponent } from './components/editproduct/editproduct.component';
import { EditAdminComponent } from './components/edit-admin/edit-admin.component';
import { OfferPublicityComponent } from './components/offer-publicity/offer-publicity.component';
import { UserPublicityComponent } from './components/user-publicity/user-publicity.component';
import { GiftactualiteComponent } from './components/giftactualite/giftactualite.component';
import { DemandetrendComponent } from './components/demandetrend/demandetrend.component';
import { PublicationdetailComponent } from './components/publicationdetail/publicationdetail.component';
import { LightboxModule } from 'ngx-lightbox';
import { AddpostComponent } from './components/addpost/addpost.component';
import { PostslistComponent } from './components/postslist/postslist.component';
import { StoreComponent } from './components/store/store.component';
import { EditpostComponent } from './components/editpost/editpost.component';
import { ParallaxComponent } from './parallax/parallax.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { OrderdetailComponent } from './components/orderdetail/orderdetail.component';
import { CodeparrainageComponent } from './components/codeparrainage/codeparrainage.component';
import { CodeparrainageDemandComponent } from './components/codeparrainage-demand/codeparrainage-demand.component';
import { FortuneComponent } from './components/fortune/fortune.component';
import { HistoriqueComponent } from './components/historique/historique.component';
import { ListTrendComponent } from './components/list-trend/list-trend.component';
import { UsertodeleteComponent } from './components/usertodelete/usertodelete.component';
import { PretComponent } from './components/pret/pret.component';
import { ListPretComponent } from './components/list-pret/list-pret.component';
import { CategoryComponent } from './components/category/category.component';
import { AddproductComponent } from './components/addproduct/addproduct.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { TraderComponent } from './components/trader/trader.component';
import { TraderdemandsComponent } from './components/traderdemands/traderdemands.component';
import { TraderpercentageComponent } from './components/traderpercentage/traderpercentage.component';
import { CountrytradeComponent } from './components/countrytrade/countrytrade.component';
import { CountrydelegateComponent } from './components/countrydelegate/countrydelegate.component';
import { DetailUserComponent } from './components/detail-user/detail-user.component';
import { AddDonateComponent } from './components/add-donate/add-donate.component';
import { DonateListComponent } from './components/donate-list/donate-list.component';
import { EditDonateComponent } from './components/edit-donate/edit-donate.component';
import { ExplainComponent } from './components/explain/explain.component';
import { PretHistoryComponent } from './components/pret-history/pret-history.component';
import { CodeRechargeComponent } from './components/code-recharge/code-recharge.component';
import { SpecialPublicationComponent } from './components/special-publication/special-publication.component';
import { ModuleComponent } from './components/module/module.component';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#4fb2ac',
  fgsColor: '#4fb2ac',
  pbColor: '#4fb2ac',
  "fgsSize": 80,
  fgsType: "cube-grid",
  bgsOpacity: 0.8,
  text: "...يرجى الإنتظار",

};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuComponent,
    ConversionComponent,
    ActualiteComponent,
    ProfileComponent,
    TousconversionsComponent,
    ListeAdminComponent,
    ListeUtilisateurComponent,
    FiltreadminPipe,
    FiltreutilisateurPipe,
    QuestionComponent,
    AjoutAdminComponent,
    MobileComponent,
    PubliciteComponent,
    ListeMobileComponent,
    ContactComponent,
    ThemeComponent,
    CadeauComponent,
    ListeBlockComponent,
    ListeSignalComponent,
    CompteurComponent,
    DelegateComponent,
    DemandedelegateComponent,
    ProductComponent,
    DemandproductComponent,
    EventComponent,
    EditproductComponent,
    EditAdminComponent,
    OfferPublicityComponent,
    UserPublicityComponent,
    GiftactualiteComponent,
    DemandetrendComponent,
    PublicationdetailComponent,
    AddpostComponent,
    PostslistComponent,
    StoreComponent,
    EditpostComponent,
    ParallaxComponent,
    OrderdetailComponent,
    CodeparrainageComponent,
    CodeparrainageDemandComponent,
    FortuneComponent,
    HistoriqueComponent,
    ListTrendComponent,
    UsertodeleteComponent,
    PretComponent,
    ListPretComponent,
    CategoryComponent,
    AddproductComponent,
    OrderHistoryComponent,
    TraderComponent,
    TraderdemandsComponent,
    TraderpercentageComponent,
    CountrytradeComponent,
    CountrydelegateComponent,
    DetailUserComponent,
    AddDonateComponent,
    DonateListComponent,
    EditDonateComponent,
    ExplainComponent,
    PretHistoryComponent,
    CodeRechargeComponent,
    SpecialPublicationComponent,
    ModuleComponent
    ],
  imports: [
    DataTablesModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-right'
    }),
    NgbModule,

    NgxUiLoaderModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    LightboxModule,


  ],
  providers: [DatePipe, EncrDecrService, {
    provide: HTTP_INTERCEPTORS,
    useClass: MyHttpInterceptor,
    multi: true
  }, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
