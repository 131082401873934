import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first, flatMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utilisateur } from 'src/app/modules/utilisateur';
import { NotificationService } from 'src/app/services/notification.service';
import { UtilisateurService } from 'src/app/services/utilisateur.service';
import { FiltreutilisateurPipe } from './liste-utilisateur-filter.pipe';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/services/message.service';
import { Message } from 'src/app/modules/message';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { environment } from 'src/environments/environment.prod';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/shared.service';
@Component({
  selector: 'app-liste-utilisateur',
  templateUrl: './liste-utilisateur.component.html',
  styleUrls: ['./liste-utilisateur.component.css']
})
export class ListeUtilisateurComponent implements OnInit {
  uri = environment.apiUrl;
  utilisateur: Utilisateur[]
  registerForm: FormGroup;
  registerForm1: FormGroup;
  registerForm2: FormGroup;
  registerForm3: FormGroup;
  registerForm5: FormGroup;
  registerForm6: FormGroup;
  registerForm7: FormGroup;
  registerForm8: FormGroup;
  filteredItems: Utilisateur[] = [];
  id: string;
  searchText: string;
  message: string;
  messages: string;
  remark: string;
  remark2: string;
  messageP: string;
  point: string;
  pageSize = 10;
  currentPage = 1;
  closeResult = '';
  user: any = {};
  submitted = false;
  submitted2 = false;
  submitted3 = false;
  submitted5 = false;
  submitted6 = false;
  submitted7 = false;
  submitted8 = false;
  msg: Message[];
  periode = false;
  periodeBlock: string;
  montant: string;
  montant1: string;
  diffJour: number;
  persons: Utilisateur[];
  searchTerm: string;
  countUser: string;
  progress: number;
  progressPoint: number;
  isTask: boolean = false;
  isTaskPoint: boolean = false;
  sendPoint = false;
  equipe: string;
  equipeOnline: string;
  offers: any;
  diamondAmount: string;
  pointAmount: string;
  totalItems: number;
  p: number = 1; 
  itemsPerPage: number = 10;
  public filtreutilisateur: FiltreutilisateurPipe;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  dtOptions: DataTables.Settings = {};
  dOptions: DataTables.Settings = {};
  modalData: any;
  receivedData: any;
  offers2: any;
  block_data: string;
  block_data2: string;
  userPret: boolean = true;
  searchType: string = 'codeparrainageSpecial';
  constructor(private serviceutilisateur: UtilisateurService, private http: HttpClient, private cdr: ChangeDetectorRef,
    private servicemessage: MessageService, private serviceauth: AuthentificationService, private notifyService: NotificationService,
    private formBuilder: FormBuilder, private modalService: NgbModal, private sharedService: SharedService,) { }

  ngOnInit() {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
    });
    this.loadUsers()
    this.receivedData = false;
    this.getProgressPoint()
    this.getProgress();

    setInterval(() => {
      this.checkProgressPoint();
      this.checkProgress();
    }, 30000);
    this.user = this.serviceauth.getCurrentUser();
    this.countUsers();
    this.registerForm = this.formBuilder.group({
      message: ['', Validators.required],
    });
    this.registerForm1 = this.formBuilder.group({
      messages: ['', Validators.required],
    });
    this.registerForm2 = this.formBuilder.group({
      periodeBlock: ['', Validators.required],
      block_data: ['', Validators.required],
    });
    this.registerForm3 = this.formBuilder.group({
      messageP: ['', Validators.required],
      point: ['', Validators.required]
    });
    this.registerForm5 = this.formBuilder.group({
      montant: ['', Validators.required],
    });
    this.registerForm6 = this.formBuilder.group({
      montant1: ['', Validators.required],
    });
    this.registerForm7 = this.formBuilder.group({
      remark: ['', Validators.required],
    });
    this.registerForm8 = this.formBuilder.group({
      remark2: ['', Validators.required],
    });
  }

  get f() { return this.registerForm.controls; }
  get h() { return this.registerForm5.controls; }
  get g() { return this.registerForm2.controls; }
  get k() { return this.registerForm6.controls; }
  get l() { return this.registerForm7.controls; }
  get m() { return this.registerForm8.controls; }
  diffTime(date: any, codeparrainage) {
    var date1 = new Date();
    var date2 = new Date(date);
    var Diff_temps = date2.getTime() - date1.getTime();
    var Diff_jours = Diff_temps / (1000 * 3600 * 24);
    this.diffJour = Math.round(Diff_jours) + 1;
    if (this.diffJour > 0) {
      this.serviceutilisateur.getBlock(codeparrainage)
        .subscribe(data => {
          this.block_data2 = data['block_data']
        })
    }
  }
  onSearchTermChange() {
    this.currentPage = 1;
    this.loadUsers();
  }


  search() {
    this.currentPage = 1;
    this.loadUsers();
  }
  loadUsers() {
    this.serviceutilisateur.getUsers(this.currentPage, this.pageSize, this.searchTerm, this.searchType).subscribe(
      (response) => {
        console.log(this.searchType)
        this.persons = response.users;
        this.totalItems = response.pagination.total;
        this.persons.forEach(utilisateur => {
          this.serviceutilisateur.hasRemark(utilisateur.id).subscribe((response: any) => {
             utilisateur.hasRemark = response.has_remark; // Supposons que l'API retourne `hasRemark`
          });
        });
      }
    );
  }
  pageChange(newPage: number) {
    this.currentPage = newPage;
    this.loadUsers();
  }
  hasRemark(id) {
    this.serviceutilisateur.hasRemark(id)
      .subscribe(data => {
        console.log(data)
      })
  }

  addMessage(id: string) {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('id_user', id);
    formData.append('message', this.message);
    this.servicemessage.addmessage(formData).pipe(first()).subscribe(() => {
      this.loadUsers()
      this.submitted = false;
      this.message = "";
      this.showToasterSuccess();
      this.cdr.detectChanges();
      this.modalService.dismissAll();
    })
  }

  updateAmountPoint(montant, id) {
    this.submitted5 = true;
    if (this.registerForm5.invalid) {
      return;
    }
    this.serviceutilisateur.updateAmountPoint(montant, id, this.user.email)
      .pipe(first()).subscribe((data) => {
        this.loadUsers()
        if (data['success'] == "false") {
          this.showToasterError2();
        } else {
          this.showToasterSuccess3();
        }
        this.modalService.dismissAll();
        this.submitted5 = false;
        this.diamondAmount = "";
        this.pointAmount = "";
      });
  }
  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }
  updateAmountDiamond(montant, id) {
    this.submitted6 = true;
    if (this.registerForm6.invalid) {
      return;
    }
    this.serviceutilisateur.updateAmountDiamond(montant, id, this.user.email)
      .pipe(first()).subscribe((data) => {
        this.loadUsers()
        if (data['success'] == "false") {
          this.showToasterError2();
        } else {
          this.showToasterSuccess4();
        }

        this.modalService.dismissAll();
        this.submitted6 = false;
        this.diamondAmount = "";
        this.pointAmount = "";
      });

  }


  addRemark(remark, id) {
    this.submitted7 = true;
    if (this.registerForm7.invalid) {
      return;
    }
    this.serviceutilisateur.addRemark(remark, id)
      .pipe(first()).subscribe((data) => {
        this.loadUsers()
        this.modalService.dismissAll();
        this.submitted7 = false;
        this.notifyService.showSuccess("تم إضافة ملاحظة")
      });

  }

  updateRemark(remark, id) {
    this.submitted8 = true;
    if (this.registerForm8.invalid) {
      return;
    }
    this.serviceutilisateur.updateRemark(remark, id)
      .pipe(first()).subscribe((data) => {
        this.loadUsers()
        this.modalService.dismissAll();
        this.submitted8 = false;
        this.notifyService.showSuccess("تم تعديل الملاحظة  ")
      });

  }

  deleteRemark(id) {
    this.serviceutilisateur.deleteRemark(id)
      .pipe(first()).subscribe((data) => {
        this.loadUsers()
        this.modalService.dismissAll();
        this.notifyService.showSuccess("تم حذف الملاحظة  ")
      });

  }

  

  addMessageTotal() {
    this.submitted = true;
    if (this.registerForm1.invalid) {
      return;
    }
    this.servicemessage.addmessageTotal(this.messages)
      .pipe(first()).subscribe((data) => {
        this.getProgressPoint()
        this.getProgress()
        this.loadUsers()
        this.messages = "";
        this.modalService.dismissAll();
        this.submitted = false;
        if (data['success'] == "false") {
          this.showToasterErrors();
        } else {
          this.showToasterSuccess();
        }

      });

  }

  addPointTotal() {
    this.submitted3 = true;
    if (this.registerForm3.invalid) {
      return;
    }
    this.servicemessage.addPointTotal(this.messageP, this.point)
      .pipe(first()).subscribe((data) => {
        this.loadUsers()
        this.getProgressPoint()
        this.getProgress()
        this.messageP = "";
        this.point = "";
        this.modalService.dismissAll();
        this.submitted = false;
        if (data['success'] == "false") {
          this.showToasterErrors();
        } else {
          this.showToasterSuccess2();
        }
      })
  }

 


  getMsgByid(id) {
    this.servicemessage.getMsgbyid(id)
      .subscribe(data => {
        this.msg = data;
      })
  }
  countUsers() {
    this.serviceutilisateur.countUsers()
      .subscribe(data => {

        this.countUser = data['countUsers'];
      })
  }

  checkUserPret(id: string) {
    this.serviceutilisateur.checkUserPret(id)
      .subscribe(data => {
        if (data['success'] == "true") {
          this.userPret = true;
        } else {
          this.userPret = false;
        }
      })
  }


  addUserPret(id: string) {
    this.serviceutilisateur.addUserPret(id)
      .subscribe(data => {
        this.loadUsers()
        this.modalService.dismissAll();
        this.showToasterSuccess5();
        this.userPret = true;
      })
  }



  deleteUserPret(id: string) {
    this.serviceutilisateur.deleteUserPret(id)
      .subscribe(data => {
        this.loadUsers()
        this.modalService.dismissAll();
        this.showToasterSuccess6();
        this.userPret = true;
      })
  }

  getProgressPoint() {
    this.servicemessage.getProgressPoint()
      .subscribe(data => {
        this.isTaskPoint = data['isTask'];
        this.progressPoint = data['progress'];

      })
  }

  checkProgressPoint() {
    if (this.isTaskPoint == true) {
      this.servicemessage.getProgressPoint().subscribe(data => {
        this.isTaskPoint = data['isTask'];
        this.progressPoint = data['progress'];
      })
    }
  }

  getProgress() {
    this.servicemessage.getProgress()
      .subscribe(data => {
        this.isTask = data['isTask'];
        this.progress = data['progress'];
      })
  }

  checkProgress() {
    if (this.isTask == true) {
      this.servicemessage.getProgress().subscribe(data => {
        this.isTask = data['isTask'];
        this.progress = data['progress'];
      })
    }
  }


  getEquipeUser(id) {
    this.serviceutilisateur.getEquipeUser(id)
      .subscribe(data => {
        this.equipe = data['equipe'];
        this.equipeOnline = data['equipeOnline'];
      })
  }

  getOfferUser(id) {
    this.serviceutilisateur.getOfferUser(id)
      .subscribe(data => {
        this.offers = data;
      })
  }
  getOfferUser2(id) {
    this.serviceutilisateur.getOfferUser2(id)
      .subscribe(data => {
        this.offers2 = data;
      })
  }

  blockUser(id: string) {
    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      return;
    }
    this.serviceutilisateur.blockUser(id, this.periodeBlock, this.block_data).pipe(first()).subscribe(() => {
      this.loadUsers()
      this.showToasterError1();
      this.modalService.dismissAll();
      this.submitted2 = false;
      this.periodeBlock = "";
      this.block_data = "";
    })
  }

  deblockUser(id: string) {
    this.serviceutilisateur.deblockUser(id)
      .pipe(first()).subscribe(() => {
        this.loadUsers()
        this.showToasterSuccess1();
        this.modalService.dismissAll();
        this.periodeBlock = "";
      })
  }

  showToasterSuccess1() {
    this.notifyService.showSuccess(" تم إلغاء الحظر بنجاح")
  }

  showToasterSuccess() {
    this.notifyService.showSuccess(" تم إرسال رسالة بنجاح")
  }
  showToasterSuccess2() {
    this.notifyService.showSuccess(" تم إرسال الهدايا بنجاح")
  }
  showToasterSuccess3() {
    this.notifyService.showSuccess(" تم تعديل النقاط بنجاح")
  }

  showToasterSuccess4() {
    this.notifyService.showSuccess(" تم تعديل المجوهرات بنجاح")
  }

  showToasterSuccess5() {
    this.notifyService.showSuccess(" تم منع نقل العداد  ")
  }


  showToasterSuccess6() {
    this.notifyService.showSuccess(" تم  فتح نقل العداد ")
  }


  showToasterErrors() {
    this.notifyService.showError(" يرجى إنتظار إنتهاء الإرسال ")
  }

  showToasterError2() {
    this.notifyService.showError("لا يجب أن يكون الرصيد تحت الصفر")
  }

  reset() {
    this.filteredItems = this.utilisateur;
    this.searchText = "";
  }

  delete(id: string, codeparrainage: string) {
    this.serviceutilisateur.deleteusers(id, codeparrainage).pipe(first()).subscribe(() => {
      this.modalService.dismissAll();
      this.loadUsers()
      this.showToasterError();
    })
  }

  showToasterError() {
    this.notifyService.showSuccess("تم محو المستخدم")
  }

  showToasterError1() {
    this.notifyService.showSuccess("تم  حظر المستخدم")
  }

  openSm(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    this.periode = false;
    this.periodeBlock = "";
    this.message = "";
    this.messages = "";
    this.submitted = false;
    this.submitted2 = false;
    this.diamondAmount = "";
    this.pointAmount = "";
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openSm2(content) {
    this.modalService.open(content, { size: 'lg', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openSm3(content) {
    this.sendPoint = false;
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  openSm4(content) {
    this.sendPoint = false;
    this.offers = null;
    this.modalService.open(content, { size: 'lg', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  showBlock() {
    this.periode = true;
  }
}
