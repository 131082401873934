import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { Recharge } from '../modules/recharge';

@Injectable({
  providedIn: 'root'
})
export class DonateService {

 uri = environment.urlDonate;
 uriS= environment.urlSupport;
   tok = localStorage.getItem('token');
   token = JSON.parse(this.tok);
   headers = new HttpHeaders({
     'Authorization': `Bearer ${this.token}`,
     'Accept': 'application/json'
   });
   constructor(private http: HttpClient) { }

     addDonate(data: FormData) {
      return this.http.post(`${this.uri}HDBHKJLSQZHDUFCDBSZDX`, data, { headers: this.headers });
     }

       getDonates(page: number , pageSize: number): Observable<any> {
         const params = new HttpParams()
           .set('page', page.toString())
           .set('pageSize', pageSize.toString());
         return this.http.get(`${this.uri}HDBHKJLSQZHDUFCDBSZDX?page=${page}`,{  headers: this.headers , params });
       }

       deleteDonate(id: string) {
        return this.http.delete(`${this.uri}HDBHKJLSQZHDUFCDBSZDX/` + id, { headers: this.headers });
      }


      updateDonate(id: number, data: FormData) {
        return this.http.post(`${this.uri}FHBDCXNLWKSKLXNECSN/${id}`, data, { headers: this.headers });
      }
      
      getDonateById(id: number) {
        return this.http.get(`${this.uri}HDBHKJLSQZHDUFCDBSZDX/${id}`);
      }



      addSupport(data: FormData) {
        return this.http.post(`${this.uriS}JHDCBXSJNSCKNKDJNCEUC`, data, { headers: this.headers });
       }
  
         getSupports(page: number , pageSize: number): Observable<any> {
           const params = new HttpParams()
             .set('page', page.toString())
             .set('pageSize', pageSize.toString());
           return this.http.get(`${this.uriS}JHDCBXSJNSCKNKDJNCEUC?page=${page}`,{  headers: this.headers , params });
         }
  
         deleteSupport(id: string) {
          return this.http.delete(`${this.uriS}JHDCBXSJNSCKNKDJNCEUC/` + id, { headers: this.headers });
        }
  
  
        updateSupport(id: number, question, answer) {
          const obj={
            question:question,
            answer:answer
          }
          return this.http.put(`${this.uriS}JHDCBXSJNSCKNKDJNCEUC/${id}`, obj, { headers: this.headers });
        }
        


        addPublication(data: FormData) {
          return this.http.post(`${this.uriS}FJVDCNJSDNJUHRUFDHSLD`, data, { headers: this.headers });
         }




           addModule(name:string) {
             const obj={
               name : name
             }
             return this
               .http
               .post(`${this.uriS}XQSXDSFGTHYJJUIKOLFDE`, obj, { headers: this.headers });
           }
         
           updateModule(id:string, name:string) {
             const obj={
               name : name
             }
             return this
               .http
               .put(`${this.uriS}XQSXDSFGTHYJJUIKOLFDE/` + id, obj, { headers: this.headers });
           }
         
           getModules() {
             const url_get = this.uriS + 'XQSXDSFGTHYJJUIKOLFDE';
             return this.http.get<Recharge>(url_get, { headers: this.headers });
           }
         
           deleteModule(id: string) {
             return this.http.delete(`${this.uriS}XQSXDSFGTHYJJUIKOLFDE/` + id, { headers: this.headers });
           }
}
