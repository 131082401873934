<app-menu></app-menu>

<body dir="rtl" [ngStyle]="{'width': receivedData ? '82%' : 'auto'}" style="padding: 62px;">
  <div class="container" *ngIf="user.role == 'globale' || user.donate == 1" style="    margin-right: 22px;">
    <div class="d-flex" style="justify-content: space-between;">
      <h2>تعديل حالة تبرع </h2>
    </div>
    <br>
    <div class="container mt-4">
      <form [formGroup]="editForm" (ngSubmit)="onSubmit()">

        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <div class="form-group">
                <textarea id="description" maxlength="400" style="height: 200px;" class="form-control"
                  [(ngModel)]="donate.description" formControlName="description" rows="3"></textarea>
              </div>
            </div>

          </div>
          <div class="col-md-8">
            <div class="card">
              <div class="form-group mt-4">
                <div class="row">
                  <div class="col-md-4" *ngFor="let image of existingImages; let i = index">
                    <div class="image-container">
                      <i class="material-icons delete-icon" (click)="removeExistingImage(i)">delete</i>
                      <img [src]="image.image" class="img-thumbnail"  style="height: 150px;"/>
                    </div>
                  </div>
                </div>
              </div>
              

              <div class="form-group mt-4">
                <label>وثائق جديدة :</label>
                <br>
                <div class="row">
                  <div class="col-md-4" *ngFor="let image of newImages; let i = index">
                    <div class="image-container">
                    <i class="material-icons delete-icon" (click)="removeNewImage(i)">delete</i>
                    <img [src]="newImagesName[i]" class="img-thumbnail" style="height: 150px;" />
                  </div>
                  </div>
                  <div *ngIf="getTotalImagesCount() < maxImages">
                    <label for="imageE" class="form-control labelImage">حمل صورة</label>
                    <input type="file" id="imageE" accept="image/*" style="display: none;"
                      (change)="addNewImage($event)" />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div style="text-align: center;">
          <button type="submit" class="btn btn-primary mt-4" [disabled]="editForm.invalid">تعديل</button>
        </div>


   
      </form>
    </div>
  </div>
  <div class="container" *ngIf="user.role != 'globale' && user.donate == 0 " style="padding-top: 10% !important">
    <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
          background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto;
          margin-bottom:auto; max-height: 800px; background-image: url('assets/droit.png')">
    </div>
  </div>
</body>