<app-menu></app-menu>

<body dir="rtl" [ngStyle]="{'width': receivedData ? '82%' : 'auto'}" style="padding: 62px;">
    <div class="container " *ngIf="user.role == 'globale' || user.explains == 1" >
        <div class="d-flex" style="justify-content: space-between;">
            <h2>الشروحات </h2>
            <button routerLink="/module" class=" lab"> قائمة المحاور</button> 
        </div>

        <br>


            <div class="row">
                <div class="col-md-4">
                    <form (ngSubmit)="onSubmit()" [formGroup]="photoForm">
                        <div class="card">

                            <h5> المحور</h5>
                            <br />
                                <select  [(ngModel)]="selectedModule" name="module"
                                    class="inp form-control" formControlName="module" required>
                                    <option value="undefined" disabled>اختر المحور</option>
                                    <option *ngFor="let module of modules" [value]="module.id">{{ module.name }}
                                    </option>
                                </select>
                      


                                <br />

                            <h5> السؤال</h5>
                            <br />
                            <textarea maxlength="400" style="max-height:130px;height:130px;" id="question" cols="5"
                                formControlName="question"></textarea>
                            <br>
                            <h5> الجواب</h5>
                            <br>
                            <textarea maxlength="400" style="max-height:130px;height:130px;" id="answer" cols="5" formControlName="answer"></textarea>
                            <br>
                            <div style="text-align: center;">
                                <button type="submit" class="button btn" [disabled]="photoForm.invalid|| isSubmitting">إضافة</button>
                            </div>

                        </div>
                    </form>
                </div>
                <div class="col-md-8">
                    <div class="card">
                        <h5> قائمة الشروحات</h5>
                        <div *ngFor="let support of supports" class="row cardList">
                            <div >
                     
                                <li>{{ getModuleName(support.module_id) }}</li>
                          
                             </div>
                            <div class="col-md-5 ">
                                <div class="colQuestion">
                                    {{support.question}}
                                </div>
                               
                            </div>
                            <div class="col-md-6 ">
                                <div class="colAnswer">
                                    {{support.answer}}
                                </div>
                            </div>
                           
                           
                            <div class="col-md-1 d-flex  align-items-center justify-content-center">
                                <div class="d-flex ">
                                    <a (click)="openSm(content4)">
                                        <img src="../../../assets/deltepublication.png" height="25px" alt="">
                                    </a> &nbsp; &nbsp;
                                    <a (click)="openMd(content)">
                                        <img src="../../../assets/editpublication.png" height="25px"  alt="">
                                    </a>
                                </div>
                            </div>
                            <ng-template #content4 let-modal id="template4">
                                <div class="modal-body" dir="rtl">
                                  <button id="close2" type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                  <h4 id="h3"> هل تريد حذفه  ؟</h4>
                                  <br>
                                  <div class="d-flex justify-content-center">
                                    <button id="btnP" (click)="delete(support.id)" type="button" class="btn btn-primary">&nbsp; &nbsp;
                                      تأكيد &nbsp; &nbsp;</button>
                                  </div>
                                </div>
                              </ng-template>
                              <ng-template  #content let-modal id="template4">
                                <div class="modal-body" dir="rtl">
                                    <button id="close2" type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                    
                                    <form (ngSubmit)="onUpdate(support.id)" [formGroup]="editForm">
                                        <div class="card">
                
                                            <h5> السؤال</h5>
                                            <br />
                                            <textarea [(ngModel)]="support.question" maxlength="400" style="max-height:130px;height:130px;" id="question1" cols="5"
                                                formControlName="question1"></textarea>
                                            <br>
                                            <h5> الجواب</h5>
                                            <br>
                                            <textarea [(ngModel)]="support.answer" maxlength="400" style="max-height:130px;height:130px;" id="answer1" cols="5" formControlName="answer1"></textarea>
                                            <br>
                                            <div style="text-align: center;">
                                                <button type="submit" class="button btn" [disabled]="editForm.invalid">تعديل</button>
                                            </div>
                
                                        </div>
                                    </form>
                                  </div>

                                
                              </ng-template>
                        </div>
                        <div style="padding-top: 10px;"> <ngb-pagination [maxSize]="5" [(page)]="currentPage" [pageSize]="pageSize"
                            [collectionSize]="totalItems" (pageChange)="pageChange($event)"></ngb-pagination>
                        </div>
                    </div>

                   
                </div>
            </div>
            <br>





    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.explains == 0 " style="padding-top: 10% !important">
        <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
          background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto;
          margin-bottom:auto; max-height: 800px; background-image: url('assets/droit.png')">
        </div>
    </div>
</body>