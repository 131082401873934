import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { Utilisateur } from '../modules/utilisateur';
import { Offer } from '../modules/offer';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {
  uri = environment.apiUrl;
  urlum = environment.urlUsermangment;
  urlnotif = environment.urlNotification;
  actualiteUrl = environment.actualiteUrl;
  urlChatroom = environment.urlChatroom;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  constructor(private http: HttpClient) { }
  getAllusers(): Observable<Utilisateur[]> {
    const obj = {
    };

    const url_get = this.uri + 'users';
    return this.http.post<Utilisateur[]>(url_get, obj, { headers: this.headers });
  }

  getUser( id:string): Observable<any> {
    const obj={
      "HBXSHUDHKJC" : id
    }
    return this.http.post(`${this.urlum}getUserById`, obj,{  headers: this.headers  });
  }

  getUsers(page: number , pageSize: number , searchTerm:string, attribute:string): Observable<any> {
    const obj={
      searchterm : searchTerm,
      attribute:attribute
    }
    const params = new HttpParams()
      .set('page', page.toString());
    return this.http.post(`${this.urlum}getUsers?page=${page}`, obj,{  headers: this.headers , params });
  }

  hasRemark(id: string) {
    const url_get = `${this.urlnotif}has_remark/${id}`;
    return this.http.get(url_get);
  }

  addRemark(remark :string, user_id: string) {
    const obj = {
      remark: remark,
      user_id : user_id
    };
    const url_get = this.urlnotif + 'add_remark' ;
    return this.http.post(url_get , obj);
  }

  updateRemark(remark :string, user_id: string) {
    const obj = {
      remark: remark,
      user_id :  String(user_id)
    };
    console.log(remark, user_id)
    const url_get = this.urlnotif + 'update_remark' ;
    return this.http.put(url_get , obj);
  }

  deleteRemark(user_id: string) {
    const url_get = `${this.urlnotif}delete_remark/${user_id}`;
    return this.http.delete(url_get);
  }

  getSearchUser(searchTerm: string): Observable<Utilisateur[]> {
    const obj = {
      searchTerm: searchTerm
    };
    const url_get = this.uri + 'searchUser';
    return this.http.post<Utilisateur[]>(url_get, obj, { headers: this.headers });
  }

  checkUserPret(id: string) {
    const obj = {
      id: id
    };
    const url_get = this.uri + 'HTKGREFJDZXLPFKGRJTIEF4';
    return this.http.post(url_get, obj, { headers: this.headers });
  }

  addUserPret(id: string){
    const obj = {
      id: id
    };
    const url_get = this.uri + 'HJBCSKJHDKJHCIDCDNKDRKF';
    return this.http.post(url_get, obj, { headers: this.headers });
  }

  deleteUserPret(id: string) {
    const obj = {
      id: id
    };
    const url_get = this.uri + 'JOYHTGRFEJSXNIEFRFCRGYH';
    return this.http.post(url_get, obj, { headers: this.headers });
  }


  updateAmountPoint(montant, id, admin) {
    const obj = {
      montant: montant,
      id: id,
      admin : admin
    }
    return this.http.post(`${this.uri}updateAmountPoint`, obj, { headers: this.headers });
  }


  updateEmail(email, id, admin) {
    const obj = {
      email: email,
      id: id,
      admin : admin
    }
    return this.http.post(`${this.urlum}CDBKJEFKJEFNKJGRVRFHGDSZ`, obj, { headers: this.headers });
  }

  
  updateName(name, id, admin) {
    const obj = {
      MP43RF3XSQFE: name,
      id: id,
      admin : admin
    }
    return this.http.post(`${this.urlum}GMFDNCXLKJVDEHNDCNNFEJF`, obj, { headers: this.headers });
  }

  updateImage(data: FormData) {
    return this.http.post(`${this.urlum}TBHGRKJSNCJKNVRHLFEFCHJ`, data, { headers: this.headers });
  }

  chechRoom(codeparrainage) {
    const obj = {
      EZD427T8Y7NJF: codeparrainage
    }
    return this.http.post(`${this.urlChatroom}HFBVJEBDCKJNDHRKUFHVG`, obj, { headers: this.headers });
  }

  updateNumeroTlf(numerotele, id, admin) {
    const obj = {
      numerotele: numerotele,
      id: id,
      admin : admin
    }
    return this.http.post(`${this.urlum}UJYHTGRFDVFGBHTRERFVGBY`, obj, { headers: this.headers });
  }


  updatePaswword(password, id, admin) {
    const obj = {
      password: password,
      id: id,
      admin : admin
    }
    return this.http.post(`${this.urlum}IYUJTRHGDFBGHUKLIUNFAQD`, obj, { headers: this.headers });
  }


  updateAmountDiamond(montant, id, admin) {
    const obj = {
      montant: montant,
      id: id,
      admin : admin
    }
    return this.http.post(`${this.uri}updateAmountDiamond`, obj, { headers: this.headers });
  }

  blockUser(id: string, periodeBlock: string,block_data:string) {
    const obj = {
      periodeBlock: periodeBlock,
      block_data:block_data
    };


    return this
      .http
      .put(`${this.uri}blockUser/${id}`, obj, { headers: this.headers });
  }

  deblockUser(id: string) {
    const obj = {

    };
    return this.http.put(`${this.uri}deblockUser/${id}`, obj, { headers: this.headers });
  }

  getEquipeUser(id: string) {
    const url_get = this.uri + 'equipeUser/' + id;
    return this.http.get(url_get, { headers: this.headers });
  }

  getOfferUser(id: string) {
    const url_get = this.uri + 'offerUser/' + id;
    return this.http.get(url_get, { headers: this.headers });
  }
  getOfferUser2(id: string) {
    const url_get = this.urlum + 'offerUser/' + id;
    return this.http.get(url_get, { headers: this.headers });
  }

  countUsers() {
    const url_get = this.uri + 'countUsers';
    return this.http.get(url_get, { headers: this.headers });
  }

  countUsersBlock() {
    const url_get = this.uri + 'countUsersBlock';
    return this.http.get(url_get, { headers: this.headers });
  }

  countUsersSignal() {
    const url_get = this.uri + 'countUsersSignal';
    return this.http.get(url_get, { headers: this.headers });
  }

  deleteusers(id: string , codeparrainage:string) {
    const obj={
      codeparrainage : codeparrainage
    }
    return this.http.post(`${this.uri}deleteuserdash/` + id, obj, { headers: this.headers });
  }

  canceldeleteusers(codeparrainage:string) {
    const obj={
      codeparrainage : codeparrainage
    }
    return this.http.post(`${this.uri}cancelUserToDelete`, obj, { headers: this.headers });
  }

  deleteSignal(id: string) {
    return this.http.delete(`${this.uri}deleteSignal/` + id, { headers: this.headers });
  }

  addCounter(data: FormData) {
    this
      .http
      .post(`${this.uri}offers`, data, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'offers';
    return this.http.get<Offer[]>(url_get, { headers: this.headers });
  }

  updateCounter(id, name, price) {
    const obj = {
      name: name,
      price: price
    };
    this
      .http
      .put(`${this.uri}offers/` + id, obj, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'offers';
    return this.http.get<Offer[]>(url_get, { headers: this.headers });
  }

  deleteoffer(id: string) {
    return this.http.delete(`${this.uri}offers/` + id, { headers: this.headers });
  }

  getHistorique(page: number , pageSize: number,startDate :string , endDate : string,id: string) {
    const obj = {
      startDate : startDate,
      endDate : endDate
    }
    console.log(startDate)
    console.log(endDate)
    const params = new HttpParams()
    .set('page', page.toString())
    .set('pageSize', pageSize.toString());
    return this
      .http
      .post(`${this.actualiteUrl}TUKGHGHVFRTJNFHLIJGTIUJYN/${id}?page=${page}`, obj, { headers: this.headers });
  }


  
  getBlock(id: string){
    const obj = {
      HBHBGJHVGFCT : id
    }
    const url_get = this.urlum + 'HTGRFEDZSCDVFRGTHYGBLIK';
    return this.http.post(url_get,  obj,{ headers: this.headers });
  }
}