export const environment = {
  production: true,
  // apiUrl: 'https://apisntat.phyliatech.com/api/',
  // actualiteUrl: 'https://actualite.phyliatech.com/api/',
  // urlStorage: 'https://apisntat.phyliatech.com/storage',
  // urlMarket: 'https://market.phyliatech.com/api/',
  // urlGame: 'https://game.phyliatech.com/api/',
  // urlStore: 'https://store.phyliatech.com/api/',
  // urlChatroom: 'https://chatroom.phyliatech.com/api/',
  // urlUsermangment: 'https://usermanagement.phyliatech.com/api/',
  // urlNotification: 'https://notification.phyliatech.com/',

  apiUrl: 'https://dashboardapi.santat.net/api/',
  actualiteUrl: 'https://actualite.santat.net/api/',
  urlStorage: 'https://dashboardapi.santat.net/storage',
  urlMarket: 'https://market.santat.net/api/',
  urlGame: 'https://game.santat.net/api/',
  urlStore: 'https://store.santat.net/api/',
  urlChatroom: 'https://chatroom.santat.net/api/',
  urlUsermangment: 'https://usermanagement.santat.net/api/',
  urlNotification: 'https://notification.santat.net/',
  enableDebug: false
};