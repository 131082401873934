import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DonateService } from 'src/app/services/donate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';

import { Pipe, PipeTransform } from '@angular/core';
import { first } from 'rxjs';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { SharedService } from 'src/app/shared.service';


@Component({
  selector: 'app-edit-donate',
  templateUrl: './edit-donate.component.html',
  styleUrls: ['./edit-donate.component.css'],
})
export class EditDonateComponent implements OnInit {
  editForm: FormGroup;
  donateId: number;
  existingImages: any[] = []
  newImages: File[] = []; 
  newImagesName: any[] = []
  maxImages = 10;
  donate:any;
  user: any = {};
  description:string;
  receivedData: boolean;
  constructor(
    private serviceauth: AuthentificationService, 
    private sharedService: SharedService,
    private fb: FormBuilder,
    private donateService: DonateService,
    private route: ActivatedRoute,
    private router: Router,
    private notifyService: NotificationService
  ) {}

  ngOnInit(): void {
    this.donateId = this.route.snapshot.params['donateId']; 
    this.editForm = this.fb.group({
      description: ['', Validators.required],
    });

    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
    });

    this.user = this.serviceauth.getCurrentUser();

    this.donateService.getDonateById(this.donateId).pipe(first()).subscribe((data: any) => {
      this.donate = data
      this.existingImages = data['images']; 
    });
  }

  addNewImage(event: any): void {
    const file = event.target.files[0];
    if (file && this.getTotalImagesCount() < this.maxImages) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.newImages.push(file); // Ajoute le fichier
        this.newImagesName.push(e.target.result); // Ajoute l'aperçu de l'image
      };
      reader.readAsDataURL(file); // Convertit l'image en base64
    } else if (this.getTotalImagesCount() >= this.maxImages) {
      this.notifyService.showError('Vous ne pouvez pas ajouter plus de 10 images.');
    }
  }
  

  removeExistingImage(index: number): void {
    this.existingImages.splice(index, 1);
  }

  removeNewImage(index: number): void {
    this.newImages.splice(index, 1);
  }

  getTotalImagesCount(): number {
    return this.existingImages.length + this.newImages.length;
  }

  onSubmit(): void {
    if (this.editForm.valid) {
      const formData = new FormData();
      formData.append('description', this.editForm.get('description')?.value);
      console.log(this.editForm.get('description')?.value)
      this.existingImages.forEach((url) => {
        console.log(url.image)
        formData.append('imageUrls[]', url.image);
      });

      this.newImages.forEach((file) => {
        formData.append('images[]', file);
      });

      this.donateService.updateDonate(this.donateId, formData).pipe(first()).subscribe(() => {
        this.donateService.getDonateById(this.donateId).pipe(first()).subscribe((data: any) => {
          this.donate = data
          this.existingImages = data['images']; 
        });
        this.notifyService.showSuccess("تم تعديل حالة تبرع");
      });
    }
  }
}
