<meta name="viewport" content="width=device-width, initial-scale=1.0">

<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '83%' : 'auto'}">
  <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.pret == 1">
    <br><br>
    <div class="card">
      <div class="card-body">
        <h2>طلبات شراء القروض</h2>
        <br>
        <div class="d-flex col-md-2">
          <input type="search" (search)="search()" class="inputSearch form-control" [(ngModel)]="searchTerm"
            name="searchTerm">
          <i (click)=" search()" class="searchIcon material-icons">search</i>
        </div>
        <br>
        <div class="row" style="overflow-x:auto;direction: rtl;">
          <table class="row-border hover">
            <thead>
              <tr>
                <th style="border-bottom-right-radius: 10px; border-top-right-radius:10px">التاريخ</th>
                <th> رمز الطلب</th>
                <th>قيمة القرض</th>
                <th>رمز الإحالة</th>
                <th>الرصيد</th>
                <th>الأقساط</th>
                <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
              </tr>
            </thead>
            <tbody>
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;"
                *ngFor="let demandproduct of demandsproduct; let index = index;">
                <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{
                  demandproduct.created_at | date: 'yyyy-MM-dd H:mm' }}</td>
                <td > {{ demandproduct.orderRef }} </td>
                <td >{{ demandproduct.totalPrice }}</td>
                <td >{{ demandproduct.codeparrainage }}</td>
                <td >{{ demandproduct.montant }}</td>
                <td >{{ demandproduct.period }} </td>
                <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <span class="d-flex" *ngIf="demandproduct.state == 'inProgress'">
                    <button #refuseButton (click)="open(content)" id="submitB">
                      <i class="material-icons" style="color: rgb(206, 6, 6);">cancel</i>
                    </button> &nbsp; &nbsp;
                    <button (click)="update(demandproduct.id) ; submitButton.disabled = true" #submitButton
                      id="submitB">
                      <i class="material-icons" style="color: green;">check_circle</i>
                    </button>
                  </span>
                  <a *ngIf="demandproduct.state == 'accepted' " style="color: green; cursor: default;">
                    موافقة على الطلب
                  </a>
                  <a *ngIf="demandproduct.state == 'refused' " style="color: red; cursor: default;">
                    رفض الطلب
                  </a>
                  <a *ngIf="demandproduct.state == 'inPayement' " style="color: #30287a; cursor: default;">
                    قيد الدفع
                  </a>
                  <a *ngIf="demandproduct.state == 'done' " style="color: #30287a; cursor: default;">
                    تم الدفع
                  </a>
                </td>
                <ng-template #content let-modal>
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <div class="modal-body" dir="rtl">
                      <h4 id="h3"> سيتم رفض طلب شراء القرض </h4><br>
                      <div class="justify-content-center d-flex">
                        <button (click)="updaterefuse(demandproduct.id);submitButton2.disabled = true" #submitButton2
                          type="button" class="lab btn">رفض الطلب</button>
                      </div><br>
                      <form [formGroup]="registerForm">
                        <div class="flex" dir="ltr">
                          <textarea placeholder="السبب" pattern=".*\S+.*" maxlength="250"
                            style="max-height: 100px !important;" name="cause" formControlName="cause" id=""
                            [(ngModel)]="cause" cols="30" rows="1" class="form-control"></textarea>
                          <button [disabled]="registerForm.invalid"
                            (click)="updaterefuseC(demandproduct.id, cause); submitButton1.disabled = true"
                            #submitButton1 type="button" class="lab btn">&nbsp; <i style="color: #fff;"
                              class="material-icons glyphicon">send</i> &nbsp;</button>
                        </div>
                        <div *ngIf="submitted && f['cause'].errors" class="invalid-feedback">
                          <div class="err" *ngIf="f['cause'].errors['required']">يجب ادخال السبب </div>
                        </div><br>
                      </form>
                    </div>
                  </div>
                </ng-template>
              </tr>
            </tbody>
          </table>
          <div style="padding-top: 10px;"> <ngb-pagination [maxSize]="5" [(page)]="currentPage" [pageSize]="pageSize"
              [collectionSize]="totalItems" (pageChange)="pageChange($event)"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="user.role != 'globale' && user.pret == 0 " style="padding-top: 10% !important">
    <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
      background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto;
      margin-bottom:auto; max-height: 800px; background-image: url('assets/droit.png')">
    </div>
  </div>
</body>