<meta name="viewport" content="width=device-width, initial-scale=1.0">

<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '83%' : 'auto'}">
  <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.contact == 1">
    <br> <br>
    <div class="card">
      <div class="card-body">
        <h2>قائمة الدعم</h2>
        <br>
        <div class="d-flex col-md-2">
          <input type="search" (search)="search()" class="inputSearch form-control" [(ngModel)]="searchTerm"
            name="searchTerm">
          <i (click)=" search()" class="searchIcon material-icons">search</i>
        </div>
        <br>
        <div class="row" style="overflow-x:auto;direction: rtl;">
          <table class="row-border hover">
            <thead>
              <tr>
                <th style="border-bottom-right-radius: 10px; border-top-right-radius:10px" scope="col">#</th>
                <th scope="col">التاريخ</th>
                <th scope="col">المستخدم</th>
                <th scope="col">رمز الإحالة</th>
                <th scope="col">محضور</th>
                <th scope="col">السؤال</th>
                <th scope="col">زر الدعم</th>
                <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px" scope="col"></th>
              </tr>
            </thead>
            <tbody *ngFor="let ad of contact; let index = index;">
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;">
                <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{((currentPage *
                  10) -9)+index}}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{ ad.created_at | date:
                  'yyyy-MM-dd H:mm' }} </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <div class="d-flex">{{ ad.nomprenom }}
                    <img *ngIf="ad.badge" height="30" width="30" [src]="ad.badge" alt="">
                  </div>
                </td>
                <td style="border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{ ad.codeparrainage }}
                </td>
                <td style="border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> 
                  <i *ngIf="ad.fromBlock" class="material-icons" style="color: green;">check_circle</i>
                </td>
                <td *ngIf="ad.image==null" style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{
                  ad.question }}</td>
                <td *ngIf="ad.image!=null" style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <a  (click)="open(content1)">
                    <i class="material-icons" style="color: #30287a;">image</i>
                  </a>
                  <ng-template #content1 let-modal>
                    <div class="modal-content">
                      <div class="modal-body" id="mbody" dir="rtl">
                        <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click'); ">
                          <span aria-hidden="true">&times;</span>
                        </button> <br>
                        <br>
                        <div style="text-align: center;">
                          <img style="max-height: 400px !important" class="img-fluid" src="{{ad.image}}" alt="">
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <a *ngIf="ad.repondu == false" class="link" (click)="openSm(content); allContact(ad.id)">
                    <button class="button btn">الإجابة عن السؤال</button>
                  </a>
                  <a *ngIf="ad.repondu == true" style="color: black; cursor: default;">
                    الإجابة عن السؤال
                  </a>
                </td>
                <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <a *ngIf="ad.repondu == true" class="link" (click)="openSm2(content1)">
                    <button class="button btn">الإجابة </button>
                  </a>
                  <a *ngIf="ad.repondu == false" style="color: black; cursor: default;">
                    الإجابة
                  </a>
                </td>
              </tr>
              <ng-template #content let-modal class="testt">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="btn-close" aria-label="Close"
                      (click)="modal.dismiss('Cross click')"></button>
                  </div>
                  <div class="modal-body" dir="rtl">
                    <div class="row">
                      <div class="col-md-6">
                        <br>
                        <h4 id="h3"> الإجابة عن السؤال </h4>
                        <br>
                        <form [formGroup]="registerForm">
                          <div class="flex" dir="ltr">
                            <textarea pattern=".*\S+.*" style="max-height: 100px !important;" maxlength="150"
                              name="reponse" formControlName="reponse" id="" [(ngModel)]="reponse" cols="30" rows="1"
                              class="form-control"></textarea>
                            <button [disabled]="registerForm.invalid"
                              (click)="updatecontact(ad.id, reponse); submitButton1.disabled = true" #submitButton1
                              type="button" class="lab btn">&nbsp;<i style="color: #fff;"
                                class="material-icons">send</i> &nbsp;</button>
                          </div>
                          <div *ngIf="submitted && f['reponse'].errors" class="invalid-feedback">
                            <div class="err" *ngIf="f['reponse'].errors['required']">يجب ادخال الإجابة </div>
                          </div>
                          <br><br>
                        </form>
                      </div>
                      <div class="col-md-6 cardimg">
                        <div id="divdrop">
                          <div *ngFor="let m of contacts ">
                            <div class="div1 d-flex">
                              <img height="50px" src="assets/questionn.png" class="img" />
                              <div class="div2">
                                <div class="exp"> {{ m.question }}</div>
                              </div>
                            </div>
                            <div *ngIf="m.reponse" class="d-flex div1" style="text-align: left;" dir="ltr">
                              <img height="50px" src="assets/reponsee.png" class="img1" />
                              <div class="div2">
                                <div class="exp"> {{ m.reponse }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template #content1 let-modal class="testt">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="btn-close" aria-label="Close"
                      (click)="modal.dismiss('Cross click')"></button>
                  </div>
                  <h4 style="text-align:center"> الإجابة عن السؤال </h4>
                  <div class="modal-body" dir="rtl" style="text-align: center">
                    <img class="img-fluid" src="assets/contact.png" alt="">
                    <br>
                    <div class="row row2">
                      <img src="assets/men.png" class="avatar" alt="">
                      <p>{{ad.reponse}}</p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </tbody>
            <tr *ngIf="contact?.length == 0 ">
              <td colspan="8" class="no-data-available">لا توجد بيانات متوفرة</td>
            </tr>
          </table>
          <br>
          <div style="padding-top: 10px;"> <ngb-pagination [maxSize]="5" [(page)]="currentPage" [pageSize]="pageSize"
              [collectionSize]="totalItems" (pageChange)="pageChange($event)"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="user.role != 'globale' && user.contact == 0 " style="padding-top: 10% !important">
    <div class="container" style="width: 100%;height: 400px;max-height: 800px;background-size: contain;background-repeat: no-repeat;background-position: center center;
      display:block;margin-top:auto;margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
    </div>
  </div>
</body>