import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DelegateService {
  uri = environment.apiUrl;
  uri1 = environment.urlMarket;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  constructor(private http: HttpClient) { }

  delete(id: string) {
    return this.http.delete(`${this.uri}deleteDelegate/` + id, { headers: this.headers });
  }

  update(id: string) {
    const obj = {};
    return this.http.post(`${this.uri}acceptDelegate/${id}`, obj, { headers: this.headers });
  }

  updaterefuse(id: string, admin: string) {
    const obj = {admin : admin};
    return this.http.post(`${this.uri}refuseDelegate/${id}`, obj, { headers: this.headers });
  }

  updaterefuseC(cause: string, id: string, admin: string) {
    const obj = {
      cause: cause, admin : admin
    };
    return this.http.post(`${this.uri}refuseDelegateCause/${id}`, obj, { headers: this.headers });
  }


  updateTrader(id: string) {
    const obj = {};
    return this.http.post(`${this.uri1}IUYGTFDXS1CV54FD8CD4S/${id}`, obj, { headers: this.headers });
  }

  updaterefuseTrader(id: string, admin: string) {
    const obj = {admin : admin};
    return this.http.post(`${this.uri1}BNVCFGHFRD5X8ZD4CRFD6/${id}`, obj, { headers: this.headers });
  }

  updaterefuseCTrader(cause: string, id: string, admin: string) {
    const obj = {
      cause: cause, admin : admin
    };
    return this.http.post(`${this.uri1}POIUYTGRFEDXS55X6ZDCF/${id}`, obj, { headers: this.headers });
  }


  updateTraderP(id: string) {
    const obj = {};
    return this.http.post(`${this.uri1}FBFVRKJZRNKJNFRG2SDZD/${id}`, obj, { headers: this.headers });
  }

  updaterefuseTraderP(id: string, admin: string) {
    const obj = {admin : admin};
    return this.http.post(`${this.uri1}SCFSEDBGFJYUKUJHFDGTE/${id}`, obj, { headers: this.headers });
  }

  updaterefuseCTraderP(cause: string, id: string, admin: string) {
    const obj = {
      cause: cause, admin : admin
    };
    return this.http.post(`${this.uri1}SXKNIEJFLZKPJVGRJNFZA/${id}`, obj, { headers: this.headers });
  }

  getCountries() {
    return this.http.post(`${this.uri1}WMKXLMOZJDLJECFRGTHBYGR`, { headers: this.headers });
  }
  getCountriesUsed() {
    return this.http.post(`${this.uri1}DCBSKJEZHNDCJKSEZHFUGTF`, { headers: this.headers });
  }

  addCountry(country: string, cities: any) {
    const obj = {
      country: country,
      cities: cities,
    };
    return this.http.post(`${this.uri1}DCJNDKDJHCNKUDHRFCDRF`, obj, { headers: this.headers });
  }

  editCountry(country: string, newcountry: string) {
    const obj = {
      country: country,
      newcountry: newcountry,
    };
    return this.http.put(`${this.uri1}QWD54V8TH1V45X41SZ85QWX`, obj, { headers: this.headers });
  }

  deleteCountry(country: string) {
    const obj = {
      country: country
    };
    return this.http.post(`${this.uri1}DCNJKDECNKJDEFNHJCNBE4`, obj, { headers: this.headers });
  }

  addCity(country: string, city: any) {
    const obj = {
      country: country,
      city: city,
    };
    return this.http.post(`${this.uri1}XVJBNCIEHFDIEFSXD56Z4S`, obj, { headers: this.headers });
  }

  editCity( country: string,city: string, newcity: string) {
    const obj = {
      city:city,
      country: country,
      newcity: newcity,
    };
    return this.http.put(`${this.uri1}XC5E4F8R4G84GFDCS451QSA`, obj, { headers: this.headers });
  }

  deleteCity(country: string, city: string) {
    const obj = {
      country: country,
      city:city
    };
    return this.http.post(`${this.uri1}DXSZDEF45E84DZS54ZDEFC`, obj, { headers: this.headers });
  }


  getCountriesD() {
    return this.http.post(`${this.uri}FSBHCFJDCHNVIFLCCDFVGVFR`, { headers: this.headers });
  }
  getCountriesUsedD() {
    return this.http.post(`${this.uri}JDSCNEDCNLKNEDNFSXSJZDNR`, { headers: this.headers });
  }

  addCountryD(country: string, cities: any) {
    const obj = {
      country: country,
      cities: cities,
    };
    return this.http.post(`${this.uri}DCJNDKDJHCNKUDHRFCDRF`, obj, { headers: this.headers });
  }

  editCountryD(country: string, newcountry: string) {
    const obj = {
      country: country,
      newcountry: newcountry,
    };
    return this.http.put(`${this.uri}QWD54V8TH1V45X41SZ85QWX`, obj, { headers: this.headers });
  }

  deleteCountryD(country: string) {
    const obj = {
      country: country
    };
    return this.http.post(`${this.uri}DCNJKDECNKJDEFNHJCNBE4`, obj, { headers: this.headers });
  }

  addCityD(country: string, city: any) {
    const obj = {
      country: country,
      city: city,
    };
    return this.http.post(`${this.uri}XVJBNCIEHFDIEFSXD56Z4S`, obj, { headers: this.headers });
  }

  editCityD( country: string,city: string, newcity: string) {
    const obj = {
      city:city,
      country: country,
      newcity: newcity,
    };
    return this.http.put(`${this.uri}XC5E4F8R4G84GFDCS451QSA`, obj, { headers: this.headers });
  }

  deleteCityD(country: string, city: string) {
    const obj = {
      country: country,
      city:city
    };
    return this.http.post(`${this.uri}DXSZDEF45E84DZS54ZDEFC`, obj, { headers: this.headers });
  }


  updatedelegate(name: string, phoneNumber: string, address: string, country: string, city: string, remark: string,whatsapp:string, id: string) {
    const obj = {
      name: name,
      city: city,
      address: address,
      country: country,
      phoneNumber: phoneNumber,
      whatsapp: whatsapp,
      remark: remark
    };
    return this.http.put(`${this.uri}updateDelegate/${id}`, obj, { headers: this.headers });
  }

  getDelegates(page: number , pageSize: number , searchTerm:string): Observable<any> {
      const obj={
        searchTerm : searchTerm
      }
      const params = new HttpParams()
        .set('page', page.toString())
        .set('pageSize', pageSize.toString());
      return this.http.post(`${this.uri}indexDashboard?page=${page}`, obj,{  headers: this.headers , params });
    }
    
    getDelegatesDemandes(page: number , pageSize: number , searchTerm:string): Observable<any> {
      const obj={
        searchTerm : searchTerm
      }
      const params = new HttpParams()
        .set('page', page.toString())
        .set('pageSize', pageSize.toString());
      return this.http.post(`${this.uri}indexDemandes?page=${page}`, obj,{  headers: this.headers , params });
    }

  addRemarkDelegate(remark: string, id: string) {
    const obj = {
      remark: remark
    };
    return this.http.put(`${this.uri}addRemarkDelegate/${id}`, obj, { headers: this.headers });
  }


  updateTraderInfo(name: string,storeName:string, phoneNumber: string, address: string, country: string, city: string, remark: string, id: string) {
    const obj = {
      name: name,
      city: city,
      address: address,
      country: country,
      phoneNumber: phoneNumber,
      remark: remark,
      storeName:storeName
    };
    return this.http.put(`${this.uri1}OLIKUJYHTGRFEDCDE154F5/${id}`, obj, { headers: this.headers });
  }

  addRemarkTrader(remark: string, id: string) {
    const obj = {
      remark: remark
    };
    return this.http.put(`${this.uri1}IONBVCX45SZ4D5ZDEDCGBV/${id}`, obj, { headers: this.headers });
  }

  deleteTrader(id: string) {
    return this.http.delete(`${this.uri1}SDXDERGBHNYHBGVFE54R/` + id, { headers: this.headers });
  }

}
