<meta name="viewport" content="width=device-width, initial-scale=1.0">

<app-menu></app-menu>

<body  [ngStyle]="{'width': receivedData ? '82.5%' : ''}" >
  <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.demanddelegate == 1">
    <br><br>
    <div class="card">
      <div class="card-body">


        <h2 style="margin-right: 42px;">طلبات الوكالة</h2>
        <br>
        <!-- <div class="d-flex col-md-2">
          <input type="search"  class="inputSearch form-control" >
          <i  class="searchIcon material-icons">search</i>
        </div>--> 


        <br>
        <div class="row" style="overflow-x: auto;direction: rtl;">
          <table id="dtable" datatable [dtOptions]="dtOptions" class="table row-border hover">
            <thead>
              <tr>
                <th style="border-bottom-right-radius: 10px; border-top-right-radius:10px">#</th>
                <th>التاريخ</th>
                <th>الإسم </th>
                <th>البلد </th>
                <th> المدينة </th>

                <th>العنوان </th>
                <th> رقم الهاتف</th>
                <th>رمز الإحالة</th> 
                <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
              </tr>
            </thead>
            <tbody>
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;" *ngFor="let delegate of delegates; let index = index;">
                <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{((currentPage * 10) -9)+index}}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ delegate.created_at | date: 'yyyy-MM-dd H:mm' }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ delegate.name }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ delegate.country }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ delegate.city }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ delegate.address }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ delegate.phoneNumber }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ delegate.codeparrainageSpecial }}</td>
                <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <span class="d-flex" *ngIf="delegate.state == 'inProgress'">
                    <button #refuseButton (click)="open(content)" id="submitB">
                      <i class="material-icons" style="color: rgb(206, 6, 6);">cancel</i>
                    </button> &nbsp; &nbsp;
                    <button (click)="update(delegate.id) ; submitButton.disabled = true" #submitButton id="submitB">
                      <i class="material-icons" style="color: green;">check_circle</i>
                    </button>
                  </span>
                  <span class="d-flex" *ngIf="delegate.state == 'refused'">

                    <p style="color: rgb(206, 6, 6);">طلب مرفوض</p>

                  </span>
                </td>

                <ng-template #content let-modal>
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <div class="modal-body" dir="rtl">
                      <h4 id="h3"> سيتم رفض طلب الوكيل </h4><br>
                      <div class="justify-content-center d-flex">
                        <button (click)="updaterefuse(delegate.id);submitButton2.disabled = true" #submitButton2
                          type="button" class="lab btn">رفض الطلب</button>
                      </div><br>
                      <form [formGroup]="registerForm">
                        <div class="flex" dir="ltr">
                          <textarea placeholder="السبب" pattern=".*\S+.*" maxlength="250"
                            style="max-height: 100px !important;" name="cause" formControlName="cause" id=""
                            [(ngModel)]="cause" cols="30" rows="1" class="form-control"></textarea>
                          <button [disabled]="registerForm.invalid"
                            (click)="updaterefuseC(delegate.id, cause); submitButton1.disabled = true" #submitButton1
                            type="button" class="lab btn"> <i style="color: #fff;"
                              class="material-icons glyphicon">send</i> &nbsp;</button>
                        </div>
                        <div *ngIf="submitted && f['cause'].errors" class="invalid-feedback">
                          <div class="err" *ngIf="f['cause'].errors['required']">يجب ادخال السبب </div>
                        </div><br>
                      </form>
                    </div>
                  </div>
                </ng-template>


              </tr>

            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="user.role != 'globale' && user.demanddelegate == 0 "
    style="padding-top: 10% !important">
    <div class="container" style="width: 100%;height: 400px;max-height: 800px;background-size: contain;
    background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto;
    margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
    </div>
  </div>

</body>


