<app-menu></app-menu>

<body>
    <div class="container pt-5" *ngIf="user.role == 'globale' || user.users == 1" dir="rtl">
        <br>

        <div class="row" dir="rtl">
            <div class="col-md-4">
                <div class="card">
                    <img *ngIf="user.role != 'globale' && user.info == 0 "  class="imgProfil" src="{{userInfo.image}}" alt="">
                    <img *ngIf="user.role == 'globale' || user.info == 1" style="cursor: pointer;" (click)="openSm3(content14)"  class="imgProfil" src="{{userInfo.image}}" alt="">
                    <br>
                    
                      <h3>{{userInfo.nomprenom}}  <i *ngIf="user.role == 'globale' || user.info == 1" (click)="openSm(content13)" class="material-icons" style="color: orange;">edit</i> </h3>
                      <h3>{{userInfo.codeparrainageSpecial}} <a *ngIf="user.role == 'globale' || user.user_message == 1" class="action"
                        (click)="openSm2(content1); getMsgByid(userInfo.id)">
                        <i class="material-icons" style="color: #30287a;">mail</i>
                      </a> </h3>
                    <p> رمز مرجعي : {{ userInfo.codeDePere }} </p>
                    <p> البريد الإلكتروني : {{ userInfo.email }} <i *ngIf="user.role == 'globale' || user.info == 1" (click)="openSm(content10)" class="material-icons" style="color: orange;">edit</i> </p>
                    <p> رقم الهاتف : {{ userInfo.numerotele }} <i *ngIf="user.role == 'globale' || user.info == 1" (click)="openSm(content11)" class="material-icons" style="color: orange;">edit</i> </p>
                    <p class="d-flex">كلمة المرور : <i *ngIf="userInfo.password != null && user.role == 'globale' || user.info == 1 "  (click)="openSm(content12)" class="material-icons" style="color: orange;">edit</i></p>
                  
                    <div class="d-flex">
                        <button *ngIf="user.role == 'globale' || user.user_delete == 1" class="button action delete"
                            (click)="openSm(content)"> حذف المستخدم </button>
                        <button *ngIf="user.role == 'globale' || user.user_block == 1" class="button action block"
                            (click)="openSm3(content2); diffTime(userInfo.token,userInfo.codeparrainage)">حضر المستخدم
                        </button>

                    </div>
                    <ng-template #content1 let-modal class="testt">
                        <div class="modal-content" style="padding: 15px;">
                          <div class="modal-body" dir="rtl" style="padding: 0px !important;">
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="div1" dir="rtl">
                            <img src="assets/men.png" class="img" />
                            <div class="div2">
                              <h5> {{userInfo.nomprenom}} </h5>
                              <h5> {{userInfo.codeparrainage}} </h5>
                            </div>
                          </div>
      
                          <br>
                          <div class="modal-body" dir="rtl">
                            <div id="divdrop">
                              <div *ngFor="let m of msg ">
                                <div class="d-flex row" style="justify-content: space-between;">
                                  <p class="col-md-10">
                                    <li> {{m.message}}</li>
                                  </p>
                                  <p class="col-md-2" style="text-align:left"> {{ m.created_at | date: 'yy/M/d' }} </p>
                                </div>
                                <hr>
                              </div>
                            </div>
      
                            <br>
                            <form [formGroup]="registerForm">
      
                              <div class="flex" dir="ltr">
      
                                <textarea placeholder="أرسل رسالة ..." pattern=".*\S+.*" style=" max-height: 70px"
                                  formControlName="message" [(ngModel)]="message" name="message" cols="30" rows="1"
                                  class="form-control"></textarea>
                                <button [disabled]="registerForm.invalid"
                                  (click)="addMessage(userInfo.id); submitButton1.disabled = true" #submitButton1 type="button"
                                  class="lab btn"><i style="color: #fff;" class="material-icons">send</i>
                                  &nbsp;</button>
                              </div>
      
                              <div *ngIf="submitted && f['message'].errors" class="invalid-feedback">
                                <div class="err" *ngIf="f['message'].errors['required']">يجب ادخال الرسالة </div>
                              </div>
                              <br>
                            </form>
      
                          </div>
                        </div>
                      </ng-template>

                    <ng-template #content10 let-modal>
                        <div class="modal-body" dir="rtl">
                            <h5 id="h3"> تعديل البريد الإلكتروني  </h5>
                            <br>
                            <form [formGroup]="registerForm10">
                                <input dir="ltr" class="form-control" maxlength="100"  type="text" formControlName="email"
                                    [(ngModel)]="userInfo.email">
        
                                <div *ngIf="submitted10 && a['email'].errors" class="invalid-feedback">
                                    <div class="err" *ngIf="a['email'].errors['required']">يجب ادخال البريد الإلكتروني  </div>
                                </div>
                                <br>
                                <div class="d-flex justify-content-center">
                                    <button id="btnP" (click)="updateEmail(userInfo.email , userInfo.id)" type="button"
                                        class="btn btn-primary"> &nbsp; &nbsp; تأكيد &nbsp; &nbsp;</button>
                                </div>
                            </form>
                        </div>
                    </ng-template>

                    <ng-template #content13 let-modal>
                        <div class="modal-body" dir="rtl">
                            <h5 id="h3"> تعديل الإسم  </h5>
                            <br>
                            <form [formGroup]="registerForm13">
                                <input dir="ltr" class="form-control" maxlength="100"  type="text" formControlName="nomprenom"
                                    [(ngModel)]="userInfo.nomprenom">
        
                                <div *ngIf="submitted13 && d['nomprenom'].errors" class="invalid-feedback">
                                    <div class="err" *ngIf="d['nomprenom'].errors['required']">يجب ادخال الإسم  </div>
                                </div>
                                <br>
                                <div class="d-flex justify-content-center">
                                    <button id="btnP" (click)="updateName(userInfo.nomprenom , userInfo.id)" type="button"
                                        class="btn btn-primary"> &nbsp; &nbsp; تأكيد &nbsp; &nbsp;</button>
                                </div>
                            </form>
                        </div>
                    </ng-template>


                    <ng-template #content14 let-modal id="template">
                        <div class="modal-content">
                          <div class="modal-body" id="mbody" dir="rtl">
                            <div class="row" id="row5">
                              <div class="col-md-2">
                                <button id="close" type="button" class="close" aria-label="Close"
                                  (click)="modal.close('Cross click'); ferme()">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                            </div><br>
              
                            <img *ngIf="link == ''" src='{{userInfo.image}}' height="250px">
                            <img *ngIf="link !='' " [src]="link" height="250px"><br>
                            <div class="flex">
                              <label id="upload3" (change)="selectImage($event)" class="form-control lab"
                                for="inputGroupFile01"> <i class="fa fa-camera"></i> تحميل صورة
                                <input type="file" accept="image/*" style="display: none;" id="inputGroupFile01"> </label>
                              <button id="update" (click)="updateImage(userInfo.id)" type="button"
                                class="form-control lab ">تعديل</button>
                            </div>
                          </div>
                        </div>
                      </ng-template>



                    <ng-template #content11 let-modal>
                        <div class="modal-body" dir="rtl">
                            <h5 id="h3"> تعديل رقم الهاتف  </h5>
                            <br>
                            <form [formGroup]="registerForm11">
                                <input dir="ltr" class="form-control"  onKeyPress="if(this.value.length==13) return false;" onkeydown="javascript: return event.keyCode === 8 ||
                                event.keyCode === 46 ? true : !isNaN(Number(event.key))" type="number" formControlName="numerotele"
                                    [(ngModel)]="userInfo.numerotele">
        
                                <div *ngIf="submitted11 && b['numerotele'].errors" class="invalid-feedback">
                                    <div class="err" *ngIf="b['numerotele'].errors['required']">يجب ادخال رقم الهاتف </div>
                                </div>
                                <br>
                                <div class="d-flex justify-content-center">
                                    <button id="btnP" (click)="updateNumeroTlf(userInfo.numerotele , userInfo.id)" type="button"
                                        class="btn btn-primary"> &nbsp; &nbsp; تأكيد &nbsp; &nbsp;</button>
                                </div>
                            </form>
                        </div>
                    </ng-template>

                    <ng-template #content12 let-modal>
                        <div class="modal-body" dir="rtl">
                            <h5 id="h3"> تعديل كلمة المرور    </h5>
                            <br>
                            <form [formGroup]="registerForm12">
                                <input dir="ltr" class="form-control" maxlength="10"  type="password" formControlName="password"
                                    [(ngModel)]="password">
        
                                <div *ngIf="submitted12 && c['password'].errors" class="invalid-feedback">
                                    <div class="err" *ngIf="c['password'].errors['required']">يجب ادخال  كلمة المرور   </div>
                                </div>
                                <br>
                                <div class="d-flex justify-content-center">
                                    <button id="btnP" (click)="updatePassword(password , userInfo.id)" type="button"
                                        class="btn btn-primary"> &nbsp; &nbsp; تأكيد &nbsp; &nbsp;</button>
                                </div>
                            </form>
                        </div>
                    </ng-template>

                    <ng-template #content let-modal>
                        <div class="modal-body" dir="rtl">
                            <div class="modal-header">
                                <button type="button" class="btn-close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"></button>
                            </div>
                            <h5 id="h3"> هل تريد حذف هذا المستخدم؟</h5>
                            <br>
                            <div class="d-flex justify-content-center">
                                <button id="btnP" (click)="delete(userInfo.id , userInfo.codeparrainage)" type="button"
                                    class="btn btn-primary"> &nbsp; &nbsp;
                                    تأكيد &nbsp; &nbsp;</button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #content2 let-modal class="testt">
                        <div class="modal-content">
                          <div class="modal-body" dir="rtl">
                            <div class="modal-header">
                              <button type="button" class="btn-close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')"></button>
                            </div>
                            <h4> هل تريد حظر هذا المستخدم؟</h4>
                            <br>
                            <div class="d-flex" style="justify-content: space-between;" id="bts">
                              <button class="btn bl1" (click)="showBlock()" *ngIf="diffJour <= 0 || userInfo.token == ''">حظر
                              </button>
                              <button class="btn bl4" (click)="deblockUser(userInfo.id)" *ngIf="diffJour >=0">إلغاء الحظر
                              </button>
                              <button class="btn bl2" aria-label="Close" (click)="modal.dismiss('Cross click')">إلغاء</button>
                            </div>
                            <br>
                            <div style="font-size : 18px" *ngIf="diffJour > 0"> <strong> مدة الحظر المتبقية : {{diffJour}} يوم
                              </strong>
                              <br>
                              <strong> السبب : {{block_data2}}
                              </strong>
      
                            </div>
      
                            <div *ngIf="periode == true">
                              <form [formGroup]="registerForm2">
                                <div class="d-flex" style="justify-content: space-between;">
                                  <div style="font-size : 18px"> <strong>يرجى تحديد المدة الزمنية للحظر </strong> </div>
                                  <input max="99999" min="1" name="periodeBlock" formControlName="periodeBlock"
                                    [(ngModel)]="periodeBlock" class="inp form-control" type="number">
                                </div>
                                <div *ngIf="submitted2 && g['periodeBlock'].errors" class="invalid-feedback">
                                  <div style="text-align:left ;" class="err" *ngIf="g['periodeBlock'].errors['required']">يجب
                                    ادخال المدة الزمنية للحظر </div>
                                </div>
                                <br>
                                <div style="font-size : 18px"> <strong>السبب</strong> </div>
                                <textarea maxlength="300" formControlName="block_data" [(ngModel)]="block_data"
                                  name="block_data" type="text" class="inputPoint1 form-control" name="dszse"
                                  id=""> </textarea>
                                <div *ngIf="submitted2 && g['block_data'].errors" class="invalid-feedback">
                                  <div class="err" *ngIf="g['block_data'].errors['required']">يجب
                                    ادخال السبب </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <button (click)="blockUser(userInfo.id)" class="btn bl3">تسجيل</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                </div>

            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="d-flex">
                        <h5> العداد : </h5>&nbsp;

                        <i (click)="openSm3(contentOffer); getOfferUser(userInfo.id);getOfferUser2(userInfo.id)"
                            class="material-icons" style="color: #30287a;">av_timer</i>

                    </div>
                </div>
                <div class="card">
                    <div class="d-flex">
                        <h5> عدد الإبلاغات : &nbsp; {{userInfo.nbrSignal}}</h5>&nbsp;&nbsp;
                        <img height="25px" src="../../../assets/nbrblockdashboard.png" alt="">
                    </div>
                </div>
                <div class="card">
                    <div class="d-flex">
                        <h5> السجل : </h5>&nbsp;

                        <a target="_blank" [routerLink]="['/historique/', userInfo.id , userInfo.codeparrainageSpecial, userInfo.nomprenom]">
                           <img class="material-icons" src="../../../assets/historydashboard.png" alt="">
                        </a>  &nbsp;
                        <a (click)="openSm(content7); checkUserPret(userInfo.id)">
                            <i class="material-icons" style="color: #00ae37;">money</i>
                        </a>

                    </div>

                    <ng-template #content7 let-modal>
                        <div class="modal-body" dir="rtl">
                          <div class="modal-header">
                            <button type="button" class="btn-close" aria-label="Close"
                              (click)="modal.dismiss('Cross click')"></button>
                          </div>
                          <div *ngIf="userPret==false">
                            <h5 id="h3"> منع نقل العداد </h5>
                            <br>
                            <div class="d-flex justify-content-center">
                              <button id="btnP" (click)="addUserPret(userInfo.id)" type="button" class="btn btn-primary"> &nbsp;
                                &nbsp; تأكيد &nbsp; &nbsp;
                              </button>
                            </div>
                          </div>
      
                          <div *ngIf="userPret==true">
                            <h5 id="h3">فتح نقل العداد </h5>
                            <br>
                            <div class="d-flex justify-content-center">
                              <button id="btnP" (click)="deleteUserPret(userInfo.id)" type="button" class="btn btn-primary">
                                &nbsp; &nbsp; تأكيد &nbsp; &nbsp;
                              </button>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                </div>
                <div class="card">
                    <br>
                    <div class="card-container">
                        <div class=" card1">
                            <h5>عدد الفريق</h5>
                            <h5>{{equipe}}</h5>
                        </div>
                        <img src="assets/equipe.png" class="card-img">
                    </div>
                    <div class="card-container">
                        <div class=" card2">
                            <h5>عدد المتصلين</h5>
                            <h5>{{equipeOnline}}</h5>
                        </div>
                        <img src="assets/equipeOnline.png" class="card-img">
                    </div>
                </div>

                <ng-template #contentOffer let-modal>
                    <div class="modal-content" dir="rtl">
                        <div class="modal-header">
                            <button type="button" class="btn-close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')"></button>
                        </div>
                        <div class="modal-body">


                            <table class="table row-border hover">
                                <tbody>

                                    <th style="background-color: rgba(98, 180, 218, 0.479);text-align-last: center;">
                                        مجموع
                                        المجوهرات </th>
                                    <th
                                        style="background-color: rgba(98, 180, 218, 0.479); border: 1px;text-align-last: center;">
                                        مجموع النقاط </th>
                                    <tr>
                                        <td style="text-align-last: center;">{{offers2.diamondAmount}} </td>

                                        <td style="text-align-last: center;">{{offers2.pointAmount}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="d-flex">
                        <h5> النقاط : {{ userInfo.pointAmount }} </h5>&nbsp;&nbsp;
                        <img src="../../../assets/pointdashboard.png" height="25px" alt="">
                        <i *ngIf="user.role == 'globale' || user.user_point == 1" (click)="openSm(content5)"
                            class="material-icons" style="color: orange;">edit</i>
                    </div>
                </div>
                <div class="card">
                    <div class="d-flex">
                        <h5> المجوهرات : {{ userInfo.diamondAmount }} </h5> &nbsp;&nbsp;
                        <img src="../../../assets/diamonddashboard.png" height="25px" alt="">
                        <i *ngIf="user.role == 'globale' || user.user_diamond == 1" (click)="openSm(content6)"
                            class="material-icons" style="color: orange;">edit</i>
                    </div>
                </div>
                <div class="card">
                    <div class="d-flex">
                        <h5 *ngIf="hasRoom=='false'"> غرفة دردشة  : لا يوجد</h5>
                        <h5 *ngIf="hasRoom!='false'"> غرفة دردشة  : ID - {{ hasRoom }} </h5>
                        &nbsp;&nbsp;
                    </div>
                </div>
                <div class="card">
                   
                    <form [formGroup]="registerForm7" *ngIf="userInfo.hasRemark == 'none'">
                        <h5>الملاحظة :</h5>
                        <div dir="ltr">
                            <textarea placeholder="" pattern=".*\S+.*" style=" max-height: 90px"
                                formControlName="remark" [(ngModel)]="remark" name="remark" cols="30" rows="4"
                                class="form-control"></textarea>
                            <div *ngIf="submitted7 && l['remark'].errors" class="invalid-feedback">
                                <div class="err" *ngIf="l['remark'].errors['required']">يجب ادخال الرسالة </div>
                            </div>
                        </div>
            
                        <br>
                        <div class="d-flex justify-content-center">
                            <button [disabled]="registerForm7.invalid"
                                (click)="addRemark(remark , userInfo.id); submitButton1.disabled = true" #submitButton1
                                type="button" class="bl3 btn">&nbsp; &nbsp; إضافة &nbsp; &nbsp;
                            </button>
                        </div>
                        <br>
                    </form>
                    <form [formGroup]="registerForm8" *ngIf="userInfo.hasRemark != 'none'">
                        <h5>الملاحظة :</h5>
            
                        <div  dir="ltr">
                            <textarea placeholder="" pattern=".*\S+.*" style=" max-height: 90px"
                                formControlName="remark2" [(ngModel)]="userInfo.hasRemark" name="remark2" cols="30"
                                rows="4" class="form-control"></textarea>
                        </div>
                        <div *ngIf="submitted8 && m['remark2'].errors" class="invalid-feedback">
                            <div class="err" *ngIf="m['remark2'].errors['required']">يجب ادخال الرسالة </div>
                        </div>
                        <br>
                        <div class="d-flex justify-content-center">

                            <button [disabled]="registerForm8.invalid"
                                (click)="updateRemark(userInfo.hasRemark , userInfo.id); submitButton1.disabled = true"
                                #submitButton1 type="button" class="bl3 btn">تعديل
                            </button> &nbsp; &nbsp;

                            <button (click)="deleteRemark(userInfo.id);" class="bl1 btn">
                                حذف
                                &nbsp;</button>
                        </div>
                        <br>
                    </form>
                </div>
            </div>
            <ng-template #content5 let-modal>
                <div class="modal-body" dir="rtl">
                    <h5 id="h3"> تعديل النقاط </h5>
                    <br>
                    <form [formGroup]="registerForm5">
                        <input dir="ltr" placeholder="0" class="form-control" type="number" formControlName="montant"
                            [(ngModel)]="pointAmount">

                        <div *ngIf="submitted5 && h['montant'].errors" class="invalid-feedback">
                            <div class="err" *ngIf="h['montant'].errors['required']">يجب ادخال النقاط </div>
                        </div>
                        <br>
                        <div class="d-flex justify-content-center">
                            <button id="btnP" (click)="updateAmountPoint(pointAmount , userInfo.id)" type="button"
                                class="btn btn-primary"> &nbsp; &nbsp; تأكيد &nbsp; &nbsp;</button>
                        </div>
                    </form>
                </div>
            </ng-template>
            <ng-template #content6 let-modal>
                <div class="modal-body" dir="rtl">
                    <h5 id="h3"> تعديل المجوهرات </h5>
                    <br>
                    <form [formGroup]="registerForm6">
                        <input dir="ltr" placeholder="0" class="form-control" type="number" formControlName="montant1"
                            [(ngModel)]="diamondAmount">

                        <div *ngIf="submitted6 && k['montant1'].errors" class="invalid-feedback">
                            <div class="err" *ngIf="k['montant1'].errors['required']">يجب ادخال المجوهرات </div>
                        </div>
                        <br>
                        <div class="d-flex justify-content-center">
                            <button id="btnP" (click)="updateAmountDiamond(diamondAmount , userInfo.id)" type="button"
                                class="btn btn-primary"> &nbsp; &nbsp; تأكيد &nbsp; &nbsp;</button>
                        </div>
                    </form>

                </div>
            </ng-template>
        </div>


    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.users == 0" style="padding-top: 10% !important">
        <div class="container" style="width: 100%; height: 400px; max-height: 800px; background-size: contain;
            background-repeat: no-repeat; background-position: center center; display: block; margin-top: auto;
            margin-bottom: auto; max-height: 800px; background-image: url('assets/droit.png')">
        </div>
    </div>
</body>