import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { DelegateService } from 'src/app/services/delegate.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-countrytrade',
  templateUrl: './countrytrade.component.html',
  styleUrls: ['./countrytrade.component.css']
})
export class CountrytradeComponent implements OnInit {
  user: any = {};
  countries: any[] = [];
  selectedCountries: { [key: string]: string[] } = {};
  usedCountriesAndCities: { [key: string]: string[] } = {};
  receivedData: boolean;
  closeResult = '';
  newCountryName: string = '';
  newCities: string[] = [''];
  editCountryName: string = '';
  currentEditingCountry: string = '';
  currentEditingCity: string = '';
  editCityName: string = '';
  newCityName: string = '';
  currentEditingCountryForCity: string = '';
  constructor(private servicedelegate: DelegateService, private serviceauth: AuthentificationService, private modalService: NgbModal
    , private sharedService: SharedService , private cdr: ChangeDetectorRef , private notifyService: NotificationService) { }

  ngOnInit(): void {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;

    });
    this.user = this.serviceauth.getCurrentUser();
    this.loadCountriesAndCities()
    this.loadUsedCountriesAndCities()
  }

  loadUsedCountriesAndCities(): void {
    this.servicedelegate.getCountriesUsed().subscribe({
      next: (response) => {
        const usedCountries = response['countries'] || [];
        usedCountries.forEach(country => {
          this.usedCountriesAndCities[country.name] = country.cities || [];
        });
      }
    });
  }


  loadCountriesAndCities(): void {
    this.servicedelegate.getCountries().subscribe({
      next: (response) => {
        this.countries = response['countries'] || [];
        console.log(this.countries)
      },
      error: (err) => {
        console.error('Failed to load countries and cities', err);
      }
    });
  }

  toggleCountrySelection(countryName: string, event): void {
    this.loadCountriesAndCities();
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.selectedCountries = {};
      const country = this.countries.find((c) => c.name === countryName);
      if (country) {
        this.selectedCountries[countryName] = country.cities;
        console.log(this.selectedCountries)
      }
    } else {
      delete this.selectedCountries[countryName];
    }
  }

  get selectedCountriesKeys(): string[] {
    return Object.keys(this.selectedCountries);
  }

  isCityUsed(countryName: string, cityName: string): boolean {
    const usedCities = this.usedCountriesAndCities[countryName] || [];
    return usedCities.includes(cityName);
  }

  isCountryUsed(countryName: string): boolean {
    return Object.keys(this.usedCountriesAndCities).includes(countryName);
  }

  deleteCity(countryName: string, cityName: string): void {
    this.servicedelegate.deleteCity(countryName, cityName)
      .pipe(first())
      .subscribe(() => {
        const country = this.countries.find((c) => c.name === countryName);
        if (country) {
          country.cities = country.cities.filter((city) => city !== cityName);
          this.countries = [...this.countries]; // Nouvelle instance du tableau
        }
        this.toggleCountrySelection(countryName, { target: { checked: true } });
        this.showToasterSuccess1c()
        this.modalService.dismissAll();
      });
  }
  
  openEditCountryModal(countryName: string, content): void {
    this.currentEditingCountry = countryName;
    this.editCountryName = countryName;
    this.modalService.open(content, { size: 'md', centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  saveEditedCountry(): void {
    if (!this.editCountryName || !this.editCountryName.trim()) {
      alert('يرجى إدخال اسم الدولة.');
      return;
    }
    this.servicedelegate
      .editCountry(this.currentEditingCountry, this.editCountryName)
      .pipe(first())
      .subscribe({
        next: () => {
          this.modalService.dismissAll();
          this.loadCountriesAndCities();
          this.showToasterSuccess2()
        }
      });
  }
  

  openEditCityModal(countryName: string, cityName: string, content): void {
    this.currentEditingCountryForCity = countryName;
    this.currentEditingCity = cityName;
    this.editCityName = cityName; // Initialiser avec le nom actuel
    this.modalService.open(content, { size: 'md', centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  saveEditedCity(): void {
    if (!this.editCityName || !this.editCityName.trim()) {
      alert('يرجى إدخال اسم المدينة.');
      return;
    }
  
    this.servicedelegate
      .editCity(this.currentEditingCountryForCity, this.currentEditingCity, this.editCityName.trim())
      .pipe(first())
      .subscribe({
        next: () => {
          // Mettre à jour localement dans le tableau `countries`
          const country = this.countries.find(c => c.name === this.currentEditingCountryForCity);
          if (country) {
            const cityIndex = country.cities.findIndex(city => city === this.currentEditingCity);
            if (cityIndex > -1) {
              country.cities[cityIndex] = this.editCityName.trim();
              this.countries = [...this.countries]; // Nouvelle instance pour détecter les changements
            }
          }
          this.showToasterSuccess2c()
          this.toggleCountrySelection(this.currentEditingCountryForCity, { target: { checked: true } });
          this.modalService.dismissAll(); // Fermer la modal après la mise à jour
        },
        error: (err) => {
          console.error('Failed to edit city', err);
        }
      });
  }
  
  

  

  deleteCountry(countryName: string): void {
    this.servicedelegate.deleteCountry(countryName)
      .pipe(first()).subscribe(() => {
        this.modalService.dismissAll();
        this.loadCountriesAndCities();
        this.showToasterSuccess1()
      })
  }

  openSm2(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openSm(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    $('#dtable').DataTable().draw(false);
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  addCity(): void {
    this.newCities.push('');
  }

  addCityP(countryName: string): void {
    if (this.newCityName.trim() === '') {
      alert('يرجى إدخال اسم المدينة.');
      return;
    }
  
    this.servicedelegate
      .addCity(countryName, this.newCityName.trim())
      .pipe(first())
      .subscribe({
        next: () => {
          // Ajouter localement dans le tableau `countries`
          const country = this.countries.find(c => c.name === countryName);
          if (country) {
            country.cities.push(this.newCityName.trim());
            this.countries = [...this.countries]; // Nouvelle instance pour détecter les changements
          }
          this.showToasterSuccessc() 
          this.toggleCountrySelection(countryName, { target: { checked: true } });
          this.newCityName = ''; // Réinitialiser le champ de saisie
        },
        error: (err) => {
          console.error('Failed to add city', err);
        }
      });
  }
  

  showToasterSuccess() {
    this.notifyService.showSuccess("تم إضافة الدولة")
  }

  showToasterSuccess1() {
    this.notifyService.showSuccess("تم حذف  الدولة")
  }


  showToasterSuccess2() {
    this.notifyService.showSuccess("تم تعديل الدولة")
  }

  showToasterSuccessc() {
    this.notifyService.showSuccess("تم إضافة المدينة")
  }

  showToasterSuccess1c() {
    this.notifyService.showSuccess("تم حذف  المدينة")
  }


  showToasterSuccess2c() {
    this.notifyService.showSuccess("تم تعديل المدينة")
  }



  removeCity(index: number): void {

    if (this.newCities.length > 1) {
      this.newCities.splice(index, 1);
    }
  }

  addCountry(): void {
    if (!this.newCountryName || this.newCities.length === 0 || this.newCities.some(city => !city.trim())) {
      alert('يرجى إدخال اسم الدولة واسم مدينة واحدة على الأقل.');
      return;
    }
    this.servicedelegate.addCountry(this.newCountryName, this.newCities)
      .pipe(first()).subscribe(() => {
        this.modalService.dismissAll();
        this.newCountryName = '';
        this.newCities = [''];
        this.showToasterSuccess() 
        this.loadCountriesAndCities()
      })
  }

  trackByFn(index: number): any {
    return index;
  }
  

}
