import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { Conversion } from '../modules/conversion';

@Injectable({
  providedIn: 'root'
})
export class ConversionService {
  uri = environment.apiUrl;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  constructor(private http: HttpClient) { }
  getAllconversion(): Observable<Conversion[]> {
    const url_get = this.uri + 'conversions';
    return this.http.get<Conversion[]>(url_get, { headers: this.headers });
  }

  getAllconversionEnAttente(): Observable<Conversion[]> {
    const url_get = this.uri + 'conversionattente';
    return this.http.get<Conversion[]>(url_get, { headers: this.headers });
  }

  getConversions(page: number , pageSize: number , searchTerm:string): Observable<any> {
    const obj={
      searchTerm : searchTerm
    }
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.post(`${this.uri}conversionattente?page=${page}`, obj,{  headers: this.headers , params });
  }

  getConversionsEnAttente(page: number , pageSize: number , searchTerm:string): Observable<any> {
    const obj={
      searchTerm : searchTerm
    }
    console.log(pageSize)
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.post(`${this.uri}conversions?page=${page}`, obj,{  headers: this.headers , params });
  }


  updateconversion(accepte: string, id: string) {
    const obj = {
      accepte: accepte
    };


    return this.http.put(`${this.uri}updateaccepte/${id}`, obj, { headers: this.headers });
  }

  updaterefuse(accepte: string, id: string, admin : string) {
    const obj = {
      accepte: accepte,
      admin : admin
    };


    return this.http.put(`${this.uri}updaterefuse/${id}`, obj, { headers: this.headers });
  }

  updaterefuseC(accepte: string, cause: string, id: string, admin : string) {
    const obj = {
      accepte: accepte,
      cause: cause,
      admin : admin
    };
    return this.http.put(`${this.uri}updaterefuseC/${id}`, obj, { headers: this.headers });
  }


}
