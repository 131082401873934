import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { Recharge } from 'src/app/modules/recharge';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { DonateService } from 'src/app/services/donate.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-explain',
  templateUrl: './explain.component.html',
  styleUrls: ['./explain.component.css']
})
export class ExplainComponent implements OnInit {

 photoForm: FormGroup;
 editForm: FormGroup;
   user: any = {};
   receivedData: boolean;
   supports:any;
   currentPage = 1;
  closeResult = '';
  pageSize = 10;
    searchTerm: string;
    totalItems: number;
      modules: Recharge[] = [];
      selectedModule: string;
      isSubmitting: boolean = false;
   constructor(
    private modalService: NgbModal,
     private fb: FormBuilder,
     private serviceauth: AuthentificationService, 
     private sharedService: SharedService,
     private servicedonate: DonateService,
     private notifyService: NotificationService
   ) {}
 
   ngOnInit(): void {
     this.sharedService.data$.subscribe(data => {
       this.receivedData = data;
     });
     this.photoForm = this.fb.group({
      answer: ['', Validators.required], 
      question: ['', Validators.required], 
      module: ['', Validators.required], 
     });
     this.editForm = this.fb.group({
      answer1: ['', Validators.required], 
      question1: ['', Validators.required], 
     });
     this.user = this.serviceauth.getCurrentUser();
     this.listSupports();
     this.getModules()
   }
 
   onSubmit() {
    if (this.photoForm.valid) {
      this.isSubmitting = true;
      const formData = new FormData();
      formData.append('question', this.photoForm.get('question')?.value);
      formData.append('answer', this.photoForm.get('answer')?.value);
      formData.append('module_id', this.selectedModule);

      this.servicedonate.addSupport(formData)
        .pipe(first())
        .subscribe(() => {
          this.listSupports();
          this.showToasterSuccess();
          this.photoForm.reset();
          this.selectedModule = undefined;
          this.isSubmitting = false;
        });
    }
  }

  getModuleName(categoryId: string): string {
    const category = this.modules.find(cat => cat.id == categoryId);
    return category ? category.name : 'Unknown';
  }


  onUpdate(id:number) {
    if (this.editForm.valid) {

      this.servicedonate.updateSupport(id,this.editForm.get('question1')?.value, this.editForm.get('answer1')?.value)
        .pipe(first())
        .subscribe(() => {
          this.listSupports();
          this.showToasterSuccess1();
          this.modalService.dismissAll();
        });
    }
  }
 
  listSupports() {
    this.servicedonate.getSupports(this.currentPage, this.pageSize).subscribe(
      (response) => {
        this.supports = response.data;
        this.totalItems = response.total;
      },
      (error) => {
        console.error('Error fetching posts', error);
      }
    );
  }

  getModules() {
    this.servicedonate.getModules()
      .pipe(first()).subscribe((data:any) => {
        this.modules = data
      })
  }


  delete(id: string) {
    this.servicedonate.deleteSupport(id).pipe(first()).subscribe(() => {
      this.listSupports()
      this.showToasterError();
      this.modalService.dismissAll();
    })
  }

  showToasterError() {
    this.notifyService.showSuccess("تم حذف الشروحات  ")
  }

  pageChange(newPage: number) {
    this.currentPage = newPage;
    this.listSupports();
  }
  openSm(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openMd(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
      }
   showToasterSuccess() {
     this.notifyService.showSuccess("تم إضافة الشروحات ");
   }

   showToasterSuccess1() {
    this.notifyService.showSuccess("تم تعديل الشروحات ");
  }
 }
 