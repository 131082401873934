import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UtilisateurService } from 'src/app/services/utilisateur.service';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/services/message.service';
import { Message } from 'src/app/modules/message';

@Component({
  selector: 'app-detail-user',
  templateUrl: './detail-user.component.html',
  styleUrls: ['./detail-user.component.css']
})
export class DetailUserComponent implements OnInit {
  message: string;
    msg: Message[];
  user: any = {};
    userId: string;
  userInfo :any = {};
    historiques:any= [];
    currentPage = 1;
    pageSize = 10;
    totalItems: number;
    startDate : string;
    endDate :string;
    closeResult = '';
    diffJour: number;
    block_data: string;
    block_data2: string;
    periode = false;
    periodeBlock: string;
    registerForm: FormGroup;
      submitted = false;
      registerForm2: FormGroup;
      submitted2 = false;
      registerForm5: FormGroup;
      submitted5 = false;
      registerForm6: FormGroup;
      submitted6 = false;
      registerForm7: FormGroup;
      submitted7 = false;
      registerForm8: FormGroup;
      submitted8 = false;
      registerForm10: FormGroup;
      submitted10 = false;
      registerForm11: FormGroup;
      submitted11 = false;
      registerForm12: FormGroup;
      submitted12 = false;
      registerForm13: FormGroup;
      submitted13 = false;
      diamondAmount: string;
      pointAmount: string;
      remark: string;
      remark2: string;
      equipe: string;
      equipeOnline: string;
      offers: any;
      offers2: any;
      password :string;
      userPret: boolean = true;
      image: File;
      link:string="";
      hasRoom:string="false"
    constructor(private route: ActivatedRoute, private modalService: NgbModal,private serviceutilisateur: UtilisateurService,private serviceauth: AuthentificationService
      , private notifyService: NotificationService,private formBuilder: FormBuilder,private servicemessage: MessageService
    ) { }
  
    ngOnInit(): void {
      this.userId = this.route.snapshot.params['userId'];
      this.getUser(this.userId)
      this.getEquipeUser(this.userId)
      this.user = this.serviceauth.getCurrentUser();
      this.registerForm = this.formBuilder.group({
        message: ['', Validators.required],
      });
          this.registerForm2 = this.formBuilder.group({
            periodeBlock: ['', Validators.required],
            block_data: ['', Validators.required],
          });
          this.registerForm5 = this.formBuilder.group({
            montant: ['', Validators.required],
          });
          this.registerForm6 = this.formBuilder.group({
            montant1: ['', Validators.required],
          });
          this.registerForm7 = this.formBuilder.group({
            remark: ['', Validators.required],
          });
          this.registerForm8 = this.formBuilder.group({
            remark2: ['', Validators.required],
          });
          this.registerForm10 = this.formBuilder.group({
            email: ['', Validators.required],
          });
          this.registerForm11= this.formBuilder.group({
            numerotele: ['', Validators.required],
          });
          this.registerForm12 = this.formBuilder.group({
            password: ['', Validators.required],
          });
          this.registerForm13 = this.formBuilder.group({
            nomprenom: ['', Validators.required],
          });
    }
  
    get g() { return this.registerForm2.controls; }
    get h() { return this.registerForm5.controls; }
    get k() { return this.registerForm6.controls; }
    get l() { return this.registerForm7.controls; }
    get m() { return this.registerForm8.controls; }
    get f() { return this.registerForm.controls; }
    get a() { return this.registerForm10.controls; }
    get b() { return this.registerForm11.controls; }
    get c() { return this.registerForm12.controls; }
    get d() { return this.registerForm13.controls; }
    getUser(userId) {
      this.serviceutilisateur.getUser(userId)
      .subscribe(response => {
      
      this.userInfo=response
      this.serviceutilisateur.hasRemark(userId).subscribe((response: any) => {
        this.userInfo.hasRemark = response.has_remark;

        this.serviceutilisateur.chechRoom(this.userInfo.codeparrainage).pipe(first()).subscribe((data) => {
          if (data['success'] == "false") {
            this.hasRoom ="false";
          } else {
            this.hasRoom = data['room_id']
          }
        })

     });
      })
    }

    delete(id: string, codeparrainage: string) {
        this.serviceutilisateur.deleteusers(id, codeparrainage).pipe(first()).subscribe(() => {
          this.modalService.dismissAll();
          this.getUser(this.userId)
          this.showToasterError();
        })
      }

      showToasterError() {
        this.notifyService.showSuccess("تم محو المستخدم")
      }
    
      showToasterError1() {
        this.notifyService.showSuccess("تم  حظر المستخدم")
      }

      showToasterSuccess1() {
        this.notifyService.showSuccess(" تم إلغاء الحظر بنجاح")
      }
      showToasterError2() {
        this.notifyService.showError("لا يجب أن يكون الرصيد تحت الصفر")
      }
      showToasterSuccess3() {
        this.notifyService.showSuccess(" تم تعديل النقاط بنجاح")
      }
      showToasterSuccess4() {
        this.notifyService.showSuccess(" تم تعديل المجوهرات بنجاح")
      }
      showToasterSuccess5() {
        this.notifyService.showSuccess(" تم منع نقل العداد  ")
      }
    
    
      showToasterSuccess6() {
        this.notifyService.showSuccess(" تم  فتح نقل العداد ")
      }
    
    
      openSm(content) {
        this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }

      openSm3(content) {
        this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
 private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  diffTime(date: any, codeparrainage) {
    var date1 = new Date();
    var date2 = new Date(date);
    var Diff_temps = date2.getTime() - date1.getTime();
    var Diff_jours = Diff_temps / (1000 * 3600 * 24);
    this.diffJour = Math.round(Diff_jours) ;
    if (this.diffJour > 0) {
      this.serviceutilisateur.getBlock(codeparrainage)
        .subscribe(data => {
          this.block_data2 = data['block_data']
        })
    }
  }

  getMsgByid(id) {
    this.servicemessage.getMsgbyid(id)
      .subscribe(data => {
        this.msg = data;
      })
  }

  showBlock() {
    this.periode = true;
  }

  blockUser(id: string) {
    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      return;
    }
    this.serviceutilisateur.blockUser(id, this.periodeBlock, this.block_data).pipe(first()).subscribe(() => {
      this.getUser(this.userId)
      this.showToasterError1();
      this.modalService.dismissAll();
      this.submitted2 = false;
      this.periodeBlock = "";
      this.block_data = "";
      window.location.reload();
    })
  }
  deblockUser(id: string) {
    this.serviceutilisateur.deblockUser(id)
      .pipe(first()).subscribe(() => {
        this.getUser(this.userId)
   
        this.modalService.dismissAll();
        this.periodeBlock = "";
        window.location.reload();
        this.showToasterSuccess1();
      })
  }


  addMessage(id: string) {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('id_user', id);
    formData.append('message', this.message);
    this.servicemessage.addmessage(formData).pipe(first()).subscribe(() => {
      this.getUser(this.userId)
      this.submitted = false;
      this.message = "";
      this.showToasterSuccess();
      this.modalService.dismissAll();
    })
  }
  updateEmail(email, id) {
    this.submitted10= true;
    if (this.registerForm10.invalid) {
      return;
    }
    this.serviceutilisateur.updateEmail(email, id, this.user.email)
      .pipe(first()).subscribe((data) => {
        this.getUser(this.userId)
        if (data['success'] == "accountExist") {
          this.notifyService.showError("يوجد حساب بهذا  البريد الإلكتروني ")
        } else {
          this.notifyService.showSuccess(" تم تعديل  البريد الإلكتروني  بنجاح")
        }
        this.modalService.dismissAll();
        this.submitted10 = false;
      });
  }

  updateName(nomprenom, id) {
    this.submitted13= true;
    if (this.registerForm13.invalid) {
      return;
    }
    this.serviceutilisateur.updateName(nomprenom, id, this.user.email)
      .pipe(first()).subscribe((data) => {
        this.getUser(this.userId)
        this.notifyService.showSuccess(" تم تعديل الإسم")
        this.modalService.dismissAll();
        this.submitted13 = false;
      });
  }

  updateImage(id) {
    const formData = new FormData();
    formData.append('image', this.image);
    formData.append('id', id);

    this.serviceutilisateur.updateImage(formData)
      .pipe(first()).subscribe((data) => {
        this.getUser(this.userId)
        this.notifyService.showSuccess(" تم تعديل الصورة")
        this.modalService.dismissAll();
      });
  }

  selectImage(event: any) {
    var reader = new FileReader();
    const file = event.target.files[0];
    this.image= file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link = event.target.result;
    }
  }

  ferme() {
    this.link = "";
    this.image = null;
  }

  updateNumeroTlf(numerotele, id) {
    this.submitted11 = true;
    if (this.registerForm11.invalid) {
      return;
    }
    this.serviceutilisateur.updateNumeroTlf(numerotele, id, this.user.email)
      .pipe(first()).subscribe((data) => {
        this.getUser(this.userId)
        if (data['success'] == "numTelExiste") {
          this.notifyService.showError("يوجد حساب بهذا  الرقم  ")
        } else {
          this.notifyService.showSuccess(" تم تعديل  رقم الهاتف   بنجاح")
        }
        this.modalService.dismissAll();
        this.submitted11 = false;
      });
  }

  updatePassword(password, id) {
    this.submitted12 = true;
    if (this.registerForm12.invalid) {
      return;
    }
    this.serviceutilisateur.updatePaswword(password, id, this.user.email)
      .pipe(first()).subscribe((data) => {
        this.getUser(this.userId)
        this.notifyService.showSuccess(" تم تعديل  كلمة المرور  بنجاح")
        this.modalService.dismissAll();
        this.submitted12 = false;
        this.password = "";
      });
  }

  showToasterSuccess() {
    this.notifyService.showSuccess(" تم إرسال رسالة بنجاح")
  }


  updateAmountPoint(montant, id) {
    this.submitted5 = true;
    if (this.registerForm5.invalid) {
      return;
    }
    this.serviceutilisateur.updateAmountPoint(montant, id, this.user.email)
      .pipe(first()).subscribe((data) => {
        this.getUser(this.userId)
        if (data['success'] == "false") {
          this.showToasterError2();
        } else {
          this.showToasterSuccess3();
        }
        this.modalService.dismissAll();
        this.submitted5 = false;
        this.diamondAmount = "";
        this.pointAmount = "";
      });
  }

  updateAmountDiamond(montant, id) {
    this.submitted6 = true;
    if (this.registerForm6.invalid) {
      return;
    }
    this.serviceutilisateur.updateAmountDiamond(montant, id, this.user.email)
      .pipe(first()).subscribe((data) => {
        this.getUser(this.userId)
        if (data['success'] == "false") {
          this.showToasterError2();
        } else {
          this.showToasterSuccess4();
        }
        this.modalService.dismissAll();
        this.submitted6 = false;
        this.diamondAmount = "";
        this.pointAmount = "";
      });

  }

  addRemark(remark, id) {
    this.submitted7 = true;
    if (this.registerForm7.invalid) {
      return;
    }
    this.serviceutilisateur.addRemark(remark, id)
      .pipe(first()).subscribe((data) => {
        this.getUser(this.userId)
        this.modalService.dismissAll();
        this.submitted7 = false;
        this.notifyService.showSuccess("تم إضافة ملاحظة")
      });

  }

  updateRemark(remark, id) {
    this.submitted8 = true;
    if (this.registerForm8.invalid) {
      return;
    }
    this.serviceutilisateur.updateRemark(remark, id)
      .pipe(first()).subscribe((data) => {
        this.getUser(this.userId)
        this.modalService.dismissAll();
        this.submitted8 = false;
        this.notifyService.showSuccess("تم تعديل الملاحظة  ")
      });

  }

  deleteRemark(id) {
    this.serviceutilisateur.deleteRemark(id)
      .pipe(first()).subscribe((data) => {
        this.getUser(this.userId)
        this.modalService.dismissAll();
        this.notifyService.showSuccess("تم حذف الملاحظة  ")
      });

  }

  getEquipeUser(id) {
    this.serviceutilisateur.getEquipeUser(id)
      .subscribe(data => {
        this.equipe = data['equipe'];
        this.equipeOnline = data['equipeOnline'];
      })
  }

  
  getOfferUser(id) {
    this.serviceutilisateur.getOfferUser(id)
      .subscribe(data => {
        this.offers = data;
      })
  }
  getOfferUser2(id) {
    this.serviceutilisateur.getOfferUser2(id)
      .subscribe(data => {
        this.offers2 = data;
      })
  }
  openSm2(content) {
    this.modalService.open(content, { size: 'lg', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  
  checkUserPret(id: string) {
    this.serviceutilisateur.checkUserPret(id)
      .subscribe(data => {
        if (data['success'] == "true") {
          this.userPret = true;
        } else {
          this.userPret = false;
        }
      })
  }


  addUserPret(id: string) {
    this.serviceutilisateur.addUserPret(id)
      .subscribe(data => {
        this.getUser(this.userId)
        this.modalService.dismissAll();
        this.showToasterSuccess5();
        this.userPret = true;
      })
  }



  deleteUserPret(id: string) {
    this.serviceutilisateur.deleteUserPret(id)
      .subscribe(data => {
        this.getUser(this.userId)
        this.modalService.dismissAll();
        this.showToasterSuccess6();
        this.userPret = true;
      })
  }

  }
  