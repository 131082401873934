import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Demandproduct } from 'src/app/modules/demandproduct';
import { DemandproductService } from 'src/app/services/demandproduct.service';
import { SharedService } from 'src/app/shared.service';
import { DonateService } from 'src/app/services/donate.service';

@Component({
  selector: 'app-donate-list',
  templateUrl: './donate-list.component.html',
  styleUrls: ['./donate-list.component.css']
})
export class DonateListComponent implements OnInit {

    user: any = {};
    uri = environment.urlMarket;
    currentPage = 1;
    closeResult = '';
    cause: string;
    donates: any[];
    registerForm: FormGroup;
    tok = localStorage.getItem('token');
    token = JSON.parse(this.tok);
    submitted = false;
    pageSize = 10;
    searchTerm: string;
    totalItems: number;
    p: number = 1;
    images: any[] = []; // Liste des images de la donation sélectionnée
    currentImagePage: number = 1; // Page actuelle des images dans le popup
    imagesPerPage: number = 5; // Nombre d'images par page
    imagesPerRow: number = 3; // Nombre d'images par ligne
    totalImagePages: number = 1; 
    headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`,
      'Accept': 'application/json'
    });
    dtOptions: DataTables.Settings = {};
    receivedData: any;
    constructor(private serviceauth: AuthentificationService, private http: HttpClient, private modalService: NgbModal, private notifyService: NotificationService, private sharedService: SharedService,
      private formBuilder: FormBuilder, private servicedonate: DonateService) { }
  
    ngOnInit(): void {
      this.user = this.serviceauth.getCurrentUser();
      this.sharedService.data$.subscribe(data => {
        this.receivedData = data;
      });
      this.receivedData = false;
      this.registerForm = this.formBuilder.group({
        cause: ['', Validators.required],
      });
      this.listDonates()
    }
  
    get f() { return this.registerForm.controls; }
  
    listDonates() {
      this.servicedonate.getDonates(this.currentPage, this.pageSize).subscribe(
        (response) => {
          this.donates = response.data;
          console.log(response)
          this.totalItems = response.total;
        },
        (error) => {
          console.error('Error fetching posts', error);
        }
      );
    }
  
    openImageModal(donate: any, content) {
      this.images = donate.images || [];
      this.totalImagePages = Math.ceil(this.images.length / this.imagesPerPage); 
      this.currentImagePage = 1; 
      this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    
    getImagesForCurrentPage(): any[] {
      const startIndex = (this.currentImagePage - 1) * this.imagesPerPage;
      const endIndex = startIndex + this.imagesPerPage;
      return this.images.slice(startIndex, endIndex);
    }
    
    groupImagesByRow(images: any[]): any[][] {
      const grouped: any[][] = [];
      for (let i = 0; i < images.length; i += this.imagesPerRow) {
        grouped.push(images.slice(i, i + this.imagesPerRow));
      }
      return grouped;
    }

    search() {
      this.currentPage = 1;
      this.listDonates();
    }
  
    pageChange(newPage: number) {
      this.currentPage = newPage;
      this.listDonates();
    }
  
    showToasterError() {
      this.notifyService.showSuccess("تم حذف الحالة  ")
    }
 
  
    openSm(content) {
      this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  
    open(content) {
      this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }
  
    openSm2(content) {
      this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    delete(id: string) {
      this.servicedonate.deleteDonate(id).pipe(first()).subscribe(() => {
        this.listDonates()
        this.showToasterError();
        this.modalService.dismissAll();
      })
    }
  }