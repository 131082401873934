<app-menu></app-menu>

<body dir="rtl" [ngStyle]="{'width': receivedData ? '82%' : 'auto'}" style="padding: 62px;">
    <div class="container" *ngIf="user.role == 'globale' || user.donate == 1" style="    margin-right: 22px;">
        <div class="d-flex" style="justify-content: space-between;">
            <h2>إضافة  حالة تبرع </h2>
        </div>
        <br>
        <div class="container">
            <form (ngSubmit)="onSubmit()" [formGroup]="photoForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <h4>تعريف الحالة</h4>
                    <br />
                    <textarea maxlength="400" style="height: 200px;" id="description" cols="5" formControlName="description" ></textarea>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <h4>الوثائق اللازمة</h4>
                    <br />
                    <div *ngFor="let item of getFilesForCurrentPage(); let i = index" class="file-input-container d-flex">
                      <label for="imageDonate{{ i }}" class="form-control labelImage">
                        <span *ngIf="item.name; else defaultText">{{ item.name }}</span>
                        <ng-template #defaultText>حمل صورة</ng-template>
                        <input 
                          type="file" 
                          id="imageDonate{{ i }}" 
                          accept="image/*" 
                          (change)="onFileChange($event, i + (currentPage - 1) * itemsPerPage)" 
                          style="display: none;" 
                        />
                      </label>
                      <img 
                        class="deleteImg" 
                        src="../../../assets/deletepaper.png"  
                        (click)="removeFile(i + (currentPage - 1) * itemsPerPage)" 
                        alt="Supprimer l'image">
                    </div>
                      
                    <br />
                    <img 
                      *ngIf="files.length < 10" 
                      class="addImg" 
                      src="../../../assets/addpaper.png" 
                      (click)="addFileInput()" 
                      alt=""
                    >
                    <p *ngIf="files.length >= 10" class="text-danger">
                      تم الوصول إلى الحد الأقصى 10 صور
                    </p>
                    
                    <div class="pagination">
                      <button
                        *ngFor="let page of [].constructor(totalPages); let pageIndex = index"
                        [class.active]="pageIndex + 1 === currentPage"
                        (click)="goToPage(pageIndex + 1)"
                      >
                        {{ pageIndex + 1 }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <div style="text-align: center;">
                <button type="submit" class="button btn" [disabled]="photoForm.invalid|| isSubmitting">إضافة</button>
              </div>
            </form>
          </div>
          
          
    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.donate == 0 " style="padding-top: 10% !important">
        <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
          background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto;
          margin-bottom:auto; max-height: 800px; background-image: url('assets/droit.png')">
        </div>
    </div>
</body>