import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { DemanderechargeService } from 'src/app/services/demanderecharge.service';
import { DonateService } from 'src/app/services/donate.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-code-recharge',
  templateUrl: './code-recharge.component.html',
  styleUrls: ['./code-recharge.component.css']
})
export class CodeRechargeComponent implements OnInit {

 
  photoForm: FormGroup;
  editForm: FormGroup;
    user: any = {};
    receivedData: boolean;
    supports:any;
    currentPage = 1;
   closeResult = '';
   pageSize = 10;
     searchTerm: string;
     totalItems: number;
     id:string;
     img:string;
     isSubmitting = false;
    constructor(
     private modalService: NgbModal,private route: ActivatedRoute,
      private servicedemanderecharge: DemanderechargeService,
      private fb: FormBuilder,
      private serviceauth: AuthentificationService, 
      private sharedService: SharedService,
      private servicedonate: DonateService,
      private notifyService: NotificationService
    ) {}
  
    ngOnInit(): void {
      this.id = this.route.snapshot.params['id'];
      this.img = this.route.snapshot.params['img'];
      this.sharedService.data$.subscribe(data => {
        this.receivedData = data;
      });
      this.photoForm = this.fb.group({
       code: ['', Validators.required], 
      });
      this.editForm = this.fb.group({
       code1: ['', Validators.required], 
      });
      this.user = this.serviceauth.getCurrentUser();
      this.listSupports();
    }
  
    onSubmit() {
      if (this.photoForm.valid) {
        this.isSubmitting = true; 
        this.servicedemanderecharge.addCode(this.id, this.photoForm.get('code')?.value)
          .pipe(first())
          .subscribe({
            next: () => {
              this.listSupports();
              this.showToasterSuccess();
              this.photoForm.reset({ code: '' }, { onlySelf: true });
            },
            error: () => {
              this.showToasterError();
            },
            complete: () => {
              this.isSubmitting = false;
            }
          });
      }
    }
 
   onUpdate(id:string) {
     if (this.editForm.valid) {
 
       this.servicedemanderecharge.updateCode(id, this.editForm.get('code1')?.value)
         .pipe(first())
         .subscribe(() => {
           this.listSupports();
           this.showToasterSuccess1();
           this.modalService.dismissAll();
         });
     }
   }
  
   listSupports() {
     this.servicedemanderecharge.getCodes(this.currentPage, this.pageSize, this.id).subscribe(
       (response) => {
         this.supports = response.data;
         this.totalItems = response.total;
         console.log(response)
       },
       (error) => {
         console.error('Error fetching posts', error);
       }
     );
   }
 
   delete(id: string) {
     this.servicedemanderecharge.deleteCode(id).pipe(first()).subscribe(() => {
       this.listSupports()
       this.showToasterError();
       this.modalService.dismissAll();
     })
   }
 
   showToasterError() {
     this.notifyService.showSuccess("تم حذف الرمز  ")
   }
 
   pageChange(newPage: number) {
     this.currentPage = newPage;
     this.listSupports();
   }
   openSm(content) {
     this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
       this.closeResult = `Closed with: ${result}`;
     }, (reason) => {
       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
     });
   }
   openMd(content) {
     this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
       this.closeResult = `Closed with: ${result}`;
     }, (reason) => {
       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
     });
   }
   private getDismissReason(reason: any): string {
         if (reason === ModalDismissReasons.ESC) {
           return 'by pressing ESC';
         } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
           return 'by clicking on a backdrop';
         } else {
           return `with: ${reason}`;
         }
       }
    showToasterSuccess() {
      this.notifyService.showSuccess("تم إضافة الرمز ");
    }
 
    showToasterSuccess1() {
     this.notifyService.showSuccess("تم تعديل الرمز ");
   }
  }
  