<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '82%' : 'auto'}">
  <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.mobile == 1">
    <br> <br>

    <div class="card">
      <div class="card-body" style="margin-right: 33px;">


        <h2 style="text-align: right; margin-right: 9px;"> قائمة طلبات الشحن</h2>
        <br>
        <div class="d-flex col-md-2">
          <input type="search" (search)="search()" class="inputSearch form-control" [(ngModel)]="searchTerm"
            name="searchTerm">
          <i (click)=" search()" class="searchIcon material-icons">search</i>
        </div>
        <br>

     
        <div class="row" style="overflow-x: auto;direction: rtl;margin-top: 14px;">
          <div class="dataTables_wrapper no-footer"  id="dtable_wrapper" style=" margin-top: 20px;">
          <table class="row-border hover">
            <thead>
              <tr>
                <th style="
                text-align: right;border-bottom-right-radius: 10px; border-top-right-radius:10px" >#</th>
                <th style="text-align: right;">التاريخ</th>
                <th style="text-align: right;">المستخدم</th>
                <th style="text-align: right;">رمز الإحالة</th>
                <th style="text-align: right;">العداد</th>
                <th style="text-align: right;"> الرصيد</th>
                <th style="text-align:right"> بطاقة الشحن </th>
                <th style="text-align:left;border-bottom-left-radius: 10px; border-top-left-radius:10px;text-align:center">الإجابة على الطلب</th>
              </tr>
            </thead>
            <tbody *ngFor="let dem of demandesRechage; let index = index;">
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;">
                <td style="border-left: none; border-top:none ;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" id="tdTlf" (click)="col = dem.id"> {{((currentPage * 10) -9)+index}}</td>
                <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" id="tdTlf" (click)="col = dem.id">{{ dem.created_at | date: 'yyyy-MM-dd H:mm' }}</td>

                <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" id="tdTlf" (click)="col = dem.id">
                  <div class="d-flex">{{  dem.name }}
                    <img *ngIf="dem.badge" height="30" width="30" [src]="dem.badge" alt="">
                   </div>
                </td>


                <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" id="tdTlf" (click)="col = dem.id">{{ dem.codeparrainage }}</td>
                <td style=" border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> <a class="action" (click)="openSm3(contentOffer); getOfferUser(dem.user_id);getOfferUser2(dem.user_id)">
                  <i class="material-icons" style="color: #30287a;">av_timer</i>
                </a>
              </td>

                <td id="tdTlf" style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" (click)="col = dem.id">{{ dem.montant }}</td>

                <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <a (click)="open(content1)">
                    <i class="material-icons" style="color: #30287a;">image</i>
                  </a>

                  <ng-template #content1 let-modal>
                    <div class="modal-content">
                      <div class="modal-body" id="mbody" dir="rtl">

                        <div class="modal-header">
                          <button type="button" class="btn-close" aria-label="Close"
                            (click)="modal.dismiss('Cross click')"></button>
                        </div>
                        <br>
                        <img  class="img-fluid" src="{{dem.image}}" alt=""style="
                        margin-right: 29%;
                        height:200px;
                        width: 200px;
                    ">
                      </div>
                    </div>

                  </ng-template>



                </td>
                <td style="text-align: center;  border-right: none; border-top:none ; border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <span class="d-flex">
                    <button *ngIf="dem.reponse == 'inProgress' " (click)="open(contentAccept)" id="submitB">
                      <i class="material-icons" style="color: green; margin-right:123px">check_circle</i>
                    </button>

                    <button *ngIf="dem.reponse == 'inProgress' " (click)="open(content)" id="submitB" class="refu">
                      <i class="material-icons" style="color: rgb(206, 6, 6);">cancel</i>
                    </button>

                  </span>

                  <a *ngIf="dem.reponse == 'accepte' " style="color: green; cursor: default;">
                    تمت عملية الشحن
                  </a>

                  <a *ngIf="dem.reponse == 'refuse' " style="color: red; cursor: default;">
                    تم رفض طلب الشحن

                  </a>
                </td>

                <ng-template #content let-modal>
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <div class="modal-body" dir="rtl">


                      <h4 id="h3"> سيتم رفض طلب الشحن </h4>
                      <br>
                      <div class="justify-content-center d-flex">
                        <button (click)="updatedemanderefuse2(dem.id); submitButton2.disabled = true" #submitButton2
                          type="button" class="lab1 btn">رفض الطلب</button>
                      </div>
                      <br>
                      <form [formGroup]="registerForm">
                        <div class="flex">
                          <button [disabled]="registerForm.invalid"
                            (click)="updatedemanderefuse(dem.id, reponse); submitButton1.disabled = true" #submitButton1
                            type="button" class="lab btn"> <i style="color: #fff;"
                              class="material-icons glyphicon">send</i> &nbsp;</button>

                          <textarea placeholder="السبب" pattern=".*\S+.*" maxlength="250"
                            style="max-height: 100px !important;" name="reponse" formControlName="reponse" id=""
                            [(ngModel)]="reponse" cols="30" rows="1" class="form-control"></textarea>

                        </div>
                        <div *ngIf="submitted && f['reponse'].errors" class="invalid-feedback">
                          <div class="err" *ngIf="f['reponse'].errors['required']">يجب ادخال السبب </div>
                        </div>
                        <br>
                      </form>

                    </div>
                  </div>
                </ng-template>

                <ng-template #contentOffer let-modal>
                  <div class="modal-content" dir="rtl">
                    <div class="modal-header">
                      <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <div class="modal-body">

                    
                      <table class="table row-border hover">
                        <tbody>
                        
                          <th style="background-color: rgba(98, 180, 218, 0.479);text-align-last: center"> مجموع المجوهرات </th>
                          <th style="background-color: rgba(98, 180, 218, 0.479); border: 1px;text-align-last: center"> مجموع النقاط </th>
                        <tr>
                          <td style="text-align-last: center;">{{offers2.diamondAmount}} </td>
                                   
                          <td style="text-align-last: center;">{{offers2.pointAmount}}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </ng-template>


                <ng-template #contentAccept let-modal>
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <div class="modal-body" dir="rtl">


                      <h4 id="h3"> تم الموافقة على طلب الشحن </h4>
                      <br>
                      <div class="justify-content-center d-flex">
                        <button (click)="updatedemande(dem.id); submitButton3.disabled = true" #submitButton3
                          type="button" class="lab1 btn">الموافقة على الطلب</button>
                      </div>
                      <br>
                      <form [formGroup]="registerForm2">
                        <div class="flex">
                          <button [disabled]="registerForm2.invalid"
                            (click)="updatedemande2(dem.id , reponseAccept); submitButton4.disabled = true"
                            #submitButton4 type="button" class="lab btn">&nbsp; <i style="color: #fff;"
                              class="material-icons glyphicon">send</i> &nbsp;</button>

                          <textarea placeholder="السبب" pattern=".*\S+.*" maxlength="250"
                            style="max-height: 100px !important;" name="reponseAccept" formControlName="reponseAccept"
                            id="" [(ngModel)]="reponseAccept" cols="30" rows="1" class="form-control"></textarea>

                        </div>
                        <div *ngIf="submitted && g['reponseAccept'].errors" class="invalid-feedback">
                          <div class="err" *ngIf="g['reponseAccept'].errors['required']">يجب ادخال السبب </div>
                        </div>
                        <br>
                      </form>

                    </div>
                  </div>
                </ng-template>

              </tr>
              <tr *ngIf="col == dem.id">
                <td style="background-color: #fff;">
                  رقم الهاتف
                </td>
                <td colspan="4" style="background-color: #fff;">
                  {{ dem.numerotlf }}
                </td>
                <td style="background-color: #fff;" id="tdTlf" (click)="col = ''">
                  <i class="material-icons" style="color: rgb(206, 6, 6);">cancel</i>
                </td>
              </tr>

            </tbody>
            <tr *ngIf="demandesRechage?.length == 0 " >
              <td colspan="6" class="no-data-available" style="background-color: transparent;">لا توجد بيانات متوفرة</td>
            </tr>
         
          </table>

          <div style="padding-top: 10px;">          <ngb-pagination [maxSize]="5" 
            [(page)]="currentPage"
            [pageSize]="pageSize"
            [collectionSize]="totalItems"
            (pageChange)="pageChange($event)"
          ></ngb-pagination>
        </div>
       
        </div>
      </div>
    </div>

  </div>
</div>

  <div class="container" *ngIf="user.role != 'globale' && user.mobile == 0 " style="padding-top: 10% !important">

    <div class="container" style="
      width: 100%;
      height: 400px;
      max-height: 800px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      display:block;
      margin-top:auto;
      margin-bottom:auto;
      max-height: 800px;

      background-image: url('assets/droit.png')">



    </div>
  </div>

</body>

