<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '83%' : 'auto'}" style="padding: 62px;">
    <div class="container" *ngIf="user.role == 'globale' || user.event== 1" dir="rtl">
     
        <h2 class="text-right" style="margin-left: auto;"> منشور خاص </h2>
        <br>
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <br />
                        <form (ngSubmit)="onSubmit()" [formGroup]="photoForm">
                           <div class="label" >
                            <label id="upload2" class="lab" for="inputGroupFile01"><i
                                class="fa fa-camera"></i>
                            تحميل
                            صورة</label>
                           </div>
                            <input style="display: none;" accept="image/*" (change)="selectImage2($event)" type="file"
                                class="custom-file-input" id="inputGroupFile01">
                                <br>
                            
                            <div class="col-img d-flex flex-row justify-content-center align-items-center">
                                <img class="img-fluid" *ngIf="link ==''" src="../../../assets/camera.png" height="200px" width="200px">
                                <img class="img-fluid" *ngIf="link !=''" [src]="link" height="200px" width="200px">
                            </div>
                            <br>
                            <div style="text-align: center;">
                                <button type="submit" class="button btn" [disabled]="photoForm.invalid">إضافة</button>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card">
                        <br>
                        <div class="" style="text-align: left;">
                            <img src="../../../assets/cameraminni.png" alt="">
                        </div>
                        <div class="col-img d-flex flex-row justify-content-center align-items-center">
                          
                            <img class="img-fluid" *ngIf="link ==''" src="../../../assets/camera.png" height="200px" width="200px">
                            <img class="img-fluid" *ngIf="link !=''" [src]="link" height="200px" width="200px">
                        </div>
                        <br>
                    </div>
                </div>
            </div>


        </div>
    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.event == 0" style="padding-top: 10% !important">
        <div class="container"
            style="width: 100%;height: 400px;max-height: 800px;background-size: contain;background-repeat: no-repeat;background-position: center center;display: block;margin-top: auto;margin-bottom: auto;max-height: 800px;background-image: url('assets/droit.png')">
        </div>
    </div>
</body>