import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Conversion } from 'src/app/modules/conversion';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { ConversionService } from 'src/app/services/conversion.service';
import { CurrencyService } from 'src/app/services/currency.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { UtilisateurService } from 'src/app/services/utilisateur.service';
import { SharedService } from 'src/app/shared.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-conversion',
  templateUrl: './conversion.component.html',
  styleUrls: ['./conversion.component.css']
})
export class ConversionComponent implements OnInit {
  uri = environment.apiUrl;
  conversion: Conversion[];
  url1 = environment.urlStorage;
  MD = new Array();
  curr: any = {};
  max: number;
  prix_room: number;
  prix_favori: number;
  pointindinars:number;
  imagePrice: number;
  col: string;
  montant1: number;
  date = new Date;
  registerForm: FormGroup;
  registerForm1: FormGroup;
  registerForm2: FormGroup;
  registerForm3: FormGroup;
  registerForm4: FormGroup;
  registerForm5: FormGroup;
  idc: string;
  searchText: string;
  user: any = {};
  closeResult = '';
  submitted = false;
  submitted1 = false;
  submitted2 = false;
  submitted3 = false;
  submitted4 = false;
  submitted5 = false;
  offers: any;
  pageSize= 10;
  currentPage = 1;
  searchTerm: string;
  totalItems: number;
  p: number = 1;
  cause: string;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  offers2: any;
  receivedData: boolean;
  montant: string;
  constructor(private serviceutilisateur: UtilisateurService, private serviceconversion: ConversionService, private servicecurrency: CurrencyService, public datepipe: DatePipe,
    private notifyService: NotificationService, private route: ActivatedRoute, private sharedService: SharedService, private serviceauth: AuthentificationService, private modalService: NgbModal,
    private formBuilder: FormBuilder, private http: HttpClient) { }

  ngOnInit() {
    window.onload = function () {
      // Déplacer la fenêtre vers le haut de la page
      window.scrollTo(0, 0);
    }
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;

    });
    this.receivedData = false;
    this.loadPosts();
    this.idc = "1";
    this.servicecurrency.getbyid(this.idc)
      .subscribe(data => {
        this.curr = data
        this.montant1 = this.curr.montantparpoint;
        this.max = this.curr.maxmontant;
        this.prix_room = this.curr.prix_room;
        this.prix_favori = this.curr.prix_favori;
        this.pointindinars = this.curr.pointindinars;
        this.imagePrice = this.curr.imagePrice;
      })
    this.user = this.serviceauth.getCurrentUser();
    this.registerForm = this.formBuilder.group({
      cause: ['', Validators.required],
    });

    this.registerForm1 = this.formBuilder.group({
      montant1: ['', Validators.required],
    });

    this.registerForm2 = this.formBuilder.group({
      max: ['', Validators.required],
    });

    this.registerForm3 = this.formBuilder.group({
      prix_room: ['', Validators.required],
    });
    this.registerForm4 = this.formBuilder.group({
      pointindinars: ['', Validators.required],
    });
    this.registerForm5 = this.formBuilder.group({
      imagePrice: ['', Validators.required],
    });
  }

  get f() { return this.registerForm.controls; }
  get a() { return this.registerForm1.controls; }
  get b() { return this.registerForm2.controls; }
  get c() { return this.registerForm3.controls; }
  get d() { return this.registerForm4.controls; }
  get e() { return this.registerForm5.controls; }
  update(id: string) {
    this.serviceconversion.updateconversion("accepte", id).pipe(first()).subscribe(() => {
      this.showToasterSuccess2();
      this.loadPosts();
    })
  }

  loadPosts() {
    this.serviceconversion.getConversions(this.currentPage, this.pageSize, this.searchTerm).subscribe(
      (response) => {
        this.conversion = response.data;
        this.totalItems = response.meta.total;
      },
      (error) => {
        console.error('Error fetching posts', error);
      }
    );
  }

  pageChange(newPage: number) {
    this.currentPage = newPage;
    this.loadPosts();
  }

  limitInputLength(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const maxLength = 9;
    if (inputElement.value.length > maxLength) {
      inputElement.value = inputElement.value.slice(0, maxLength);
      this.montant = inputElement.value;
    }
  }

  updaterefuse(id: string) {
    this.serviceconversion.updaterefuse("refuse", id, this.user.email).pipe(first()).subscribe(() => {
      this.loadPosts();
      this.showToasterError();
      this.modalService.dismissAll();
      this.cause = "";
    })
  }

  isZeroAllowed = true;

  keyPressNumbers(event) {
    const key = event.key || String.fromCharCode(event.keyCode);

    if (key === '0' && this.isZeroAllowed && event.target.selectionStart === 0) {
      event.preventDefault();
    }

    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }

  }

  search() {
    this.currentPage = 1;
    this.loadPosts();
  }
  getOfferUser2(id) {
    this.serviceutilisateur.getOfferUser2(id)
      .subscribe(data => {
        this.offers2 = data;
      })
  }


  inputEvent(event: any) {
    const value = event.target.value;
    this.isZeroAllowed = value === '';
  }
  getOfferUser(id) {
    this.serviceutilisateur.getOfferUser(id)
      .subscribe(data => {
        this.offers = data;
      })
  }

  updaterefuseC(id: string, cause: string) {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.serviceconversion.updaterefuseC("refuse", cause, id, this.user.email).pipe(first()).subscribe(() => {
      this.loadPosts();
      this.showToasterError();
      this.modalService.dismissAll();
      this.submitted = false;
      this.cause = "";
    })
  }

  updateminpoint() {
    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      return;
    }
    if (this.max == 0) {
      return;
    }
    this.servicecurrency.updateMAXmontant(this.max, this.idc).pipe(first()).subscribe(data => {
      this.max = this.max;
      this.showToasterSuccess();
    })
  }

  updatepointindinars() {
    this.submitted4 = true;
    if (this.registerForm4.invalid) {
      return;
    }
    if (this.pointindinars == 0) {
      return;
    }
    this.servicecurrency.updatepointindinars(this.pointindinars, this.idc).pipe(first()).subscribe(data => {
      this.pointindinars = this.pointindinars;
      this.showToasterSuccess1();
    })
  }

  updateimagePrice() {
    this.submitted5 = true;
    if (this.registerForm5.invalid) {
      return;
    }
    if (this.imagePrice == 0) {
      return;
    }
    this.servicecurrency.updateimagePrice(this.imagePrice, this.idc).pipe(first()).subscribe(data => {
      this.imagePrice = this.imagePrice;
      this.showToasterSuccess5();
    })
  }

  updateprixroom() {
    this.submitted3 = true;
    if (this.registerForm3.invalid) {
      return;
    }
    if (this.prix_room == 0) {
      return;
    }
    this.servicecurrency.updateprixroom(this.prix_room, this.idc).pipe(first()).subscribe(data => {
      this.prix_room = this.prix_room;
      this.showToasterSuccess3();
    })
  }

  updateprixfavori() {
    this.servicecurrency.updateprixfavori(this.prix_favori, this.idc).pipe(first()).subscribe(data => {
      this.prix_favori = this.prix_favori;
      this.showToasterSuccess4();
    })
  }

  updatemontant() {
    this.submitted1 = true;
    if (this.registerForm1.invalid) {
      return;
    }
    if (this.montant1 == 0) {
      return;
    }

    this.servicecurrency.updatemontant(this.montant1, this.idc).pipe(first()).subscribe(data => {
      this.montant1 = this.montant1;
      this.showToasterSuccess1();
    })
  }

  showToasterSuccess() {
    this.notifyService.showSuccess(" تم تعديل الحد الادنى لتحويل النقاط")
  }
  showToasterSuccess1() {
    this.notifyService.showSuccess("تم تعديل قيمة النقطة")
  }

  showToasterSuccess3() {
    this.notifyService.showSuccess("تم تعديل  ثمن الغرفة")
  }
  showToasterSuccess4() {
    this.notifyService.showSuccess("تم تعديل  ثمن الإنضمام للغرفة")
  }

  showToasterSuccess2() {
    this.notifyService.showSuccess("تم قبول الطلب")
  }
  showToasterError() {
    this.notifyService.showError("تم رفض الطلب")
  }

  showToasterSuccess5() {
    this.notifyService.showSuccess("تم تعديل  ثمن الصورة")
  }

  open(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openSm3(content) {
    this.offers = null;
    this.modalService.open(content, { size: 'lg', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
