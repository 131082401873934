import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Demandproduct } from 'src/app/modules/demandproduct';
import { DemandproductService } from 'src/app/services/demandproduct.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-list-pret',
  templateUrl: './list-pret.component.html',
  styleUrls: ['./list-pret.component.css']
})
export class ListPretComponent implements OnInit {
  user: any = {};
  uri = environment.urlMarket;
  currentPage = 1;
  closeResult = '';
  cause: string;
  demandsproduct: Demandproduct[];
  registerForm: FormGroup;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  submitted = false;
  pageSize = 10;
  searchTerm: string;
  totalItems: number;
  p: number = 1;
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  dtOptions: DataTables.Settings = {};
  receivedData: any;
  constructor(private serviceauth: AuthentificationService, private http: HttpClient, private modalService: NgbModal,
    private servicedemandproduct: DemandproductService, private notifyService: NotificationService, private sharedService: SharedService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.user = this.serviceauth.getCurrentUser();
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
    });
    this.receivedData = false;
    this.registerForm = this.formBuilder.group({
      cause: ['', Validators.required],
    });
    this.listPrets()
  }

  get f() { return this.registerForm.controls; }

  listPrets() {
    this.servicedemandproduct.getPrets(this.currentPage, this.pageSize, this.searchTerm).subscribe(
      (response) => {
        this.demandsproduct = response.data;
        console.log(this.demandsproduct)
        this.totalItems = response.meta.total;
      },
      (error) => {
        console.error('Error fetching posts', error);
      }
    );
  }


  update(id: string) {
    console.log("test2")
    this.servicedemandproduct.updatepret(id).pipe(first()).subscribe(() => {
      this.listPrets();
      this.showToasterSuccess();
      this.modalService.dismissAll();
      this.cause = "";
    })
  }

  updaterefuse(id: string) {
    this.servicedemandproduct.updaterefusepret(id, this.user.email).pipe(first()).subscribe(() => {
      this.listPrets();
      this.showToasterError();
      this.modalService.dismissAll();
      this.cause = "";

    })
  }
  search() {
    this.currentPage = 1;
    this.listPrets();
  }

  pageChange(newPage: number) {
    this.currentPage = newPage;
    this.listPrets();
  }



  updaterefuseC(id: string, cause: string) {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.servicedemandproduct.updaterefuseCpret(cause, id, this.user.email).pipe(first()).subscribe(() => {
      this.listPrets();
      this.showToasterError();
      this.modalService.dismissAll();
      this.submitted = false;
      this.cause = "";
    })
  }


  showToasterError() {
    this.notifyService.showSuccess("تم رفض طلب شراء القرض ")
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("تمت المافقة على طلب شراء القرض")
  }


  openSm(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
